import { ProblemActionTypes } from './constants';
import { ProblemDetails, ProblemSelectableShort, ProblemSummary, ProblemUserDetails, ProblemUserSummary } from '../../../types/problem/problem';
import { UserEvaluatorResponse, UserTestSourceResponse } from '../../../types/problem/compiler';

const INIT_STATE = {
    apiUpsertSuccess: false,
    adminAllProblems: null,
    adminAllProblemsShort: null,
    adminProblemDetails: null,
    userAllProblems: null,
    userProblemDetails: null,
    userAllProblemsForLecture: null,
    userEvaluatorRun: null,
    userEvaluatorSubmit: null,
    userAllProblemsShort: null,
    loading: false,
};

type ProblemActionType = {
    type:
        | ProblemActionTypes.API_RESPONSE_SUCCESS
        | ProblemActionTypes.API_RESPONSE_ERROR
        | ProblemActionTypes.ADMIN_VIEW_ALL
        | ProblemActionTypes.ADMIN_VIEW_ALL_SHORT
        | ProblemActionTypes.ADMIN_VIEW_DETAILS
        | ProblemActionTypes.ADMIN_DELETE
        | ProblemActionTypes.ADMIN_UPSERT
        | ProblemActionTypes.USER_VIEW_ALL
        | ProblemActionTypes.USER_VIEW_SHORT
        | ProblemActionTypes.USER_VIEW_DETAILS
        | ProblemActionTypes.USER_VIEW_FOR_LECTURE
        | ProblemActionTypes.USER_RUN
        | ProblemActionTypes.USER_SUBMIT
        | ProblemActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: ProblemDetails | {};
        error?: string;
    };
};

type State = {
    apiUpsertSuccess: boolean;
    adminAllProblems?: ProblemSummary[] | null;
    adminAllProblemsShort?: ProblemSelectableShort[] | null;
    adminProblemDetails?: ProblemDetails | null;
    userAllProblems?: ProblemUserSummary[] | null;
    userProblemDetails?: ProblemUserDetails | null;
    userAllProblemsForLecture?: ProblemSelectableShort[] | null;
    userEvaluatorRun?: UserTestSourceResponse | null;
    userEvaluatorSubmit?: UserEvaluatorResponse | null;
    userAllProblemsShort?: ProblemSelectableShort[] | null;
    loading?: boolean;
};

const Problem = (state: State = INIT_STATE, action: ProblemActionType) => {
    switch (action.type) {
        case ProblemActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProblemActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllProblems: action.payload.data,
                        loading: false,
                    };
                }
                case ProblemActionTypes.ADMIN_VIEW_ALL_SHORT: {
                    return {
                        ...state,
                        adminAllProblemsShort: action.payload.data,
                    };
                }
                case ProblemActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminProblemDetails: action.payload.data,
                    };
                }
                case ProblemActionTypes.ADMIN_UPSERT: {
                    return {
                        ...state,
                        apiUpsertSuccess: true,
                    };
                }
                case ProblemActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        userAllProblems: action.payload.data,
                        loading: false,
                    };
                }
                case ProblemActionTypes.USER_VIEW_DETAILS: {
                    return {
                        ...state,
                        userProblemDetails: action.payload.data,
                    };
                }
                case ProblemActionTypes.USER_VIEW_FOR_LECTURE: {
                    return {
                        ...state,
                        userAllProblemsForLecture: action.payload.data,
                    };
                }
                case ProblemActionTypes.USER_RUN: {
                    return {
                        ...state,
                        userEvaluatorRun: action.payload.data,
                    };
                }
                case ProblemActionTypes.USER_SUBMIT: {
                    return {
                        ...state,
                        userEvaluatorSubmit: action.payload.data,
                    };
                }
                case ProblemActionTypes.USER_VIEW_SHORT: {
                    return {
                        ...state,
                        userAllProblemsShort: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case ProblemActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ProblemActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminAnswerDetails: null,
                        error: action.payload.error,
                    };
                }
                case ProblemActionTypes.USER_RUN: {
                    return {
                        ...state,
                        userEvaluatorRun: null,
                        userEvaluatorSubmit: null,
                        error: action.payload.error,
                    };
                }
                case ProblemActionTypes.USER_SUBMIT: {
                    return {
                        ...state,
                        userEvaluatorSubmit: null,
                        userEvaluatorRun: null,
                        error: action.payload.error,
                    };
                }
                case ProblemActionTypes.USER_VIEW_SHORT: {
                    return {
                        ...state,
                        userAllProblemsShort: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case ProblemActionTypes.ADMIN_VIEW_DETAILS:
            return {
                ...state,
                error: false,
                adminAnswerDetails: null,
                apiUpsertSuccess: false,
            };

        case ProblemActionTypes.ADMIN_UPSERT:
            return {
                ...state,
                apiUpsertSuccess: false,
            };

        case ProblemActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                loading: true,
                error: false,
            };

        case ProblemActionTypes.ADMIN_VIEW_ALL_SHORT:
            return {
                ...state,
                loading: true,
                adminAllProblemsShort: null,
            };

        case ProblemActionTypes.USER_VIEW_ALL:
            return {
                ...state,
                loading: true,
                userAllProblems: null,
            };

        case ProblemActionTypes.USER_VIEW_DETAILS:
            return {
                ...state,
                loading: true,
                userProblemDetails: null,
                userEvaluatorRun: null,
                userEvaluatorSubmit: null,
            };

        case ProblemActionTypes.USER_VIEW_FOR_LECTURE:
            return {
                ...state,
                loading: true,
                userAllProblemsForLecture: null,
            };

        case ProblemActionTypes.USER_RUN:
            return {
                ...state,
                loading: true,
                userEvaluatorRun: null,
                userEvaluatorSubmit: null,
            };

        case ProblemActionTypes.USER_SUBMIT:
            return {
                ...state,
                loading: true,
                userEvaluatorSubmit: null,
                userEvaluatorRun: null,
            };

        case ProblemActionTypes.USER_VIEW_SHORT:
            return {
                ...state,
                loading: true,
                userAllProblemsShort: null,
            };

        case ProblemActionTypes.RESET:
            return {
                ...state,
                loading: false,
                apiUpsertSuccess: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default Problem;

import { ChatActionTypes } from './constants';
import { ConversationDetails, MessageDetails } from '../../../types/user/chat';

const INIT_STATE = {
    apiSuccess: false,
    loading: false,
    error: null,
    allConversations: null,
    allFriends: null,
    autoReadSuccess: null,
    allConversationMessages: [],
    messageSentSuccess: null,
    conversationStatusChanged: null,
};

type ChatActionType = {
    type:
        | ChatActionTypes.API_RESPONSE_ERROR
        | ChatActionTypes.API_RESPONSE_SUCCESS
        | ChatActionTypes.USER_LOAD_ALL_FRIENDS
        | ChatActionTypes.USER_VIEW_ALL_CONTACTS
        | ChatActionTypes.USER_VIEW_CONVERSATION
        | ChatActionTypes.USER_AUTO_READ_CONVERSATION
        | ChatActionTypes.USER_CHANGE_CONVERSATION_STATUS
        | ChatActionTypes.USER_SEND_MESSAGE
        | ChatActionTypes.RESET;

    payload: {
        actionType?: string;
        data?: {} | MessageDetails;
        error?: string;
    };
};

type State = {
    apiSuccess?: boolean;
    loading?: boolean;
    error?: string | null;
    allFriends: ConversationDetails[] | null;
    allConversations: ConversationDetails[] | null;
    autoReadSuccess: boolean | null;
    messageSentSuccess: MessageDetails | null;
    allConversationMessages: MessageDetails[];
    conversationStatusChanged: number | null;
};

const Chat = (state: State = INIT_STATE, action: ChatActionType) => {
    switch (action.type) {
        case ChatActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ChatActionTypes.USER_VIEW_ALL_CONTACTS: {
                    return {
                        ...state,
                        allConversations: action.payload.data,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                case ChatActionTypes.USER_LOAD_ALL_FRIENDS: {
                    return {
                        ...state,
                        allFriends: action.payload.data,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                case ChatActionTypes.USER_AUTO_READ_CONVERSATION: {
                    return {
                        ...state,
                        autoReadSuccess: action.payload.data,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                case ChatActionTypes.USER_SEND_MESSAGE: {
                    const data = action.payload.data as MessageDetails;
                    return {
                        ...state,
                        messageSentSuccess: action.payload.data,
                        allConversationMessages: [...state.allConversationMessages, action.payload.data],
                        allConversations: state.allConversations!!.map((conversation) => {
                            if (conversation.id === data.conversationId) {
                                return {
                                    ...conversation,
                                    lastMessage: data,
                                };
                            }
                            return conversation;
                        }),
                        loading: false,
                        apiSuccess: true,
                    };
                }
                case ChatActionTypes.USER_CHANGE_CONVERSATION_STATUS: {
                    return {
                        ...state,
                        conversationStatusChanged: action.payload.data,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                case ChatActionTypes.USER_VIEW_CONVERSATION: {
                    return {
                        ...state,
                        allConversationMessages: action.payload.data,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }
        case ChatActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ChatActionTypes.USER_VIEW_ALL_CONTACTS:
                    return {
                        loading: true,
                        apiSuccess: false,
                    };
                case ChatActionTypes.USER_LOAD_ALL_FRIENDS: {
                    return {
                        loading: true,
                        apiSuccess: false,
                    };
                }
                default:
                    return { ...state };
            }
        case ChatActionTypes.USER_CHANGE_CONVERSATION_STATUS: {
            return {
                ...state,
                conversationStatusChanged: null,
                loading: true,
                apiSuccess: false,
            };
        }
        case ChatActionTypes.USER_SEND_MESSAGE: {
            return {
                ...state,
                messageSentSuccess: null,
                loading: true,
                apiSuccess: false,
            };
        }
        case ChatActionTypes.USER_AUTO_READ_CONVERSATION: {
            return {
                ...state,
                autoReadSuccess: null,
                loading: true,
                apiSuccess: false,
            };
        }
        default:
            return { ...state };
    }
};

export default Chat;

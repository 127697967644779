import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiAdminViewAll, apiUserViewDetails, apiUserViewAll } from '../../../helpers/api/shop/bundle';
import { BundleSummary } from '../../../types/shop/bundle';
import { bundleApiResponseError, bundleApiResponseSuccess } from './actions';
import { ShopBundleActionTypes } from './constants';

type AdminBundlePayloadType = {
    payload: {
        slug: string;
    };
    type: string;
};

function* adminViewAll(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll);
        const responseData: BundleSummary[] = response.data.map((bundle: BundleSummary) => {
            return Object.assign(bundle, {
                id_short: bundle.id.substring(0, 8),
            });
        });
        yield put(bundleApiResponseSuccess(ShopBundleActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(bundleApiResponseError(ShopBundleActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* userViewDetails({ payload: { slug } }: AdminBundlePayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewDetails, slug);
        yield put(bundleApiResponseSuccess(ShopBundleActionTypes.USER_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(bundleApiResponseError(ShopBundleActionTypes.USER_VIEW_DETAILS, error));
    }
}

function* userViewAll(): SagaIterator {
    try {
        const response = yield call(apiUserViewAll);
        yield put(bundleApiResponseSuccess(ShopBundleActionTypes.USER_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(bundleApiResponseError(ShopBundleActionTypes.USER_VIEW_ALL, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(ShopBundleActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchUserViewDetails() {
    yield takeEvery(ShopBundleActionTypes.USER_VIEW_DETAILS, userViewDetails);
}

export function* watchUserViewAll() {
    yield takeEvery(ShopBundleActionTypes.USER_VIEW_ALL, userViewAll);
}

function* ShopBundleSaga() {
    yield all([fork(watchAdminViewAll), fork(watchUserViewDetails), fork(watchUserViewAll)]);
}

export default ShopBundleSaga;

import { AiMessageActionTypes } from './constants';
import { AiMessageDetails, AiMessageSummary } from '../../../types/ai/aiMessage';

const INIT_STATE = {
    apiMessageSuccess: false,
    apiAssistantResponse: null,
    adminAllMessages: null,
    adminMessageDetails: null,
    loading: false,
};

type AiMessageActionType = {
    type:
        | AiMessageActionTypes.API_RESPONSE_SUCCESS
        | AiMessageActionTypes.API_RESPONSE_ERROR
        | AiMessageActionTypes.ADMIN_VIEW_ALL
        | AiMessageActionTypes.ADMIN_VIEW_DETAILS
        | AiMessageActionTypes.USER_CREATE
        | AiMessageActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: AiMessageSummary[] | AiMessageDetails | {};
        error?: string;
    };
};

type State = {
    apiMessageSuccess: boolean;
    apiAssistantResponse?: string | null;
    adminAllMessages?: AiMessageSummary[] | null;
    adminMessageDetails?: AiMessageDetails | null;
    loading?: boolean;
};

const AiMessage = (state: State = INIT_STATE, action: AiMessageActionType) => {
    switch (action.type) {
        case AiMessageActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AiMessageActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllMessages: action.payload.data,
                        loading: false,
                    };
                }
                case AiMessageActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminMessageDetails: action.payload.data,
                    };
                }
                case AiMessageActionTypes.USER_CREATE: {
                    return {
                        ...state,
                        apiMessageSuccess: true,
                        apiAssistantResponse: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case AiMessageActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AiMessageActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminMessageDetails: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case AiMessageActionTypes.ADMIN_VIEW_DETAILS:
            return {
                ...state,
                error: false,
                adminMessageDetails: null,
                apiMessageSuccess: false,
            };

        case AiMessageActionTypes.USER_CREATE:
            return {
                ...state,
                apiMessageSuccess: false,
                apiAssistantResponse: null,
            };

        case AiMessageActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                loading: true,
                error: false,
            };

        case AiMessageActionTypes.RESET:
            return {
                ...state,
                loading: false,
                apiMessageSuccess: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default AiMessage;

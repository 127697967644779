import { StaticSearchActionTypes } from './constants';

export type StaticSearchActionType = {
    type:
        | StaticSearchActionTypes.API_RESPONSE_ERROR
        | StaticSearchActionTypes.API_RESPONSE_SUCCESS
        | StaticSearchActionTypes.USER_VIEW_ALL
        | StaticSearchActionTypes.RESET;
    payload: {} | string;
};
export const staticSearchLoadData = (): StaticSearchActionType => ({
    type: StaticSearchActionTypes.USER_VIEW_ALL,
    payload: {},
});

export const staticSearchApiResponseSuccess = (actionType: string, data: {}): StaticSearchActionType => ({
    type: StaticSearchActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const staticSearchApiResponseError = (actionType: string, error: string): StaticSearchActionType => ({
    type: StaticSearchActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

import { AdminGalleryActionTypes } from './constants';
import { PhotoShort, PhotoSummary } from '../../types/cms/photo';

const INIT_STATE = {
    adminAvatarsAll: null,
    adminImagesAll: null,
    adminImagesAllShort: null,
    apiUpsertSuccess: false,
    loading: false,
};

type AdminGalleryActionType = {
    type:
        | AdminGalleryActionTypes.API_RESPONSE_SUCCESS
        | AdminGalleryActionTypes.API_RESPONSE_ERROR
        | AdminGalleryActionTypes.ADMIN_AVATARS_ALL
        | AdminGalleryActionTypes.ADMIN_IMAGES_ALL
        | AdminGalleryActionTypes.ADMIN_IMAGES_ALL_SHORT
        | AdminGalleryActionTypes.ADMIN_IMAGES_DELETE
        | AdminGalleryActionTypes.ADMIN_IMAGES_UPLOAD;
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    loading?: boolean;
    error?: boolean;
    apiUpsertSuccess?: boolean;
    adminImagesAll?: PhotoSummary[] | null;
    adminImagesAllShort?: PhotoShort[] | null;
    adminAvatarsAll?: PhotoSummary[] | null;
};

const AdminGallery = (state: State = INIT_STATE, action: AdminGalleryActionType) => {
    switch (action.type) {
        case AdminGalleryActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AdminGalleryActionTypes.ADMIN_IMAGES_ALL: {
                    return {
                        ...state,
                        adminImagesAll: action.payload.data,
                        loading: false,
                    };
                }
                case AdminGalleryActionTypes.ADMIN_IMAGES_ALL_SHORT: {
                    return {
                        ...state,
                        adminImagesAllShort: action.payload.data,
                        loading: false,
                    };
                }
                case AdminGalleryActionTypes.ADMIN_IMAGES_DELETE: {
                    return {
                        ...state,
                        apiUpsertSuccess: true,
                        loading: false,
                    };
                }
                case AdminGalleryActionTypes.ADMIN_IMAGES_UPLOAD: {
                    return {
                        ...state,
                        apiUpsertSuccess: true,
                        loading: false,
                    };
                }
                case AdminGalleryActionTypes.ADMIN_AVATARS_ALL: {
                    return {
                        ...state,
                        adminAvatarsAll: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AdminGalleryActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AdminGalleryActionTypes.ADMIN_AVATARS_ALL: {
                    return {
                        ...state,
                        adminAvatarsAll: null,
                        error: action.payload.error,
                    };
                }
                case AdminGalleryActionTypes.ADMIN_IMAGES_ALL: {
                    return {
                        ...state,
                        adminImagesAll: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case AdminGalleryActionTypes.ADMIN_AVATARS_ALL:
            return {
                ...state,
                adminAvatarsAll: null,
                error: false,
                loading: true,
            };

        case AdminGalleryActionTypes.ADMIN_IMAGES_ALL:
            return {
                ...state,
                adminImagesAll: null,
                error: false,
                loading: true,
            };

        case AdminGalleryActionTypes.ADMIN_IMAGES_ALL_SHORT:
            return {
                ...state,
                adminImagesAllShort: null,
                error: false,
                loading: true,
            };

        default:
            return { ...state };
    }
};

export default AdminGallery;

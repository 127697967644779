export enum ChatActionTypes {
    API_RESPONSE_ERROR = '@@chat/API_RESPONSE_ERROR',
    API_RESPONSE_SUCCESS = '@@chat/API_RESPONSE_SUCCESS',

    USER_VIEW_ALL_CONTACTS = '@@chat/USER_VIEW_ALL_CONTACTS',
    USER_LOAD_ALL_FRIENDS = '@@chat/USER_LOAD_ALL_FRIENDS',
    SOCKET_UPDATE_CONVERSATION_LAST_MESSAGE = '@@chat/SOCKET_UPDATE_CONVERSATION_LAST_MESSAGE',
    USER_VIEW_CONVERSATION = '@@chat/USER_VIEW_CONVERSATION',
    USER_AUTO_READ_CONVERSATION = '@@chat/USER_AUTO_READ_CONVERSATION',
    USER_CHANGE_CONVERSATION_STATUS = '@@chat/USER_CHANGE_CONVERSATION_STATUS',
    USER_SEND_MESSAGE = '@@chat/USER_SEND_MESSAGE',

    RESET = '@@chat/RESET',
}

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { ChapterNew } from '../../../types/theory/chapter';
import { QuestionNew } from '../../../types/quiz/question';

const api = new APICore();

function apiAdminViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/questions/admin/${id}`, null);
}

function apiAdminViewAll(page: number, size: number) {
    return api.get(`${apiBaseUrl}/questions/admin/page/${page}/${size}`, null);
}

function apiAdminViewByLecture(lectureId: string) {
    return api.get(`${apiBaseUrl}/questions/admin/lecture/${lectureId}`, null);
}

function apiAdminViewAllShort() {
    return api.get(`${apiBaseUrl}/questions/admin/short`, null);
}

function apiAdminViewAllAnswers(id: string) {
    return api.get(`${apiBaseUrl}/answers/admin/question/${id}`, null);
}

function apiAdminCreate(data: QuestionNew) {
    return api.create(`${apiBaseUrl}/questions/admin`, data);
}

function apiAdminUpdate(data: QuestionNew) {
    return api.update(`${apiBaseUrl}/questions/admin`, data);
}

export { apiAdminCreate, apiAdminUpdate, apiAdminViewAll, apiAdminViewAllShort, apiAdminViewDetails, apiAdminViewAllAnswers, apiAdminViewByLecture };

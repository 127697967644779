import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { AiMessageNew } from '../../../types/ai/aiMessage';

const api = new APICore();

function apiAdminViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/ai/messages/admin/${id}`, null);
}

function apiAdminViewAll() {
    return api.get(`${apiBaseUrl}/ai/messages/admin`, null);
}

function apiUserCreate(data: AiMessageNew) {
    return api.create(`${apiBaseUrl}/ai/messages`, data);
}

export { apiAdminViewAll, apiAdminViewDetails, apiUserCreate };

import { ShopInvoiceActionTypes } from './constants';
import { InvoiceDetails, InvoiceNew, InvoiceSummary, Twispay } from '../../../types/shop/invoice';

const INIT_STATE = {
    apiUpsertSuccess: false,
    adminAllInvoices: null,
    adminInvoiceDetails: null,
    userAllInvoices: null,
    userInvoiceDetails: null,
    userInvoiceStatus: null,
    userInvoiceResponse: null,
    loading: false,
};

type ShopInvoiceActionType = {
    type:
        | ShopInvoiceActionTypes.API_RESPONSE_SUCCESS
        | ShopInvoiceActionTypes.API_RESPONSE_ERROR
        | ShopInvoiceActionTypes.ADMIN_VIEW_ALL
        | ShopInvoiceActionTypes.ADMIN_VIEW_DETAILS
        | ShopInvoiceActionTypes.USER_VIEW_DETAILS
        | ShopInvoiceActionTypes.USER_VIEW_ALL
        | ShopInvoiceActionTypes.USER_CHECK_STATUS
        | ShopInvoiceActionTypes.USER_SEND_INVOICE;
    payload: {
        actionType?: string;
        data?: InvoiceNew | InvoiceDetails | InvoiceSummary[] | Twispay | {};
        error?: string;
    };
};

type State = {
    adminAllInvoices?: InvoiceSummary[] | null;
    adminInvoiceDetails?: InvoiceDetails | null;
    userAllInvoices?: InvoiceSummary[] | null;
    userInvoiceDetails?: InvoiceDetails | null;
    userInvoiceStatus?: InvoiceDetails | null;
    userInvoiceResponse?: Twispay | null;
    loading?: boolean;
};

const ShopInvoice = (state: State = INIT_STATE, action: ShopInvoiceActionType) => {
    switch (action.type) {
        case ShopInvoiceActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ShopInvoiceActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllInvoices: action.payload.data,
                        loading: false,
                    };
                }
                case ShopInvoiceActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminInvoiceDetails: action.payload.data,
                    };
                }
                case ShopInvoiceActionTypes.USER_VIEW_DETAILS: {
                    return {
                        ...state,
                        userInvoiceDetails: action.payload.data,
                    };
                }
                case ShopInvoiceActionTypes.USER_SEND_INVOICE: {
                    return {
                        ...state,
                        userInvoiceResponse: action.payload.data,
                    };
                }
                case ShopInvoiceActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        userAllInvoices: action.payload.data,
                    };
                }
                case ShopInvoiceActionTypes.USER_CHECK_STATUS: {
                    return {
                        ...state,
                        userInvoiceStatus: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case ShopInvoiceActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ShopInvoiceActionTypes.USER_VIEW_DETAILS: {
                    return {
                        ...state,
                        userInvoiceDetails: null,
                        error: action.payload.error,
                    };
                }
                case ShopInvoiceActionTypes.USER_SEND_INVOICE: {
                    return {
                        ...state,
                        userInvoiceResponse: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case ShopInvoiceActionTypes.USER_VIEW_DETAILS:
            return {
                ...state,
                error: false,
                userInvoiceDetails: null,
            };

        case ShopInvoiceActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                adminAllInvoices: null,
                loading: true,
                error: false,
            };

        case ShopInvoiceActionTypes.USER_VIEW_ALL:
            return {
                ...state,
                adminAllInvoices: null,
                loading: true,
                error: false,
            };

        case ShopInvoiceActionTypes.USER_CHECK_STATUS:
            return {
                ...state,
                userInvoiceStatus: null,
                loading: true,
                error: false,
            };

        case ShopInvoiceActionTypes.USER_SEND_INVOICE:
            return {
                ...state,
                userInvoiceResponse: null,
                loading: true,
                error: false,
            };

        default:
            return { ...state };
    }
};

export default ShopInvoice;

export enum QuestionActionTypes {
    API_RESPONSE_SUCCESS = '@@question/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@question/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL = '@@question/ADMIN_VIEW_ALL',
    ADMIN_VIEW_ALL_ANSWERS = '@@question/ADMIN_VIEW_ALL_ANSWERS',
    ADMIN_VIEW_ALL_SHORT = '@@question/ADMIN_VIEW_ALL_SHORT',
    ADMIN_VIEW_DETAILS = '@@question/ADMIN_VIEW_DETAILS',
    ADMIN_VIEW_BY_LECTURE = '@@question/ADMIN_VIEW_BY_LECTURE',
    ADMIN_DELETE = '@@question/ADMIN_DELETE',
    ADMIN_UPSERT = '@@question/ADMIN_UPSERT',

    RESET = '@@question/RESET',
}

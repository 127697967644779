import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';

const api = new APICore();

function apiAdminViewImages(page: number, size: number) {
    return api.get(`${apiBaseUrl}/photos/page/${page}/${size}`, null);
}

function apiAdminViewAvatars(page: number, size: number) {
    return api.get(`${apiBaseUrl}/photos/avatars/page/${page}/${size}`, null);
}

function apiAdminUploadImage(formData: FormData) {
    return api.createWithFileData(`${apiBaseUrl}/photos`, formData);
}

function apiAdminViewShort() {
    return api.get(`${apiBaseUrl}/photos/short`, null);
}

export { apiAdminViewImages, apiAdminViewAvatars, apiAdminViewShort, apiAdminUploadImage };

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { DependencyNew } from '../../../types/cms/dependency';

const api = new APICore();

function apiAdminGetAll() {
    return api.get(`${apiBaseUrl}/dependency/admin`, null);
}

function apiAdminView(params: { model: string; modelId: string }) {
    return api.get(`${apiBaseUrl}/dependency/admin/view/${params.model}/${params.modelId}`, null);
}

function apiAdminUpdate(data: DependencyNew) {
    return api.create(`${apiBaseUrl}/dependency/admin`, data);
}

function apiAdminDelete(params: { model: string; modelId: string }) {
    return api.delete(`${apiBaseUrl}/dependency/admin/${params.modelId}/${params.model}`);
}

function apiAdminMapModules() {
    return api.get(`${apiBaseUrl}/dependency/admin/map/modules`, null);
}

function apiAdminMapChapters(moduleId: string) {
    return api.get(`${apiBaseUrl}/dependency/admin/map/chapters/${moduleId}`, null);
}

function apiAdminMapLectures(chapterId: string) {
    return api.get(`${apiBaseUrl}/dependency/admin/map/lectures/${chapterId}`, null);
}

export { apiAdminGetAll, apiAdminView, apiAdminUpdate, apiAdminDelete, apiAdminMapModules, apiAdminMapChapters, apiAdminMapLectures };

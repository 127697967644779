export type MenuItemType = {
    key: string;
    label?: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    adminKey?: boolean;
    target?: string;
    children?: MenuItemType[];
};

const ADMIN_URL_PREFIX = '/admin';
const ADMIN_MENU_ITEMS: MenuItemType[] = [
    {
        adminKey: true,
        key: 'admin-dashboards',
        label: 'Dashboard',
        icon: 'mdi mdi-home',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-main-page',
                url: `${ADMIN_URL_PREFIX}/dashboard/home`,
                parentKey: 'admin-dashboards',
            },
            {
                adminKey: true,
                key: 'admin-main-infrastructure',
                url: `${ADMIN_URL_PREFIX}/dashboard/infrastructure`,
                parentKey: 'admin-dashboards',
            },
            {
                adminKey: true,
                key: 'admin-main-quick',
                url: `${ADMIN_URL_PREFIX}/dashboard/quicks`,
                parentKey: 'admin-dashboards',
            },
            {
                adminKey: true,
                key: 'admin-main-logs',
                url: `${ADMIN_URL_PREFIX}/dashboard/logs`,
                parentKey: 'admin-dashboards',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-ai',
        label: 'AI Chat',
        icon: 'mdi mdi-chat-plus',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-ai-config',
                url: `${ADMIN_URL_PREFIX}/ai/config`,
                parentKey: 'admin-ai',
            },
            {
                adminKey: true,
                key: 'admin-ai-conversations',
                url: `${ADMIN_URL_PREFIX}/ai/conversations`,
                parentKey: 'admin-ai',
            },
            {
                adminKey: true,
                key: 'admin-ai-chat',
                url: `${ADMIN_URL_PREFIX}/ai/messages`,
                parentKey: 'admin-ai',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-compiler',
        label: 'Compiler',
        icon: 'mdi mdi-laptop',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-compiler-test-source',
                url: `${ADMIN_URL_PREFIX}/compiler/test-source`,
                parentKey: 'admin-compiler',
            },
            {
                adminKey: true,
                key: 'admin-compiler-test-problem',
                label: 'Testeaza problema',
                url: `${ADMIN_URL_PREFIX}/compiler/test-problem`,
                parentKey: 'admin-compiler',
            },
            {
                adminKey: true,
                key: 'admin-compiler-languages',
                label: 'Limbaje si status',
                url: `${ADMIN_URL_PREFIX}/compiler/languages`,
                parentKey: 'admin-compiler',
            },
            {
                adminKey: true,
                key: 'admin-compiler-logs',
                label: 'Log-uri',
                url: `${ADMIN_URL_PREFIX}/compiler/logs`,
                parentKey: 'admin-compiler',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-shop',
        label: 'Shop',
        icon: 'mdi mdi-cart',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-shop-products',
                url: `${ADMIN_URL_PREFIX}/shop/products`,
                parentKey: 'admin-shop',
            },
            {
                adminKey: true,
                key: 'admin-shop-invoices',
                label: 'Testeaza problema',
                url: `${ADMIN_URL_PREFIX}/shop/invoices`,
                parentKey: 'admin-shop',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-users',
        label: 'Users',
        icon: 'mdi mdi-account',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-users-all',
                url: `${ADMIN_URL_PREFIX}/users/all`,
                parentKey: 'admin-theory',
            },
            {
                adminKey: true,
                key: 'admin-users-avatars',
                url: `${ADMIN_URL_PREFIX}/users/avatars`,
                parentKey: 'admin-theory',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-theory',
        label: 'Teorie',
        icon: 'mdi mdi-book-open-blank-variant',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-theory-modules',
                url: `${ADMIN_URL_PREFIX}/theory/modules`,
                parentKey: 'admin-theory',
            },
            {
                adminKey: true,
                key: 'admin-theory-chapters',
                url: `${ADMIN_URL_PREFIX}/theory/chapters`,
                parentKey: 'admin-theory',
            },
            {
                adminKey: true,
                key: 'admin-theory-lectures',
                url: `${ADMIN_URL_PREFIX}/theory/lectures`,
                parentKey: 'admin-theory',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-quiz',
        label: 'Quiz',
        icon: 'mdi mdi-help-circle',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-quiz-question',
                url: `${ADMIN_URL_PREFIX}/quiz/questions`,
                parentKey: 'admin-quiz',
            },
            {
                adminKey: true,
                key: 'admin-quiz-answer',
                url: `${ADMIN_URL_PREFIX}/quiz/answers`,
                parentKey: 'admin-quiz',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-problems',
        label: 'Probleme',
        icon: 'mdi mdi-clipboard-alert-outline',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-problems-all',
                url: `${ADMIN_URL_PREFIX}/problems/all`,
                parentKey: 'admin-problems',
            },
            {
                adminKey: true,
                key: 'admin-problems-drafts',
                url: `${ADMIN_URL_PREFIX}/problems/drafts`,
                parentKey: 'admin-problems',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-comments',
        label: 'Comments',
        icon: 'mdi mdi-comment-processing-outline me-1',
        parentKey: 'admin-panel',
        url: `${ADMIN_URL_PREFIX}/comments`,
    },
    {
        adminKey: true,
        key: 'admin-gallery',
        label: 'Gallery',
        icon: 'mdi mdi-image-multiple-outline',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-gallery-images',
                url: `${ADMIN_URL_PREFIX}/gallery/images`,
                parentKey: 'admin-gallery',
            },

            {
                adminKey: true,
                key: 'admin-gallery-avatars',
                url: `${ADMIN_URL_PREFIX}/gallery/avatars`,
                parentKey: 'admin-gallery',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-support',
        label: 'Suport',
        icon: 'mdi mdi-help',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-support-reports',
                url: `${ADMIN_URL_PREFIX}/support/reports`,
                parentKey: 'admin-support',
            },
            {
                adminKey: true,
                key: 'admin-support-tickets',
                url: `${ADMIN_URL_PREFIX}/support/tickets`,
                parentKey: 'admin-support',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-cms',
        label: 'CMS',
        icon: 'mdi mdi-cog',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-cms-pages',
                url: `${ADMIN_URL_PREFIX}/cms/pages`,
                parentKey: 'admin-cms',
            },
            {
                adminKey: true,
                key: 'admin-cms-dependencies',
                url: `${ADMIN_URL_PREFIX}/cms/dependencies`,
                parentKey: 'admin-cms',
            },
            {
                adminKey: true,
                key: 'admin-cms-contacts',
                url: `${ADMIN_URL_PREFIX}/cms/contacts`,
                parentKey: 'admin-cms',
            },
            {
                adminKey: true,
                key: 'admin-cms-tags',
                url: `${ADMIN_URL_PREFIX}/cms/tags`,
                parentKey: 'admin-cms',
            },
            {
                adminKey: true,
                key: 'admin-cms-schools',
                url: `${ADMIN_URL_PREFIX}/cms/schools`,
                parentKey: 'admin-cms',
            },
            {
                adminKey: true,
                key: 'admin-cms-seo',
                url: `${ADMIN_URL_PREFIX}/cms/seo`,
                parentKey: 'admin-cms',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-logs',
        label: 'Logs',
        icon: 'mdi mdi-file-document-outline',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-logs-energy',
                url: `${ADMIN_URL_PREFIX}/logs/energy`,
                parentKey: 'admin-logs',
            },
            {
                adminKey: true,
                key: 'admin-logs-security',
                url: `${ADMIN_URL_PREFIX}/logs/security`,
                parentKey: 'admin-logs',
            },
            {
                adminKey: true,
                key: 'admin-logs-logins',
                url: `${ADMIN_URL_PREFIX}/logs/logins`,
                parentKey: 'admin-logs',
            },
            {
                adminKey: true,
                key: 'admin-logs-timespent',
                url: `${ADMIN_URL_PREFIX}/logs/timespent`,
                parentKey: 'admin-logs',
            },
        ],
    },
];

const MENU_ITEMS: MenuItemType[] = [
    {
        key: 'dashboard',
        isTitle: false,
        icon: 'mdi mdi-tablet-dashboard',
        url: '/dashboard',
    },
    {
        key: 'search',
        isTitle: false,
        icon: 'mdi mdi-magnify',
        url: '/search',
    },
    {
        key: 'modules',
        isTitle: false,
        icon: 'mdi mdi-certificate',
        url: '/modules',
    },
    {
        key: 'shop',
        isTitle: false,
        icon: 'mdi mdi-cart',
        url: '/shop',
    },
    {
        key: 'problems',
        isTitle: false,
        icon: 'mdi mdi-code-braces',
        url: '/problems',
    },
    {
        key: 'rankings',
        isTitle: false,
        icon: 'mdi mdi-chart-timeline',
        url: '/ranking',
    },
];

export { ADMIN_MENU_ITEMS, MENU_ITEMS };

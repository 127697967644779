import { ChapterActionTypes } from './constants';
import { ChapterDetails, ChapterNew, ChapterSummary } from '../../../types/theory/chapter';

export type ChapterActionType = {
    type:
        | ChapterActionTypes.API_RESPONSE_SUCCESS
        | ChapterActionTypes.API_RESPONSE_ERROR
        | ChapterActionTypes.ADMIN_VIEW_ALL
        | ChapterActionTypes.ADMIN_VIEW_ALL_SHORT
        | ChapterActionTypes.ADMIN_VIEW_DETAILS
        | ChapterActionTypes.ADMIN_DELETE
        | ChapterActionTypes.ADMIN_UPSERT
        | ChapterActionTypes.RESET;
    payload: {} | string;
};

export const chapterAllApiResponseSuccess = (actionType: string, data: ChapterSummary | ChapterDetails | {}): ChapterActionType => ({
    type: ChapterActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const chapterApiResponseError = (actionType: string, error: string): ChapterActionType => ({
    type: ChapterActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const chapterAdminViewAll = (page: number, size: number): ChapterActionType => ({
    type: ChapterActionTypes.ADMIN_VIEW_ALL,
    payload: { page, size },
});

export const chapterAdminViewAllShort = (): ChapterActionType => ({
    type: ChapterActionTypes.ADMIN_VIEW_ALL_SHORT,
    payload: {},
});

export const chapterAdminViewDetails = (id: string): ChapterActionType => ({
    type: ChapterActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

export const chapterAdminUpsert = (data: ChapterNew): ChapterActionType => ({
    type: ChapterActionTypes.ADMIN_UPSERT,
    payload: data,
});

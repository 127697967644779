import { ProfileActionTypes } from './constants';

export type ProfileActionType = {
    type: ProfileActionTypes.USER_LOAD_PROFILE | ProfileActionTypes.API_RESPONSE_ERROR | ProfileActionTypes.API_RESPONSE_SUCCESS | ProfileActionTypes.RESET;
    payload: {} | string;
};
export const profileLoadUserData = (username: string): ProfileActionType => ({
    type: ProfileActionTypes.USER_LOAD_PROFILE,
    payload: { username },
});

export const profileApiResponseSuccess = (actionType: string, data: {}): ProfileActionType => ({
    type: ProfileActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const profileApiResponseError = (actionType: string, error: string): ProfileActionType => ({
    type: ProfileActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

import { combineReducers } from 'redux';
import AdminGallery from './gallery/reducers';
import AdminPanel from './admin/reducers';
import AiConfig from './ai/config/reducers';
import AiConversation from './ai/conversation/reducers';
import AiMessage from './ai/message/reducers';
import Answer from './quiz/answer/reducers';
import Auth from './auth/reducers';
import Chapter from './theory/chapter/reducers';
import Chat from './user/chat/reducers';
import Comment from './cms/comment/reducers';
import Compiler from './problem/compiler/reducers';
import Contact from './cms/contact/reducers';
import County from './constant/counties/reducers';
import Dashboard from './user/dashboard/reducers';
import Dependency from './cms/dependency/reducers';
import EnergyLog from './user/logs/energy/reducers';
import Evaluator from './problem/evaluator/reducers';
import Friendship from './user/friendship/reducers';
import Layout from './layout/reducers';
import Lecture from './theory/lecture/reducers';
import LoginLog from './log/login/reducers';
import ModuleTheory from './theory/module/reducers';
import Notification from './user/notifications/reducers';
import Problem from './problem/problem/reducers';
import ProblemHint from './problem/hint/reducers';
import ProblemSearchPanel from './problem/searchPanel/reducers';
import ProblemSolution from './problem/solution/reducers';
import ProblemTest from './problem/test/reducers';
import Profile from './user/profile/reducers';
import Question from './quiz/question/reducers';
import Quiz from './theory/quiz/reducers';
import Ranking from './user/ranking/reducers';
import Report from './cms/report/reducers';
import School from './cms/school/reducers';
import SeoData from './cms/seoData/reducers';
import Settings from './user/settings/reducers';
import ShopBundle from './shop/bundle/reducers';
import ShopInvoice from './shop/invoice/reducers';
import StaticPage from './cms/staticPage/reducers';
import StaticSearch from './user/search/reducers';
import Tag from './cms/tag/reducers';
import Ticket from './cms/ticket/reducers';
import TimespentLog from './user/logs/timespent/reducers';
import UserPurchase from './user/purchase/reducers';
import SecurityLog from './log/security/reducers';

export default combineReducers({
    AdminGallery,
    AdminPanel,
    AiConfig,
    AiConversation,
    AiMessage,
    Answer,
    Auth,
    Chapter,
    Chat,
    Comment,
    Compiler,
    Contact,
    County,
    Dashboard,
    Dependency,
    EnergyLog,
    Evaluator,
    Friendship,
    Layout,
    Lecture,
    LoginLog,
    ModuleTheory,
    Notification,
    Problem,
    ProblemHint,
    ProblemSearchPanel,
    ProblemSolution,
    ProblemTest,
    Profile,
    Question,
    Quiz,
    Ranking,
    Report,
    School,
    SecurityLog,
    SeoData,
    Settings,
    ShopBundle,
    ShopInvoice,
    StaticPage,
    StaticSearch,
    Tag,
    Ticket,
    TimespentLog,
    UserPurchase,
});

import { PageActionTypes } from './constants';
import { StaticPageData, StaticPageNew } from '../../../types/cms/staticPage';

export type PageActionType = {
    type:
        | PageActionTypes.API_RESPONSE_SUCCESS
        | PageActionTypes.API_RESPONSE_ERROR
        | PageActionTypes.USER_VIEW
        | PageActionTypes.ADMIN_VIEW_ALL
        | PageActionTypes.ADMIN_CREATE
        | PageActionTypes.ADMIN_UPDATE
        | PageActionTypes.RESET;
    payload: {} | string;
};

// common success
export const staticPageApiResponseSuccess = (actionType: string, data: StaticPageData | {}): PageActionType => ({
    type: PageActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const staticPageApiResponseError = (actionType: string, error: string): PageActionType => ({
    type: PageActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const staticPageUserView = (slug: string): PageActionType => ({
    type: PageActionTypes.USER_VIEW,
    payload: { slug },
});

export const staticPageAdminAll = (): PageActionType => ({
    type: PageActionTypes.ADMIN_VIEW_ALL,
    payload: {},
});

export const staticPageAdminCreate = (data: StaticPageNew): PageActionType => ({
    type: PageActionTypes.ADMIN_CREATE,
    payload: data,
});

export const staticPageAdminUpdate = (data: StaticPageNew): PageActionType => ({
    type: PageActionTypes.ADMIN_UPDATE,
    payload: data,
});

export const resetStaticPage = (): PageActionType => ({
    type: PageActionTypes.RESET,
    payload: {},
});

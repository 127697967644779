import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { moduleAllApiResponseSuccess, moduleApiResponseError } from './actions';
import { ModuleActionTypes } from './constants';
import { ModuleNew, ModuleProgress, ModuleSummary } from '../../../types/theory/module';
import {
    apiAdminCreate,
    apiAdminUpdate,
    apiAdminViewAll,
    apiAdminViewAllShort,
    apiAdminViewDetails,
    apiUserViewAll,
    apiUserViewChapters,
    apiUserViewDetails,
    apiUserViewProgress,
} from '../../../helpers/api/theory/module';

type ModuleDetailsPayloadType = {
    payload: {
        slug: string;
    };
    type: string;
};

type AdminModuleDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

type AdminModuleUpsertPayloadType = {
    payload: ModuleNew;
    type: string;
};

function* adminViewAll(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll);
        const responseData: ModuleSummary[] = response.data.map((module: ModuleSummary) => {
            return Object.assign(module, {
                id_short: module.id.substring(0, 8),
            });
        });
        yield put(moduleAllApiResponseSuccess(ModuleActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(moduleApiResponseError(ModuleActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewAllShort(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAllShort);
        yield put(moduleAllApiResponseSuccess(ModuleActionTypes.ADMIN_VIEW_ALL_SHORT, response.data));
    } catch (error: any) {
        yield put(moduleApiResponseError(ModuleActionTypes.ADMIN_VIEW_ALL_SHORT, error));
    }
}

function* adminViewDetails({ payload: { id } }: AdminModuleDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(moduleAllApiResponseSuccess(ModuleActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(moduleApiResponseError(ModuleActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

function* adminUpsert({ payload }: AdminModuleUpsertPayloadType): SagaIterator {
    try {
        const response = yield call(payload.id ? apiAdminUpdate : apiAdminCreate, payload);
        yield put(moduleAllApiResponseSuccess(ModuleActionTypes.ADMIN_UPSERT, response.data));
    } catch (error: any) {
        yield put(moduleApiResponseError(ModuleActionTypes.ADMIN_UPSERT, error));
    }
}

function* userViewAll(): SagaIterator {
    try {
        const response = yield call(apiUserViewAll);
        const responseData: ModuleSummary[] = response.data.map((module: ModuleSummary) => {
            return Object.assign(module, {
                id: module.id.substring(0, 8),
                status: module.status,
            });
        });
        yield put(moduleAllApiResponseSuccess(ModuleActionTypes.USER_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(moduleApiResponseError(ModuleActionTypes.USER_VIEW_ALL, error));
    }
}

function* userViewDetails({ payload: { slug } }: ModuleDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewDetails, slug);
        yield put(moduleAllApiResponseSuccess(ModuleActionTypes.USER_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(moduleApiResponseError(ModuleActionTypes.USER_VIEW_DETAILS, error));
    }
}

function* userViewProgress({ payload: { slug } }: ModuleDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewProgress, slug);
        const responseData: ModuleProgress = {
            progress: response.data,
            slug,
        };
        yield put(moduleAllApiResponseSuccess(ModuleActionTypes.USER_VIEW_PROGRESS, responseData));
    } catch (error: any) {
        yield put(moduleApiResponseError(ModuleActionTypes.USER_VIEW_PROGRESS, error));
    }
}

function* userViewChapters({ payload: { slug } }: ModuleDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewChapters, slug);
        yield put(moduleAllApiResponseSuccess(ModuleActionTypes.USER_VIEW_CHAPTERS, response.data));
    } catch (error: any) {
        yield put(moduleApiResponseError(ModuleActionTypes.USER_VIEW_CHAPTERS, error));
    }
}

export function* watchUserViewAll() {
    yield takeEvery(ModuleActionTypes.USER_VIEW_ALL, userViewAll);
}

export function* watchUserViewDetails() {
    yield takeEvery(ModuleActionTypes.USER_VIEW_DETAILS, userViewDetails);
}

export function* watchUserViewChapters() {
    yield takeEvery(ModuleActionTypes.USER_VIEW_CHAPTERS, userViewChapters);
}

export function* watchAdminViewAll() {
    yield takeEvery(ModuleActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewDetails() {
    yield takeEvery(ModuleActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

export function* watchAdminUpsert() {
    yield takeEvery(ModuleActionTypes.ADMIN_UPSERT, adminUpsert);
}

export function* watchAdminViewAllShort() {
    yield takeEvery(ModuleActionTypes.ADMIN_VIEW_ALL_SHORT, adminViewAllShort);
}

export function* watchUserViewProgress() {
    yield takeEvery(ModuleActionTypes.USER_VIEW_PROGRESS, userViewProgress);
}

function* ModuleSaga() {
    yield all([
        fork(watchAdminViewAll),
        fork(watchAdminViewAllShort),
        fork(watchAdminViewDetails),
        fork(watchAdminUpsert),
        fork(watchUserViewAll),
        fork(watchUserViewChapters),
        fork(watchUserViewDetails),
        fork(watchUserViewProgress),
    ]);
}

export default ModuleSaga;

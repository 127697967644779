import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { AdminLogsRequest, AdminTestProblemRequest, AdminTestSourceRequest } from '../../../types/problem/compiler';
import { CompilerActionTypes } from './constants';
import { apiAdminTestProblem, apiAdminTestSource, apiAdminViewLanguages, apiAdminViewLogs } from '../../../helpers/api/problem/compiler';
import { compilerApiResponseError, compilerApiResponseSuccess } from './actions';

type AdminCompilerSourcePayloadType = {
    payload: AdminTestSourceRequest;
    type: string;
};

type AdminCompilerProblemPayloadType = {
    payload: AdminTestProblemRequest;
    type: string;
};

type AdminCompilerLogsPayloadType = {
    payload: AdminLogsRequest;
    type: string;
};

function* adminViewLanguages(): SagaIterator {
    try {
        const response = yield call(apiAdminViewLanguages);
        yield put(compilerApiResponseSuccess(CompilerActionTypes.ADMIN_VIEW_LANGUAGES, response.data));
    } catch (error: any) {
        yield put(compilerApiResponseError(CompilerActionTypes.ADMIN_VIEW_LANGUAGES, error));
    }
}

function* adminGetLogs({ payload }: AdminCompilerLogsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewLogs, payload);
        yield put(compilerApiResponseSuccess(CompilerActionTypes.ADMIN_VIEW_LOGS, response.data));
    } catch (error: any) {
        yield put(compilerApiResponseError(CompilerActionTypes.ADMIN_VIEW_LOGS, error));
    }
}

function* adminTestSource({ payload }: AdminCompilerSourcePayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminTestSource, payload);
        yield put(compilerApiResponseSuccess(CompilerActionTypes.ADMIN_TEST_SOURCE, response.data));
    } catch (error: any) {
        yield put(compilerApiResponseError(CompilerActionTypes.ADMIN_TEST_SOURCE, error));
    }
}

function* adminTestProblem({ payload }: AdminCompilerProblemPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminTestProblem, payload);
        yield put(compilerApiResponseSuccess(CompilerActionTypes.ADMIN_TEST_PROBLEM, response.data));
    } catch (error: any) {
        yield put(compilerApiResponseError(CompilerActionTypes.ADMIN_TEST_PROBLEM, error));
    }
}

export function* watchAdminViewLanguages() {
    yield takeEvery(CompilerActionTypes.ADMIN_VIEW_LANGUAGES, adminViewLanguages);
}

export function* watchAdminTestSource() {
    yield takeEvery(CompilerActionTypes.ADMIN_TEST_SOURCE, adminTestSource);
}

export function* watchAdminTestProblem() {
    yield takeEvery(CompilerActionTypes.ADMIN_TEST_PROBLEM, adminTestProblem);
}

export function* watchAdminGetLogs() {
    yield takeEvery(CompilerActionTypes.ADMIN_VIEW_LOGS, adminGetLogs);
}

function* CompilerSaga() {
    yield all([fork(watchAdminViewLanguages), fork(watchAdminTestSource), fork(watchAdminTestProblem), fork(watchAdminGetLogs)]);
}

export default CompilerSaga;

import { AuthActionTypes } from './constants';
import { UserData, UserForgotPassword, UserResetPassword } from '../../types/user/user';

export type AuthActionType = {
    type:
        | AuthActionTypes.API_RESPONSE_SUCCESS
        | AuthActionTypes.API_RESPONSE_ERROR
        | AuthActionTypes.FORGOT_PASSWORD
        | AuthActionTypes.RESET_PASSWORD
        | AuthActionTypes.GET_USER_INFO
        | AuthActionTypes.LOGIN_USER
        | AuthActionTypes.LOGOUT_USER
        | AuthActionTypes.RESET
        | AuthActionTypes.SIGNUP_USER;
    payload: {} | string;
};

// common success
export const authApiResponseSuccess = (actionType: string, data: UserData | {}): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const authApiResponseError = (actionType: string, error: string): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const loginUser = (user: string, password: string): AuthActionType => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { user, password },
});

export const logoutUser = (): AuthActionType => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

export const signupUser = (email: string, password: string, captchaToken: string): AuthActionType => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: { email, password, captchaToken },
});

export const forgotPassword = (data: UserForgotPassword): AuthActionType => ({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: data,
});

export const resetPassword = (data: UserResetPassword): AuthActionType => ({
    type: AuthActionTypes.RESET_PASSWORD,
    payload: data,
});

export const infoUser = (): AuthActionType => ({
    type: AuthActionTypes.GET_USER_INFO,
    payload: {},
});

export const resetAuth = (): AuthActionType => ({
    type: AuthActionTypes.RESET,
    payload: {},
});

export enum DashboardActionTypes {
    API_RESPONSE_ERROR = '@@dashboard/API_RESPONSE_ERROR',
    API_RESPONSE_SUCCESS = '@@dashboard/API_RESPONSE_SUCCESS',

    USER_COMMENTS = '@@dashboard/USER_COMMENTS',
    USER_LOAD_GRAPH_7_DAYS = '@@dashboard/USER_LOAD_GRAPH_7_DAYS',
    USER_LOAD_GRAPH_HEATMAP = '@@dashboard/USER_LOAD_GRAPH_HEATMAP',
    USER_LOAD_STATS = '@@dashboard/USER_LOAD_STATS',
    USER_STARTED_MODULES = '@@dashboard/USER_STARTED_MODULES',
}

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { ChapterNew } from '../../../types/theory/chapter';
import { UserAnswer } from '../../../types/theory/quiz';

const api = new APICore();

function apiUserViewQuestion(lectureId: string) {
    return api.get(`${apiBaseUrl}/quiz/${lectureId}`, null);
}

function apiUserAnswerQuestion(data: UserAnswer) {
    return api.create(`${apiBaseUrl}/quiz/answer`, data);
}

export { apiUserViewQuestion, apiUserAnswerQuestion };

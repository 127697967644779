import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';

const api = new APICore();

function apiAdminClearCache(): Promise<any> {
    return api.get(`${apiBaseUrl}/admin_panel/clear_cache`);
}

export { apiAdminClearCache };

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { TagNew } from '../../../types/cms/tag';

const api = new APICore();

function apiUserGetAll(entity: string) {
    return api.get(`${apiBaseUrl}/object_tags/${entity}`, null);
}

function apiAdminGetAll() {
    return api.get(`${apiBaseUrl}/tags/admin`, null);
}

function apiAdminView(params: { tagId: number }) {
    return api.get(`${apiBaseUrl}/tags/admin/${params.tagId}`, null);
}

function apiAdminCreate(tag: TagNew) {
    return api.create(`${apiBaseUrl}/tags/admin`, tag);
}

function apiAdminUpdate(tag: TagNew) {
    return api.update(`${apiBaseUrl}/tags/admin`, tag);
}

export { apiUserGetAll, apiAdminGetAll, apiAdminCreate, apiAdminUpdate, apiAdminView };

import { UserRankingActionTypes } from './constants';

export type UserRankingActionType = {
    type: UserRankingActionTypes.API_RESPONSE_SUCCESS | UserRankingActionTypes.API_RESPONSE_ERROR | UserRankingActionTypes.USER_VIEW | UserRankingActionTypes.RESET;
    payload: {} | string;
};

export const purchaseMessageAllApiResponseSuccess = (actionType: string, data: {}): UserRankingActionType => ({
    type: UserRankingActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const purchaseMessageApiResponseError = (actionType: string, error: string): UserRankingActionType => ({
    type: UserRankingActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const rankingUserView = (county: string): UserRankingActionType => ({
    type: UserRankingActionTypes.USER_VIEW,
    payload: { county },
});

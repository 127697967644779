export enum TagActionTypes {
    API_RESPONSE_SUCCESS = '@@tag/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@tag/API_RESPONSE_ERROR',

    USER_VIEW_ALL = '@@tag/USER_GET_ALL',

    ADMIN_VIEW_ALL = '@@tag/ADMIN_VIEW_ALL',
    ADMIN_VIEW = '@@tag/ADMIN_VIEW',
    ADMIN_CREATE = '@@tag/ADMIN_CREATE',
    ADMIN_UPDATE = '@@tag/ADMIN_UPDATE',

    RESET = '@@tag/RESET',
}

export enum AiRoles {
    SYSTEM = 'SYSTEM',
    USER = 'USER',
    ASSISTANT = 'ASSISTANT',
}

export type AiChatMessage = {
    id: string;
    message: string;
    type: AiRoles;
};

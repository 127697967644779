import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { ChapterNew } from '../../../types/theory/chapter';

const api = new APICore();

function apiAdminViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/chapters/admin/${id}`, null);
}

function apiAdminViewAll(page: number, size: number) {
    return api.get(`${apiBaseUrl}/chapters/admin/page/${page}/${size}`, null);
}

function apiAdminViewAllShort() {
    return api.get(`${apiBaseUrl}/chapters/admin/short`, null);
}

function apiAdminCreate(data: ChapterNew) {
    return api.create(`${apiBaseUrl}/chapters/admin`, data);
}

function apiAdminUpdate(data: ChapterNew) {
    return api.update(`${apiBaseUrl}/chapters/admin`, data);
}

export { apiAdminCreate, apiAdminUpdate, apiAdminViewAll, apiAdminViewAllShort, apiAdminViewDetails };

import { ProblemActionTypes } from './constants';
import { ProblemDetails, ProblemNew, ProblemSearchProps, UserEvaluatorRun, UserEvaluatorSubmit } from '../../../types/problem/problem';

export type ProblemActionType = {
    type:
        | ProblemActionTypes.API_RESPONSE_SUCCESS
        | ProblemActionTypes.API_RESPONSE_ERROR
        | ProblemActionTypes.ADMIN_VIEW_ALL
        | ProblemActionTypes.ADMIN_VIEW_ALL_SHORT
        | ProblemActionTypes.ADMIN_VIEW_DETAILS
        | ProblemActionTypes.ADMIN_DELETE
        | ProblemActionTypes.ADMIN_UPSERT
        | ProblemActionTypes.USER_VIEW_ALL
        | ProblemActionTypes.USER_VIEW_DETAILS
        | ProblemActionTypes.USER_VIEW_FOR_LECTURE
        | ProblemActionTypes.USER_VIEW_SHORT
        | ProblemActionTypes.USER_RUN
        | ProblemActionTypes.USER_SUBMIT
        | ProblemActionTypes.RESET;
    payload: {} | string;
};

export const problemApiResponseSuccess = (actionType: string, data: ProblemDetails | {}): ProblemActionType => ({
    type: ProblemActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const problemApiResponseError = (actionType: string, error: string): ProblemActionType => ({
    type: ProblemActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const problemAdminViewAll = (page: number, size: number): ProblemActionType => ({
    type: ProblemActionTypes.ADMIN_VIEW_ALL,
    payload: { page, size },
});

export const problemAdminViewAllShort = (): ProblemActionType => ({
    type: ProblemActionTypes.ADMIN_VIEW_ALL_SHORT,
    payload: {},
});

export const problemAdminViewDetails = (id: string): ProblemActionType => ({
    type: ProblemActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

export const problemAdminUpsert = (data: ProblemNew): ProblemActionType => ({
    type: ProblemActionTypes.ADMIN_UPSERT,
    payload: data,
});

export const problemUserViewAll = (data: ProblemSearchProps): ProblemActionType => ({
    type: ProblemActionTypes.USER_VIEW_ALL,
    payload: data,
});

export const problemUserViewDetails = (slug: string): ProblemActionType => ({
    type: ProblemActionTypes.USER_VIEW_DETAILS,
    payload: { slug },
});

export const problemUserViewForLecture = (id: string): ProblemActionType => ({
    type: ProblemActionTypes.USER_VIEW_FOR_LECTURE,
    payload: { id },
});

export const problemUserRun = (data: UserEvaluatorRun): ProblemActionType => ({
    type: ProblemActionTypes.USER_RUN,
    payload: data,
});

export const problemUserSubmit = (data: UserEvaluatorSubmit): ProblemActionType => ({
    type: ProblemActionTypes.USER_SUBMIT,
    payload: data,
});

export const problemUserAllShort = (): ProblemActionType => ({
    type: ProblemActionTypes.USER_VIEW_SHORT,
    payload: {},
});

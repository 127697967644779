import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
    apiAdminCreate,
    apiAdminUpdate,
    apiAdminViewAll,
    apiAdminViewAllShort,
    apiAdminViewDetails,
    apiUserEvaluatorRun,
    apiUserEvaluatorSubmit,
    apiUserViewAll,
    apiUserViewAllShort,
    apiUserViewDetails,
} from '../../../helpers/api/problem/problem';
import { problemApiResponseSuccess, problemApiResponseError } from './actions';
import { PaginatedProblemDetails, ProblemNew, ProblemSearchProps, ProblemSummary, UserEvaluatorRun, UserEvaluatorSubmit } from '../../../types/problem/problem';
import { ProblemActionTypes } from './constants';

type AdminProblemAllPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

type AdminProblemDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

type UserProblemDetailsPayloadType = {
    payload: {
        slug: string;
    };
    type: string;
};

type AdminProblemUpsertPayloadType = {
    payload: ProblemNew;
    type: string;
};

type UserProblemRunPayloadType = {
    payload: UserEvaluatorRun;
    type: string;
};

type UserProblemSubmitPayloadType = {
    payload: UserEvaluatorSubmit;
    type: string;
};

type UserProblemSearchPayloadType = {
    payload: ProblemSearchProps;
    type: string;
};

function* adminViewAll({ payload: { page, size } }: AdminProblemAllPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll, page, size);
        const responseItems: ProblemSummary[] = response.data.items.map((problem: ProblemSummary) => {
            return Object.assign(problem, {
                id_short: problem.id.substring(0, 8),
            });
        });
        const responseData: PaginatedProblemDetails = {
            items: responseItems,
            total: response.data.total,
        };

        yield put(problemApiResponseSuccess(ProblemActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(problemApiResponseError(ProblemActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewAllShort(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAllShort);
        yield put(problemApiResponseSuccess(ProblemActionTypes.ADMIN_VIEW_ALL_SHORT, response.data));
    } catch (error: any) {
        yield put(problemApiResponseError(ProblemActionTypes.ADMIN_VIEW_ALL_SHORT, error));
    }
}

function* adminViewDetails({ payload: { id } }: AdminProblemDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(problemApiResponseSuccess(ProblemActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(problemApiResponseError(ProblemActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

function* adminUpsert({ payload }: AdminProblemUpsertPayloadType): SagaIterator {
    try {
        const response = yield call(payload.id ? apiAdminUpdate : apiAdminCreate, payload);
        yield put(problemApiResponseSuccess(ProblemActionTypes.ADMIN_UPSERT, response.data));
    } catch (error: any) {
        yield put(problemApiResponseError(ProblemActionTypes.ADMIN_UPSERT, error));
    }
}

function* userViewAll({ payload }: UserProblemSearchPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewAll, payload);
        const responseItems: ProblemSummary[] = response.data.items.map((problem: ProblemSummary) => {
            return Object.assign(problem, {
                id_short: problem.id.substring(0, 8),
            });
        });
        const responseData: PaginatedProblemDetails = {
            items: responseItems,
            total: response.data.total,
        };
        yield put(problemApiResponseSuccess(ProblemActionTypes.USER_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(problemApiResponseError(ProblemActionTypes.USER_VIEW_ALL, error));
    }
}

function* userView({ payload: { slug } }: UserProblemDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewDetails, slug);
        yield put(problemApiResponseSuccess(ProblemActionTypes.USER_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(problemApiResponseError(ProblemActionTypes.USER_VIEW_DETAILS, error));
    }
}

function* userRun({ payload }: UserProblemRunPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserEvaluatorRun, payload);
        yield put(problemApiResponseSuccess(ProblemActionTypes.USER_RUN, response.data));
    } catch (error: any) {
        yield put(problemApiResponseError(ProblemActionTypes.USER_RUN, error));
    }
}

function* userSubmit({ payload }: UserProblemSubmitPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserEvaluatorSubmit, payload);
        yield put(problemApiResponseSuccess(ProblemActionTypes.USER_SUBMIT, response.data));
    } catch (error: any) {
        yield put(problemApiResponseError(ProblemActionTypes.USER_SUBMIT, error));
    }
}

function* userViewAllShort(): SagaIterator {
    try {
        const response = yield call(apiUserViewAllShort);
        yield put(problemApiResponseSuccess(ProblemActionTypes.USER_VIEW_SHORT, response.data));
    } catch (error: any) {
        yield put(problemApiResponseError(ProblemActionTypes.USER_VIEW_SHORT, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(ProblemActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewAllShort() {
    yield takeEvery(ProblemActionTypes.ADMIN_VIEW_ALL_SHORT, adminViewAllShort);
}

export function* watchAdminViewDetails() {
    yield takeEvery(ProblemActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

export function* watchAdminUpsert() {
    yield takeEvery(ProblemActionTypes.ADMIN_UPSERT, adminUpsert);
}

export function* watchUserViewAll() {
    yield takeEvery(ProblemActionTypes.USER_VIEW_ALL, userViewAll);
}

export function* watchUserView() {
    yield takeEvery(ProblemActionTypes.USER_VIEW_DETAILS, userView);
}

export function* watchUserRun() {
    yield takeEvery(ProblemActionTypes.USER_RUN, userRun);
}

export function* watchUserSubmit() {
    yield takeEvery(ProblemActionTypes.USER_SUBMIT, userSubmit);
}

export function* watchUserViewAllShort() {
    yield takeEvery(ProblemActionTypes.USER_VIEW_SHORT, userViewAllShort);
}

function* ProblemSaga() {
    yield all([
        fork(watchAdminUpsert),
        fork(watchAdminViewAll),
        fork(watchAdminViewAllShort),
        fork(watchAdminViewDetails),
        fork(watchUserRun),
        fork(watchUserSubmit),
        fork(watchUserView),
        fork(watchUserViewAll),
        fork(watchUserViewAllShort),
    ]);
}

export default ProblemSaga;

import { ShopBundleActionTypes } from './constants';
import { BundleDetails, BundleShort, BundleSummary } from '../../../types/shop/bundle';

const INIT_STATE = {
    apiUpsertSuccess: false,
    adminAllBundles: null,
    adminAllBundlesShort: null,
    userBundleDetails: null,
    userAllBundles: null,
    loading: false,
};

type ShopBundleActionType = {
    type:
        | ShopBundleActionTypes.API_RESPONSE_SUCCESS
        | ShopBundleActionTypes.API_RESPONSE_ERROR
        | ShopBundleActionTypes.ADMIN_VIEW_ALL
        | ShopBundleActionTypes.ADMIN_VIEW_ALL_SHORT
        | ShopBundleActionTypes.USER_VIEW_DETAILS
        | ShopBundleActionTypes.USER_VIEW_ALL;
    payload: {
        actionType?: string;
        data?: BundleDetails | BundleDetails[] | BundleSummary | {};
        error?: string;
    };
};

type State = {
    adminAllBundles?: BundleSummary[] | null;
    adminAllBundlesShort?: BundleShort[] | null;
    userBundleDetails?: BundleDetails | null;
    userAllBundles?: BundleDetails[] | null;
    loading?: boolean;
};

const ShopBundle = (state: State = INIT_STATE, action: ShopBundleActionType) => {
    switch (action.type) {
        case ShopBundleActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ShopBundleActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllBundles: action.payload.data,
                        loading: false,
                    };
                }
                case ShopBundleActionTypes.USER_VIEW_DETAILS: {
                    return {
                        ...state,
                        userBundleDetails: action.payload.data,
                    };
                }
                case ShopBundleActionTypes.ADMIN_VIEW_ALL_SHORT: {
                    return {
                        ...state,
                        adminAllBundlesShort: action.payload.data,
                    };
                }
                case ShopBundleActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        userAllBundles: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case ShopBundleActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ShopBundleActionTypes.USER_VIEW_DETAILS: {
                    return {
                        ...state,
                        userBundleDetails: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case ShopBundleActionTypes.USER_VIEW_DETAILS:
            return {
                ...state,
                error: false,
                userBundleDetails: null,
                apiUpsertSuccess: false,
            };

        case ShopBundleActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                adminAllBundles: null,
                loading: true,
                error: false,
            };

        case ShopBundleActionTypes.ADMIN_VIEW_ALL_SHORT:
            return {
                ...state,
                adminAllBundlesShort: null,
                loading: true,
                error: false,
            };

        case ShopBundleActionTypes.USER_VIEW_ALL:
            return {
                ...state,
                userAllBundles: null,
                loading: true,
                error: false,
            };

        default:
            return { ...state };
    }
};

export default ShopBundle;

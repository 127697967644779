import { SchoolActionTypes } from './constants';
import { SchoolDetails, SchoolNew } from '../../../types/cms/school';

export type SchoolActionType = {
    type:
        | SchoolActionTypes.ADMIN_VIEW_ALL
        | SchoolActionTypes.ADMIN_CREATE
        | SchoolActionTypes.ADMIN_UPDATE
        | SchoolActionTypes.ADMIN_VIEW
        | SchoolActionTypes.ADMIN_DELETE
        | SchoolActionTypes.API_RESPONSE_SUCCESS
        | SchoolActionTypes.API_RESPONSE_ERROR
        | SchoolActionTypes.USER_VIEW_ALL
        | SchoolActionTypes.RESET;
    payload: {} | string;
};

export const schoolAdminViewAll = (): SchoolActionType => ({
    type: SchoolActionTypes.ADMIN_VIEW_ALL,
    payload: {},
});

export const schoolAdminDelete = (schoolId: string): SchoolActionType => ({
    type: SchoolActionTypes.ADMIN_DELETE,
    payload: { schoolId },
});

export const schoolAdminView = (schoolId: string): SchoolActionType => ({
    type: SchoolActionTypes.ADMIN_VIEW,
    payload: { schoolId },
});

export const schoolAdminUpdate = (school: SchoolNew): SchoolActionType => ({
    type: SchoolActionTypes.ADMIN_UPDATE,
    payload: school,
});

export const schoolAdminCreate = (school: SchoolNew): SchoolActionType => ({
    type: SchoolActionTypes.ADMIN_CREATE,
    payload: school,
});

export const schoolApiResponseSuccess = (actionType: string, data: SchoolDetails | {}): SchoolActionType => ({
    type: SchoolActionTypes.API_RESPONSE_SUCCESS,
    payload: {
        actionType,
        data,
    },
});

export const schoolApiResponseError = (actionType: string, error: string): SchoolActionType => ({
    type: SchoolActionTypes.API_RESPONSE_ERROR,
    payload: {
        actionType,
        error,
    },
});

import { ProblemSearchPanelActionTypes } from './constants';

const INIT_STATE = {
    scDifficulty: null,
    scOrder: 'DESC',
    scPage: 1,
    scSolved: null,
    scSort: 'UPDATED_AT',
    scTags: null,
    scTitle: '',
};

type ProblemSearchPanelActionType = {
    type:
        | ProblemSearchPanelActionTypes.FORM_SET_TAGS
        | ProblemSearchPanelActionTypes.FORM_SET_SOLVED
        | ProblemSearchPanelActionTypes.FORM_SET_DIFFICULTY
        | ProblemSearchPanelActionTypes.FORM_SET_ORDER
        | ProblemSearchPanelActionTypes.FORM_SET_PAGE
        | ProblemSearchPanelActionTypes.FORM_SET_SORT
        | ProblemSearchPanelActionTypes.FORM_SET_TITLE;
    payload: {
        actionType?: string;
        data?: string | string[] | {};
        dataIndex?: number;
        error?: string;
    };
};

type State = {
    scDifficulty: number | null;
    scOrder: string | null;
    scPage: number | null;
    scSolved: number | null;
    scSort: string | null;
    scTags: string[] | null;
    scTitle: string;
};

const ProblemSearchPanel = (state: State = INIT_STATE, action: ProblemSearchPanelActionType) => {
    switch (action.type) {
        case ProblemSearchPanelActionTypes.FORM_SET_DIFFICULTY:
            return {
                ...state,
                scDifficulty: action.payload.data,
            };

        case ProblemSearchPanelActionTypes.FORM_SET_ORDER:
            return {
                ...state,
                scOrder: action.payload.data,
            };

        case ProblemSearchPanelActionTypes.FORM_SET_PAGE:
            return {
                ...state,
                scPage: action.payload.data,
            };

        case ProblemSearchPanelActionTypes.FORM_SET_SOLVED:
            return {
                ...state,
                scSolved: action.payload.data,
            };

        case ProblemSearchPanelActionTypes.FORM_SET_SORT:
            return {
                ...state,
                scSort: action.payload.data,
            };

        case ProblemSearchPanelActionTypes.FORM_SET_TAGS:
            return {
                ...state,
                scTags: action.payload.data,
            };

        case ProblemSearchPanelActionTypes.FORM_SET_TITLE:
            return {
                ...state,
                scTitle: action.payload.data,
            };

        default:
            return { ...state };
    }
};

export default ProblemSearchPanel;

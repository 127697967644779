import { AiConversationActionTypes } from './constants';
import { AiConversationDetails, AiConversationSummary } from '../../../types/ai/aiConversation';

const INIT_STATE = {
    adminConversationDetails: null,
    adminAllConversations: null,
    loading: false,
};

type AiConversationActionType = {
    type:
        | AiConversationActionTypes.API_RESPONSE_SUCCESS
        | AiConversationActionTypes.API_RESPONSE_ERROR
        | AiConversationActionTypes.ADMIN_VIEW_ALL
        | AiConversationActionTypes.ADMIN_VIEW_DETAILS
        | AiConversationActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: AiConversationDetails | AiConversationSummary[] | {};
        error?: string;
    };
};

type State = {
    adminAllConversations?: AiConversationSummary[] | null;
    adminConversationDetails?: AiConversationDetails | null;
    loading?: boolean;
};

const AiConversation = (state: State = INIT_STATE, action: AiConversationActionType) => {
    switch (action.type) {
        case AiConversationActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AiConversationActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllConversations: action.payload.data,
                        loading: false,
                    };
                }
                case AiConversationActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminConversationDetails: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case AiConversationActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return { ...state };
            }

        case AiConversationActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                loading: true,
                error: false,
            };

        case AiConversationActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default AiConversation;

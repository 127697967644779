import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { ProfileActionTypes } from './constants';
import { apiUserLoadProfile } from '../../../helpers/api/user/profile';
import { profileApiResponseError, profileApiResponseSuccess } from './actions';
import { CommentDetails, CommentSummary } from '../../../types/cms/comment';

type ProfilePayload = {
    payload: {
        username: string;
    };
    type: string;
};

function* userLoadProfileData({ payload: { username } }: ProfilePayload): SagaIterator {
    try {
        const response = yield call(apiUserLoadProfile, username);
        const comments: CommentDetails[] = [];
        response.data.allComments.lectures.map((comment: CommentDetails) => {
            comments.push(comment);
        });
        response.data.allComments.problems.map((comment: CommentDetails) => {
            comments.push(comment);
        });
        response.data.allComments.modules.map((comment: CommentDetails) => {
            comments.push(comment);
        });
        comments.sort((a: CommentDetails, b: CommentDetails): number => {
            if (a.updatedAt < b.updatedAt) return 1;
            if (a.updatedAt === b.updatedAt) return 0;
            return -1;
        });
        yield put(
            profileApiResponseSuccess(ProfileActionTypes.USER_LOAD_PROFILE, {
                ...response.data,
                allComments: comments,
            }),
        );
    } catch (error: any) {
        yield put(profileApiResponseError(ProfileActionTypes.USER_LOAD_PROFILE, error));
    }
}
export function* watchUserLoadProfileData() {
    yield takeEvery(ProfileActionTypes.USER_LOAD_PROFILE, userLoadProfileData);
}

function* energyLogsSaga() {
    yield all([fork(watchUserLoadProfileData)]);
}

export default energyLogsSaga;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
    apiUserDeleteAccount,
    apiUserUpdatePassword,
    apiUserUpdatePersonalData,
    apiUserUpdatePreferenceData,
    apiUserUpdateProfileData,
    apiUserUploadProfileAvatar,
    apiUserViewPersonalData,
    apiUserViewPreferenceData,
    apiUserViewProfileData,
} from '../../../helpers/api/user/settings';
import {
    PersonalDataBody,
    PersonalDataResponse,
    PreferenceResponse,
    ProfileDataBody,
    ProfileDataResponse,
    UserDeleteAccount,
    UserNewPassword,
} from '../../../types/user/settings';
import { settingsApiResponseError, settingsApiResponseSuccess } from './actions';
import { SettingsActionTypes } from './constants';

type PersonalDataPayloadType = {
    payload: PersonalDataBody;
    type: string;
};

type ProfileDataPayloadType = {
    payload: ProfileDataBody;
    type: string;
};

type PasswordDataPayloadType = {
    payload: UserNewPassword;
    type: string;
};

type PreferenceDataPayloadType = {
    payload: PreferenceResponse;
    type: string;
};

type UserDeleteAccountPayloadType = {
    payload: UserDeleteAccount;
    type: string;
};

function* userViewPersonalData(): SagaIterator {
    try {
        const response = yield call(apiUserViewPersonalData);
        const responseData: PersonalDataResponse = response.data;
        yield put(settingsApiResponseSuccess(SettingsActionTypes.USER_VIEW_PERSONAL_DATA, responseData));
    } catch (error: any) {
        yield put(settingsApiResponseError(SettingsActionTypes.USER_VIEW_PERSONAL_DATA, error));
    }
}

function* userViewProfileData(): SagaIterator {
    try {
        const response = yield call(apiUserViewProfileData);
        const responseData: ProfileDataResponse = response.data;
        yield put(settingsApiResponseSuccess(SettingsActionTypes.USER_VIEW_PROFILE_DATA, responseData));
    } catch (error: any) {
        yield put(settingsApiResponseError(SettingsActionTypes.USER_VIEW_PROFILE_DATA, error));
    }
}

function* userViewPreferenceData(): SagaIterator {
    try {
        const response = yield call(apiUserViewPreferenceData);
        const responseData: PreferenceResponse = response.data;
        yield put(settingsApiResponseSuccess(SettingsActionTypes.USER_VIEW_PREFERENCE_DATA, responseData));
    } catch (error: any) {
        yield put(settingsApiResponseError(SettingsActionTypes.USER_VIEW_PREFERENCE_DATA, error));
    }
}

function* userUploadProfileAvatar({ payload }: any): SagaIterator {
    try {
        const response = yield call(apiUserUploadProfileAvatar, payload);
        yield put(settingsApiResponseSuccess(SettingsActionTypes.USER_UPDATE_AVATAR, response.data));
    } catch (error: any) {
        yield put(settingsApiResponseError(SettingsActionTypes.USER_UPDATE_AVATAR, error));
    }
}

function* userUpdateProfileData({ payload }: ProfileDataPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserUpdateProfileData, payload);
        yield put(settingsApiResponseSuccess(SettingsActionTypes.USER_UPDATE_PROFILE_DATA, response.data));
    } catch (error: any) {
        yield put(settingsApiResponseError(SettingsActionTypes.USER_UPDATE_PROFILE_DATA, error));
    }
}

function* userUpdatePersonalData({ payload }: PersonalDataPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserUpdatePersonalData, payload);
        yield put(settingsApiResponseSuccess(SettingsActionTypes.USER_UPDATE_PERSONAL_DATA, response.data));
    } catch (error: any) {
        yield put(settingsApiResponseError(SettingsActionTypes.USER_UPDATE_PERSONAL_DATA, error));
    }
}

function* userUpdatePasswordData({ payload }: PasswordDataPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserUpdatePassword, payload);
        yield put(settingsApiResponseSuccess(SettingsActionTypes.USER_UPDATE_PASSWORD, response.data));
    } catch (error: any) {
        yield put(settingsApiResponseError(SettingsActionTypes.USER_UPDATE_PASSWORD, error));
    }
}

function* userUpdatePreferenceData({ payload }: PreferenceDataPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserUpdatePreferenceData, payload);
        yield put(settingsApiResponseSuccess(SettingsActionTypes.USER_UPDATE_PREFERENCE_DATA, response.data));
    } catch (error: any) {
        yield put(settingsApiResponseError(SettingsActionTypes.USER_UPDATE_PREFERENCE_DATA, error));
    }
}

function* userDeleteAccount({ payload }: UserDeleteAccountPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserDeleteAccount, payload);
        yield put(settingsApiResponseSuccess(SettingsActionTypes.USER_DELETE_ACCOUNT, response.data));
    } catch (error: any) {
        yield put(settingsApiResponseError(SettingsActionTypes.USER_DELETE_ACCOUNT, error));
    }
}

export function* watchUserViewPersonalData() {
    yield takeEvery(SettingsActionTypes.USER_VIEW_PERSONAL_DATA, userViewPersonalData);
}

export function* watchUserViewProfileData() {
    yield takeEvery(SettingsActionTypes.USER_VIEW_PROFILE_DATA, userViewProfileData);
}

export function* watchUserViewPreferenceData() {
    yield takeEvery(SettingsActionTypes.USER_VIEW_PREFERENCE_DATA, userViewPreferenceData);
}

export function* watchUserUpdatePersonalData() {
    yield takeEvery(SettingsActionTypes.USER_UPDATE_PERSONAL_DATA, userUpdatePersonalData);
}

export function* watchUserUploadProfileAvatar() {
    yield takeEvery(SettingsActionTypes.USER_UPDATE_AVATAR, userUploadProfileAvatar);
}

export function* watchUserUpdateProfileData() {
    yield takeEvery(SettingsActionTypes.USER_UPDATE_PROFILE_DATA, userUpdateProfileData);
}

export function* watchUserUpdatePassword() {
    yield takeEvery(SettingsActionTypes.USER_UPDATE_PASSWORD, userUpdatePasswordData);
}

export function* watchUserUpdatePreferenceData() {
    yield takeEvery(SettingsActionTypes.USER_UPDATE_PREFERENCE_DATA, userUpdatePreferenceData);
}

export function* watchUserDeleteAccount() {
    yield takeEvery(SettingsActionTypes.USER_DELETE_ACCOUNT, userDeleteAccount);
}

function* settingsSaga() {
    yield all([
        fork(watchUserViewPersonalData),
        fork(watchUserViewProfileData),
        fork(watchUserViewPreferenceData),
        fork(watchUserDeleteAccount),
        fork(watchUserUpdatePreferenceData),
        fork(watchUserUpdatePersonalData),
        fork(watchUserUploadProfileAvatar),
        fork(watchUserUpdateProfileData),
        fork(watchUserUpdatePassword),
    ]);
}

export default settingsSaga;

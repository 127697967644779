import { ModuleActionTypes } from './constants';
import { ModuleDetails, ModuleProgress, ModuleSelectableShort, ModuleSummary } from '../../../types/theory/module';
import { ChapterResponse } from '../../../types/theory/chapter';

const INIT_STATE = {
    apiUpsertSuccess: false,
    adminAllModules: null,
    adminAllModulesShort: null,
    adminModuleDetails: null,
    userAllModules: null,
    userModuleDetails: null,
    userModuleChapters: null,
    userDefaultTab: 'module-description',
    loading: false,
};

type ModuleActionType = {
    type:
        | ModuleActionTypes.API_RESPONSE_SUCCESS
        | ModuleActionTypes.API_RESPONSE_ERROR
        | ModuleActionTypes.USER_VIEW_ALL
        | ModuleActionTypes.USER_VIEW_CHAPTERS
        | ModuleActionTypes.USER_VIEW_DETAILS
        | ModuleActionTypes.USER_DEFAULT_TAB
        | ModuleActionTypes.USER_VIEW_PROGRESS
        | ModuleActionTypes.ADMIN_VIEW_ALL
        | ModuleActionTypes.ADMIN_VIEW_DETAILS
        | ModuleActionTypes.ADMIN_UPSERT
        | ModuleActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: ModuleProgress | ModuleSummary | ModuleDetails | string | {};
        error?: string;
    };
};

type State = {
    apiUpsertSuccess: boolean;
    adminAllModules?: ModuleSummary[] | null;
    adminAllModulesShort?: ModuleSelectableShort[] | null;
    adminModuleDetails?: ModuleDetails | null;
    userAllModules?: ModuleSummary[] | null;
    userModuleDetails?: ModuleDetails | null;
    userModuleChapters?: ChapterResponse[] | null;
    userDefaultTab: string;
    loading?: boolean;
};

const ModuleTheory = (state: State = INIT_STATE, action: ModuleActionType) => {
    switch (action.type) {
        case ModuleActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ModuleActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllModules: action.payload.data,
                    };
                }
                case ModuleActionTypes.ADMIN_VIEW_ALL_SHORT: {
                    return {
                        ...state,
                        adminAllModulesShort: action.payload.data,
                    };
                }
                case ModuleActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminModuleDetails: action.payload.data,
                    };
                }
                case ModuleActionTypes.ADMIN_UPSERT: {
                    return {
                        ...state,
                        apiUpsertSuccess: true,
                    };
                }
                case ModuleActionTypes.USER_VIEW_PROGRESS: {
                    const moduleProgress: ModuleProgress = action.payload.data as ModuleProgress;
                    const allModules = state.userAllModules?.map((module) => {
                        if (module.slug === moduleProgress.slug) {
                            return {
                                ...module,
                                userProgress: moduleProgress.progress,
                            };
                        }
                        return module;
                    });
                    return {
                        ...state,
                        userAllModules: allModules,
                        loading: false,
                    };
                }
                case ModuleActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        userAllModules: action.payload.data,
                        loading: false,
                    };
                }
                case ModuleActionTypes.USER_VIEW_DETAILS: {
                    return {
                        ...state,
                        userModuleDetails: action.payload.data,
                        loading: false,
                    };
                }
                case ModuleActionTypes.USER_VIEW_CHAPTERS: {
                    return {
                        ...state,
                        userModuleChapters: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ModuleActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ModuleActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminModuleDetails: null,
                        error: action.payload.error,
                    };
                }
                case ModuleActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ModuleActionTypes.ADMIN_VIEW_DETAILS:
            return {
                ...state,
                error: false,
                adminModuleDetails: null,
                apiUpsertSuccess: false,
            };

        case ModuleActionTypes.ADMIN_UPSERT:
            return {
                ...state,
                apiUpsertSuccess: false,
            };

        case ModuleActionTypes.ADMIN_VIEW_ALL:
        case ModuleActionTypes.USER_VIEW_ALL:
        case ModuleActionTypes.USER_VIEW_CHAPTERS:
            return {
                ...state,
                loading: true,
                error: false,
            };

        case ModuleActionTypes.USER_VIEW_DETAILS:
            return {
                ...state,
                userModuleDetails: null,
                loading: true,
            };

        case ModuleActionTypes.USER_DEFAULT_TAB:
            return {
                ...state,
                userDefaultTab: action.payload.data,
            };

        case ModuleActionTypes.RESET:
            return {
                ...state,
                userAllModules: null,
                userModuleDetails: null,
                userModuleChapters: null,
                loading: false,
                apiUpsertSuccess: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default ModuleTheory;

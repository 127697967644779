import { SecurityLogActionTypes } from './constants';

export type SecurityLogActionType = {
    type: SecurityLogActionTypes.API_RESPONSE_SUCCESS | SecurityLogActionTypes.API_RESPONSE_ERROR | SecurityLogActionTypes.ADMIN_VIEW_ALL | SecurityLogActionTypes.ADMIN_VIEW_DETAILS;
    payload: {} | string;
};

export const securityLogApiResponseSuccess = (actionType: string, data: {}): SecurityLogActionType => ({
    type: SecurityLogActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const securityLogApiResponseError = (actionType: string, error: string): SecurityLogActionType => ({
    type: SecurityLogActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const securityLogAdminViewAll = (page: number, size: number): SecurityLogActionType => ({
    type: SecurityLogActionTypes.ADMIN_VIEW_ALL,
    payload: { page, size },
});

export const securityLogAdminViewDetails = (id: string): SecurityLogActionType => ({
    type: SecurityLogActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

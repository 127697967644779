import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';

const api = new APICore();

function apiUserCreate(model: string, modelId: string) {
    return api.create(`${apiBaseUrl}/purchases/${model}/${modelId}`, null);
}

export { apiUserCreate };

export enum ShopInvoiceActionTypes {
    API_RESPONSE_SUCCESS = '@@shopInvoice/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@shopInvoice/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL = '@@shopInvoice/ADMIN_VIEW_ALL',
    ADMIN_VIEW_DETAILS = '@@shopInvoice/ADMIN_VIEW_ALL_SHORT',

    USER_VIEW_ALL = '@@shopInvoice/USER_VIEW_ALL',
    USER_VIEW_DETAILS = '@@shopInvoice/ADMIN_VIEW_DETAILS',
    USER_CHECK_STATUS = '@@shopInvoice/USER_CHECK_STATUS',
    USER_SEND_INVOICE = '@@shopInvoice/USER_SEND_INVOICE',
}

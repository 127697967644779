import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { QuizActionTypes } from './constants';
import { apiUserViewQuestion, apiUserAnswerQuestion } from '../../../helpers/api/theory/quiz';
import { quizApiResponseError, quizApiResponseSuccess } from './actions';

type QuizQuestionDetailsPayloadType = {
    payload: {
        lectureId: string;
    };
    type: string;
};

type QuizQuestionAnswerPayloadType = {
    payload: {
        questionId: string;
        answerIds: string[];
    };
    type: string;
};

function* userViewQuestion({ payload: { lectureId } }: QuizQuestionDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewQuestion, lectureId);
        yield put(quizApiResponseSuccess(QuizActionTypes.USER_VIEW_LECTURE_QUESTION, response.data));
    } catch (error: any) {
        yield put(quizApiResponseError(QuizActionTypes.USER_VIEW_LECTURE_QUESTION, error));
    }
}

function* userAnswerQuestion({ payload: { questionId, answerIds } }: QuizQuestionAnswerPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserAnswerQuestion, { questionId, answerIds });
        yield put(quizApiResponseSuccess(QuizActionTypes.USER_ANSWER_QUESTION, response.data));
    } catch (error: any) {
        yield put(quizApiResponseError(QuizActionTypes.USER_ANSWER_QUESTION, error));
    }
}

export function* watchUserViewQuestion() {
    yield takeEvery(QuizActionTypes.USER_VIEW_LECTURE_QUESTION, userViewQuestion);
}

export function* watchUserAnswerQuestion() {
    yield takeEvery(QuizActionTypes.USER_ANSWER_QUESTION, userAnswerQuestion);
}

function* QuizSaga() {
    yield all([fork(watchUserViewQuestion), fork(watchUserAnswerQuestion)]);
}

export default QuizSaga;

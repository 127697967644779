import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';

const api = new APICore();

function apiAdminViewAll() {
    return api.get(`${apiBaseUrl}/packages/summary`, null);
}

function apiAdminViewAllShort() {
    return api.get(`${apiBaseUrl}/packages/short`, null);
}

function apiUserViewAll() {
    return api.get(`${apiBaseUrl}/packages/all`, null);
}

function apiUserViewDetails(slug: string) {
    return api.get(`${apiBaseUrl}/packages/${slug}`, null);
}

export { apiAdminViewAll, apiUserViewDetails, apiAdminViewAllShort, apiUserViewAll };

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { commentApiResponseError, commentApiResponseSuccess } from './actions';
import { CommentActionTypes } from './constants';
import { apiAdminDelete, apiAdminViewAll, apiAdminViewDetails, apiUserGet, apiUserSubmit, apiAdminUpdate } from '../../../helpers/api/cms/comment';
import { CommentFilter, CommentNew, CommentSummary, PaginatedCommentSummary } from '../../../types/cms/comment';
import { convertToDateTime } from '../../../utils';
import { PaginatedAnswerDetails } from '../../../types/quiz/answer';

type CommentAdminDeletePayloadType = {
    payload: {
        domain: string;
        commentId: string;
    };
    type: string;
};

type CommentAdminDetailsPayloadType = {
    payload: {
        domain: string;
        id: string;
    };
    type: string;
};

type CommentUserGetPayloadType = {
    payload: {
        domain: string;
        domainId: string;
    };
    type: string;
};

type CommentUpdatePayloadType = {
    payload: CommentNew;
    type: string;
};

type CommentData = {
    payload: CommentNew;
    type: string;
};

type CommentFilterPayloadType = {
    payload: CommentFilter;
    type: string;
};

function* adminViewAll({ payload }: CommentFilterPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll, payload);
        /*
        const responseItems: ProblemSummary[] = response.data.items.map((problem: ProblemSummary) => {
            return Object.assign(problem, {
                id_short: problem.id.substring(0, 8),
            });
        });
        const responseData: PaginatedProblemDetails = {
            items: responseItems,
            total: response.data.total,
        };
         */
        const responseItems: CommentSummary[] = response.data.items.map((comment: CommentSummary) => {
            return Object.assign(comment, {
                id_short: comment.id.substring(0, 8),
            });
        });
        const responseData: PaginatedCommentSummary = {
            items: responseItems,
            total: response.data.total,
        };
        yield put(commentApiResponseSuccess(CommentActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(commentApiResponseError(CommentActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminUpdate({ payload: commentData }: CommentUpdatePayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminUpdate, commentData);
        yield put(commentApiResponseSuccess(CommentActionTypes.ADMIN_UPDATE, response.data));
    } catch (error: any) {
        yield put(commentApiResponseError(CommentActionTypes.ADMIN_UPDATE, error));
    }
}
function* adminViewDetails({ payload: { id, domain } }: CommentAdminDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, domain, id);
        yield put(commentApiResponseSuccess(CommentActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(commentApiResponseError(CommentActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

export function* adminDeleteComment({ payload: { domain, commentId } }: CommentAdminDeletePayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminDelete, domain, commentId);
        yield put(commentApiResponseSuccess(CommentActionTypes.ADMIN_DELETE, response.data));
    } catch (error: any) {
        yield put(commentApiResponseError(CommentActionTypes.ADMIN_DELETE, error));
    }
}

function* userViewComments({ payload: { domain, domainId } }: CommentUserGetPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserGet, domain, domainId);
        yield put(commentApiResponseSuccess(CommentActionTypes.USER_GET, response.data));
    } catch (error: any) {
        yield put(commentApiResponseError(CommentActionTypes.USER_GET, error));
    }
}

function* userSendComment({ payload }: CommentData): SagaIterator {
    try {
        const response = yield call(apiUserSubmit, payload);
        yield put(commentApiResponseSuccess(CommentActionTypes.USER_SEND, response.data));
    } catch (error: any) {
        yield put(commentApiResponseError(CommentActionTypes.USER_SEND, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(CommentActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewDetails() {
    yield takeEvery(CommentActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

export function* watchUserGet() {
    yield takeEvery(CommentActionTypes.USER_GET, userViewComments);
}

export function* watchUserSendComment() {
    yield takeEvery(CommentActionTypes.USER_SEND, userSendComment);
}

export function* watchAdminDeleteComment() {
    yield takeEvery(CommentActionTypes.ADMIN_DELETE, adminDeleteComment);
}

export function* watchAdminUpdate() {
    yield takeEvery(CommentActionTypes.ADMIN_UPDATE, adminUpdate);
}

function* CommentSaga() {
    yield all([fork(watchAdminViewAll), fork(watchAdminViewDetails), fork(watchAdminDeleteComment), fork(watchUserSendComment), fork(watchUserGet), fork(watchAdminUpdate)]);
}

export default CommentSaga;

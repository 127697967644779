import { LoginLogActionTypes } from './constants';
import { LoginLogDetails } from '../../../types/log/login';

const INIT_STATE = {
    apiUpsertSuccess: false,
    adminAllLoginLogs: null,
    adminLoginLogDetails: null,
    loading: false,
};

type LoginLogActionType = {
    type: LoginLogActionTypes.API_RESPONSE_SUCCESS | LoginLogActionTypes.API_RESPONSE_ERROR | LoginLogActionTypes.ADMIN_VIEW_ALL | LoginLogActionTypes.ADMIN_VIEW_DETAILS;
    payload: {
        actionType?: string;
        data?: LoginLogDetails | {};
        error?: string;
    };
};

type State = {
    apiUpsertSuccess: boolean;
    adminAllLoginLogs?: LoginLogDetails[] | null;
    adminLoginLogDetails?: LoginLogDetails | null;
    loading?: boolean;
};

const LoginLog = (state: State = INIT_STATE, action: LoginLogActionType) => {
    switch (action.type) {
        case LoginLogActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case LoginLogActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllLoginLogs: action.payload.data,
                        loading: false,
                    };
                }
                case LoginLogActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminLoginLogDetails: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case LoginLogActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case LoginLogActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminLoginLogDetails: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case LoginLogActionTypes.ADMIN_VIEW_DETAILS:
            return {
                ...state,
                error: false,
                adminLoginLogDetails: null,
                apiUpsertSuccess: false,
            };

        case LoginLogActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                adminAllLoginLogs: null,
                loading: true,
                error: false,
            };

        default:
            return { ...state };
    }
};

export default LoginLog;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiAdminCreate, apiAdminUpdate, apiAdminViewAll, apiAdminViewAllShort, apiAdminViewDetails } from '../../../helpers/api/theory/chapter';
import { chapterAllApiResponseSuccess, chapterApiResponseError } from './actions';
import { ChapterActionTypes } from './constants';
import { ChapterNew, ChapterSummary, PaginatedChapterSummary } from '../../../types/theory/chapter';
import { LectureSummary, PaginatedLectureSummary } from '../../../types/theory/lecture';

type ChapterDetailsPayloadType = {
    payload: {
        slug: string;
    };
    type: string;
};

type AdminChapterAllPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

type AdminChapterDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

type AdminChapterUpsertPayloadType = {
    payload: ChapterNew;
    type: string;
};

function* adminViewAll({ payload: { page, size } }: AdminChapterAllPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll, page, size);
        const responseItems: ChapterSummary[] = response.data.items.map((chapter: ChapterSummary) => {
            return Object.assign(chapter, {
                id_short: chapter.id.substring(0, 8),
            });
        });
        const responseData: PaginatedChapterSummary = {
            items: responseItems,
            total: response.data.total,
        };

        yield put(chapterAllApiResponseSuccess(ChapterActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(chapterApiResponseError(ChapterActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewAllShort(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAllShort);
        yield put(chapterAllApiResponseSuccess(ChapterActionTypes.ADMIN_VIEW_ALL_SHORT, response.data));
    } catch (error: any) {
        yield put(chapterApiResponseError(ChapterActionTypes.ADMIN_VIEW_ALL_SHORT, error));
    }
}

function* adminViewDetails({ payload: { id } }: AdminChapterDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(chapterAllApiResponseSuccess(ChapterActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(chapterApiResponseError(ChapterActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

function* adminUpsert({ payload }: AdminChapterUpsertPayloadType): SagaIterator {
    try {
        const response = yield call(payload.id ? apiAdminUpdate : apiAdminCreate, payload);
        yield put(chapterAllApiResponseSuccess(ChapterActionTypes.ADMIN_UPSERT, response.data));
    } catch (error: any) {
        yield put(chapterApiResponseError(ChapterActionTypes.ADMIN_UPSERT, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(ChapterActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewAllShort() {
    yield takeEvery(ChapterActionTypes.ADMIN_VIEW_ALL_SHORT, adminViewAllShort);
}

export function* watchAdminViewDetails() {
    yield takeEvery(ChapterActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

export function* watchAdminUpsert() {
    yield takeEvery(ChapterActionTypes.ADMIN_UPSERT, adminUpsert);
}

function* ChapterSaga() {
    yield all([fork(watchAdminViewAll), fork(watchAdminViewAllShort), fork(watchAdminViewDetails), fork(watchAdminUpsert)]);
}

export default ChapterSaga;

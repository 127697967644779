export enum ChapterActionTypes {
    API_RESPONSE_SUCCESS = '@@chapter/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@chapter/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL = '@@chapter/ADMIN_VIEW_ALL',
    ADMIN_VIEW_ALL_SHORT = '@@chapter/ADMIN_VIEW_ALL_SHORT',
    ADMIN_VIEW_DETAILS = '@@chapter/ADMIN_VIEW_DETAILS',
    ADMIN_DELETE = '@@chapter/ADMIN_DELETE',
    ADMIN_UPSERT = '@@chapter/ADMIN_UPSERT',

    RESET = '@@chapter/RESET',
}

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiUserFilter, apiUserViewAll, apiUserViewDetails } from '../../../helpers/api/problem/solution';
import { PaginatedSolutionSummary, SolutionUserSummary } from '../../../types/problem/solution';
import { solutionApiResponseError, solutionApiResponseSuccess } from './actions';
import { SolutionActionTypes } from './constants';

type UserSolutionFilterPaginatedPayloadType = {
    payload: {
        id: string;
        page: number;
        size: number;
    };
    type: string;
};

type UserSolutionAllPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

type UserSolutionDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

function* userViewAll({ payload: { page, size } }: UserSolutionAllPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewAll, page, size);
        const responseItems: SolutionUserSummary[] = response.data.items.map((solution: SolutionUserSummary) => {
            return Object.assign(solution, {
                id_short: solution.id.substring(0, 8).toUpperCase(),
            });
        });
        const responseData: PaginatedSolutionSummary = {
            items: responseItems,
            total: response.data.total,
        };

        yield put(solutionApiResponseSuccess(SolutionActionTypes.USER_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(solutionApiResponseError(SolutionActionTypes.USER_VIEW_ALL, error));
    }
}

function* userFilter({ payload: { id, page, size } }: UserSolutionFilterPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserFilter, id, page, size);
        const responseItems: SolutionUserSummary[] = response.data.items.map((solution: SolutionUserSummary) => {
            return Object.assign(solution, {
                id_short: solution.id.substring(0, 8).toUpperCase(),
            });
        });
        const responseData: PaginatedSolutionSummary = {
            items: responseItems,
            total: response.data.total,
        };

        yield put(solutionApiResponseSuccess(SolutionActionTypes.USER_FILTER, responseData));
    } catch (error: any) {
        yield put(solutionApiResponseError(SolutionActionTypes.USER_FILTER, error));
    }
}

function* userViewDetails({ payload: { id } }: UserSolutionDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewDetails, id);
        yield put(solutionApiResponseSuccess(SolutionActionTypes.USER_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(solutionApiResponseError(SolutionActionTypes.USER_VIEW_DETAILS, error));
    }
}

export function* watchUserViewAll() {
    yield takeEvery(SolutionActionTypes.USER_VIEW_ALL, userViewAll);
}

export function* watchUserFilter() {
    yield takeEvery(SolutionActionTypes.USER_FILTER, userFilter);
}

export function* watchUserViewDetails() {
    yield takeEvery(SolutionActionTypes.USER_VIEW_DETAILS, userViewDetails);
}

function* ProblemSolutionSaga() {
    yield all([fork(watchUserViewAll), fork(watchUserFilter), fork(watchUserViewDetails)]);
}

export default ProblemSolutionSaga;

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { CommentFilter, CommentNew } from '../../../types/cms/comment';

const api = new APICore();

function apiAdminDelete(domain: string, commentId: string) {
    return api.delete(`${apiBaseUrl}/comments/admin/${domain}/${commentId}`);
}

function apiAdminViewAll(props: CommentFilter) {
    return api.create(`${apiBaseUrl}/comments/admin/filter`, props);
}

function apiAdminUpdate(commentData: CommentNew) {
    return api.update(`${apiBaseUrl}/comments/admin`, commentData);
}

function apiAdminViewDetails(domain: string, id: string) {
    return api.get(`${apiBaseUrl}/comments/admin/${domain}/${id}`, null);
}

function apiUserGet(domain: string, domainId: string) {
    return api.get(`${apiBaseUrl}/comments/${domain}/${domainId}`, null);
}

function apiUserSubmit(params: CommentNew) {
    return api.create(`${apiBaseUrl}/comments`, params);
}

export { apiUserSubmit, apiAdminViewAll, apiAdminViewDetails, apiUserGet, apiAdminDelete, apiAdminUpdate };

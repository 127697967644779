import { ShopInvoiceActionTypes } from './constants';
import { LoginLogDetails } from '../../../types/log/login';
import { InvoiceNew } from '../../../types/shop/invoice';

export type InvoiceActionType = {
    type:
        | ShopInvoiceActionTypes.API_RESPONSE_SUCCESS
        | ShopInvoiceActionTypes.API_RESPONSE_ERROR
        | ShopInvoiceActionTypes.ADMIN_VIEW_ALL
        | ShopInvoiceActionTypes.ADMIN_VIEW_DETAILS
        | ShopInvoiceActionTypes.USER_VIEW_DETAILS
        | ShopInvoiceActionTypes.USER_VIEW_ALL
        | ShopInvoiceActionTypes.USER_CHECK_STATUS
        | ShopInvoiceActionTypes.USER_SEND_INVOICE;
    payload: {} | string;
};

export const invoiceApiResponseSuccess = (actionType: string, data: LoginLogDetails | {}): InvoiceActionType => ({
    type: ShopInvoiceActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const invoiceApiResponseError = (actionType: string, error: string): InvoiceActionType => ({
    type: ShopInvoiceActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const invoiceAdminViewAll = (): InvoiceActionType => ({
    type: ShopInvoiceActionTypes.ADMIN_VIEW_ALL,
    payload: {},
});

export const invoiceAdminViewDetails = (id: string): InvoiceActionType => ({
    type: ShopInvoiceActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

export const invoiceUserCreate = (data: InvoiceNew): InvoiceActionType => ({
    type: ShopInvoiceActionTypes.USER_SEND_INVOICE,
    payload: data,
});

export const invoiceUserViewDetails = (slug: string): InvoiceActionType => ({
    type: ShopInvoiceActionTypes.USER_VIEW_DETAILS,
    payload: { slug },
});

export const invoiceUserCheckStatus = (uniqueId: string): InvoiceActionType => ({
    type: ShopInvoiceActionTypes.USER_CHECK_STATUS,
    payload: { uniqueId },
});

export const invoiceUserViewAll = (): InvoiceActionType => ({
    type: ShopInvoiceActionTypes.USER_VIEW_ALL,
    payload: {},
});

import { QuestionActionTypes } from './constants';
import { QuestionDetails, QuestionSelectableShort, QuestionSummary } from '../../../types/quiz/question';
import { AnswerDetails } from '../../../types/quiz/answer';

const INIT_STATE = {
    apiUpsertSuccess: false,
    adminAllQuestions: null,
    adminAllQuestionsShort: null,
    adminAllQuestionAnswers: null,
    adminQuestionDetails: null,
    adminQuestionsByLecture: null,
    loading: false,
};

type QuestionActionType = {
    type:
        | QuestionActionTypes.API_RESPONSE_SUCCESS
        | QuestionActionTypes.API_RESPONSE_ERROR
        | QuestionActionTypes.ADMIN_VIEW_ALL
        | QuestionActionTypes.ADMIN_VIEW_ALL_ANSWERS
        | QuestionActionTypes.ADMIN_VIEW_ALL_SHORT
        | QuestionActionTypes.ADMIN_VIEW_DETAILS
        | QuestionActionTypes.ADMIN_VIEW_BY_LECTURE
        | QuestionActionTypes.ADMIN_DELETE
        | QuestionActionTypes.ADMIN_UPSERT
        | QuestionActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: QuestionSummary | QuestionDetails | {};
        error?: string;
    };
};

type State = {
    apiUpsertSuccess: boolean;
    adminAllQuestions?: QuestionSummary[] | null;
    adminAllQuestionsShort?: QuestionSelectableShort[] | null;
    adminAllQuestionAnswers?: AnswerDetails[] | null;
    adminQuestionDetails?: QuestionDetails | null;
    loading?: boolean;
};

const Question = (state: State = INIT_STATE, action: QuestionActionType) => {
    switch (action.type) {
        case QuestionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case QuestionActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllQuestions: action.payload.data,
                        loading: false,
                    };
                }
                case QuestionActionTypes.ADMIN_VIEW_BY_LECTURE: {
                    return {
                        ...state,
                        adminQuestionsByLecture: action.payload.data,
                        loading: false,
                    };
                }
                case QuestionActionTypes.ADMIN_VIEW_ALL_SHORT: {
                    return {
                        ...state,
                        adminAllQuestionsShort: action.payload.data,
                    };
                }
                case QuestionActionTypes.ADMIN_VIEW_ALL_ANSWERS: {
                    return {
                        ...state,
                        adminAllQuestionAnswers: action.payload.data,
                        loading: false,
                    };
                }
                case QuestionActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminQuestionDetails: action.payload.data,
                    };
                }
                case QuestionActionTypes.ADMIN_UPSERT: {
                    return {
                        ...state,
                        apiUpsertSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }

        case QuestionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case QuestionActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminQuestionDetails: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case QuestionActionTypes.ADMIN_VIEW_DETAILS:
            return {
                ...state,
                error: false,
                adminQuestionDetails: null,
                apiUpsertSuccess: false,
            };

        case QuestionActionTypes.ADMIN_UPSERT:
            return {
                ...state,
                apiUpsertSuccess: false,
            };

        case QuestionActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                loading: true,
                error: false,
            };

        case QuestionActionTypes.ADMIN_VIEW_ALL_ANSWERS:
            return {
                ...state,
                loading: true,
                adminAllQuestionAnswers: null,
            };

        case QuestionActionTypes.ADMIN_VIEW_BY_LECTURE:
            return {
                ...state,
                adminQuestionsByLecture: null,
            };

        case QuestionActionTypes.RESET:
            return {
                ...state,
                loading: false,
                apiUpsertSuccess: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default Question;

import { QuestionActionTypes } from './constants';
import { QuestionDetails, QuestionNew, QuestionSummary } from '../../../types/quiz/question';

export type QuestionActionType = {
    type:
        | QuestionActionTypes.API_RESPONSE_SUCCESS
        | QuestionActionTypes.API_RESPONSE_ERROR
        | QuestionActionTypes.ADMIN_VIEW_ALL
        | QuestionActionTypes.ADMIN_VIEW_ALL_SHORT
        | QuestionActionTypes.ADMIN_VIEW_ALL_ANSWERS
        | QuestionActionTypes.ADMIN_VIEW_DETAILS
        | QuestionActionTypes.ADMIN_VIEW_BY_LECTURE
        | QuestionActionTypes.ADMIN_DELETE
        | QuestionActionTypes.ADMIN_UPSERT
        | QuestionActionTypes.RESET;
    payload: {} | string;
};

export const questionApiResponseSuccess = (actionType: string, data: QuestionSummary | QuestionDetails | {}): QuestionActionType => ({
    type: QuestionActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const questionApiResponseError = (actionType: string, error: string): QuestionActionType => ({
    type: QuestionActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const questionAdminViewAll = (page: number, size: number): QuestionActionType => ({
    type: QuestionActionTypes.ADMIN_VIEW_ALL,
    payload: { page, size },
});

export const questionAdminViewAllShort = (): QuestionActionType => ({
    type: QuestionActionTypes.ADMIN_VIEW_ALL_SHORT,
    payload: {},
});

export const questionAdminViewAnswers = (id: string): QuestionActionType => ({
    type: QuestionActionTypes.ADMIN_VIEW_ALL_ANSWERS,
    payload: { id },
});

export const questionAdminViewDetails = (id: string): QuestionActionType => ({
    type: QuestionActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

export const questionAdminViewByLecture = (lectureId: string): QuestionActionType => ({
    type: QuestionActionTypes.ADMIN_VIEW_BY_LECTURE,
    payload: { lectureId },
});

export const questionAdminUpsert = (data: QuestionNew): QuestionActionType => ({
    type: QuestionActionTypes.ADMIN_UPSERT,
    payload: data,
});

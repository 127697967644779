import { AiConfigActionTypes } from './constants';
import { AiConfigDetails, AiConfigNew } from '../../../types/ai/aiConfig';

const INIT_STATE = {
    apiConfigSuccess: false,
    adminAllConfigs: null,
    loading: false,
};

type AiConfigActionType = {
    type:
        | AiConfigActionTypes.API_RESPONSE_SUCCESS
        | AiConfigActionTypes.API_RESPONSE_ERROR
        | AiConfigActionTypes.ADMIN_VIEW_ALL
        | AiConfigActionTypes.ADMIN_UPDATE
        | AiConfigActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: AiConfigNew | AiConfigDetails[] | {};
        error?: string;
    };
};

type State = {
    apiConfigSuccess: boolean;
    adminAllConfigs?: AiConfigDetails[] | null;
    loading?: boolean;
};

const AiConfig = (state: State = INIT_STATE, action: AiConfigActionType) => {
    switch (action.type) {
        case AiConfigActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AiConfigActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllConfigs: action.payload.data,
                        loading: false,
                    };
                }
                case AiConfigActionTypes.ADMIN_UPDATE: {
                    return {
                        ...state,
                        apiConfigSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }

        case AiConfigActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return { ...state };
            }

        case AiConfigActionTypes.ADMIN_UPDATE:
            return {
                ...state,
                apiConfigSuccess: false,
            };

        case AiConfigActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                loading: true,
                error: false,
            };

        case AiConfigActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default AiConfig;

import { ProblemHintActionTypes } from './constants';
import { ProblemHintDetails, ProblemHintNew } from '../../../types/problem/problemHint';

const INIT_STATE = {
    adminAllHintsForProblem: null,
    adminHintDetails: null,
    apiSuccess: null,
    error: false,
    loading: false,
    userAllHintsForProblem: null,
    userHintDetails: null,
};

type ProblemHintActionType = {
    type:
        | ProblemHintActionTypes.API_RESPONSE_SUCCESS
        | ProblemHintActionTypes.API_RESPONSE_ERROR
        | ProblemHintActionTypes.ADMIN_VIEW_ALL_PROBLEM
        | ProblemHintActionTypes.ADMIN_VIEW
        | ProblemHintActionTypes.ADMIN_UPSERT
        | ProblemHintActionTypes.ADMIN_DELETE
        | ProblemHintActionTypes.USER_VIEW_ALL_PROBLEM
        | ProblemHintActionTypes.USER_VIEW;
    payload: {
        actionType?: string;
        data?: ProblemHintNew | ProblemHintDetails[] | ProblemHintDetails;
        error?: string;
    };
};

type State = {
    adminAllHintsForProblem?: ProblemHintDetails[] | null;
    adminHintDetails?: ProblemHintDetails | null;
    apiSuccess?: boolean | null;
    error?: boolean;
    loading?: boolean;
    userAllHintsForProblem?: ProblemHintDetails[] | null;
    userHintDetails?: ProblemHintDetails | null;
};

const ProblemHint = (state: State = INIT_STATE, action: ProblemHintActionType) => {
    switch (action.type) {
        case ProblemHintActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProblemHintActionTypes.ADMIN_VIEW_ALL_PROBLEM: {
                    return {
                        ...state,
                        adminAllHintsForProblem: action.payload.data,
                        loading: false,
                    };
                }
                case ProblemHintActionTypes.ADMIN_VIEW: {
                    return {
                        ...state,
                        adminHintDetails: action.payload.data,
                        loading: false,
                    };
                }
                case ProblemHintActionTypes.ADMIN_UPSERT: {
                    return {
                        ...state,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                case ProblemHintActionTypes.ADMIN_DELETE: {
                    return {
                        ...state,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                case ProblemHintActionTypes.USER_VIEW_ALL_PROBLEM: {
                    return {
                        ...state,
                        userAllHintsForProblem: action.payload.data,
                        loading: false,
                    };
                }
                case ProblemHintActionTypes.USER_VIEW: {
                    return {
                        ...state,
                        userHintDetails: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ProblemHintActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ProblemHintActionTypes.ADMIN_VIEW_ALL_PROBLEM: {
                    return {
                        ...state,
                        adminAllHintsForProblem: null,
                        apiSuccess: false,
                        error: action.payload.error,
                    };
                }
                case ProblemHintActionTypes.ADMIN_VIEW: {
                    return {
                        ...state,
                        adminHintDetails: null,
                        apiSuccess: false,
                        error: action.payload.error,
                    };
                }
                case ProblemHintActionTypes.ADMIN_UPSERT: {
                    return {
                        ...state,
                        apiSuccess: false,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case ProblemHintActionTypes.ADMIN_VIEW_ALL_PROBLEM:
            return {
                ...state,
                adminAllHintsForProblem: null,
                error: false,
                loading: true,
            };

        case ProblemHintActionTypes.ADMIN_VIEW:
            return {
                ...state,
                adminHintDetails: null,
                error: false,
                loading: true,
            };

        case ProblemHintActionTypes.ADMIN_UPSERT:
            return {
                ...state,
                apiSuccess: false,
                loading: true,
                error: false,
            };

        case ProblemHintActionTypes.ADMIN_DELETE:
            return {
                ...state,
                apiSuccess: false,
            };

        case ProblemHintActionTypes.USER_VIEW_ALL_PROBLEM:
            return {
                ...state,
                userAllHintsForProblem: null,
                error: false,
                loading: true,
            };

        case ProblemHintActionTypes.USER_VIEW:
            return {
                ...state,
                userHintDetails: null,
                error: false,
                loading: true,
            };

        default:
            return { ...state };
    }
};

export default ProblemHint;

import { ChatActionTypes } from './constants';
import { MessageDetails, MessageNew } from '../../../types/user/chat';

export type ChatActionType = {
    type:
        | ChatActionTypes.API_RESPONSE_ERROR
        | ChatActionTypes.API_RESPONSE_SUCCESS
        | ChatActionTypes.USER_VIEW_ALL_CONTACTS
        | ChatActionTypes.USER_LOAD_ALL_FRIENDS
        | ChatActionTypes.USER_VIEW_CONVERSATION
        | ChatActionTypes.SOCKET_UPDATE_CONVERSATION_LAST_MESSAGE
        | ChatActionTypes.RESET
        | ChatActionTypes.USER_SEND_MESSAGE
        | ChatActionTypes.USER_CHANGE_CONVERSATION_STATUS
        | ChatActionTypes.USER_AUTO_READ_CONVERSATION;
    payload: {} | string;
};

export const chatLoadAllUserContacts = (): ChatActionType => ({
    type: ChatActionTypes.USER_VIEW_ALL_CONTACTS,
    payload: {},
});

export const chatUserLoadAllFriends = (): ChatActionType => ({
    type: ChatActionTypes.USER_LOAD_ALL_FRIENDS,
    payload: {},
});

export const autoReadConversation = (conversationId: string): ChatActionType => ({
    type: ChatActionTypes.USER_AUTO_READ_CONVERSATION,
    payload: { conversationId },
});

export const changeConversationStatus = (conversationId: string, status: number): ChatActionType => ({
    type: ChatActionTypes.USER_CHANGE_CONVERSATION_STATUS,
    payload: { conversationId, status },
});

export const chatUserSendMessage = (data: MessageNew): ChatActionType => ({
    type: ChatActionTypes.USER_SEND_MESSAGE,
    payload: data,
});

export const chatLoadConversationMessages = (conversationId: string): ChatActionType => ({
    type: ChatActionTypes.USER_VIEW_CONVERSATION,
    payload: { conversationId },
});

export const chatUpdateConversationLastMessage = (message: MessageDetails): ChatActionType => ({
    type: ChatActionTypes.SOCKET_UPDATE_CONVERSATION_LAST_MESSAGE,
    payload: { data: message },
});

export const chatApiResponseSuccess = (actionType: string, data: {}): ChatActionType => ({
    type: ChatActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const chatApiResponseError = (actionType: string, error: string): ChatActionType => ({
    type: ChatActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export enum SolutionActionTypes {
    API_RESPONSE_SUCCESS = '@@solution/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@solution/API_RESPONSE_ERROR',

    USER_VIEW_ALL = '@@solution/USER_VIEW_ALL',
    USER_VIEW_DETAILS = '@@solution/USER_VIEW_DETAILS',
    USER_FILTER = '@@solution/USER_FILTER',

    RESET = '@@solution/RESET',
}

import { DependencyActionTypes } from './constants';
import { DependencyDetails, DependencyNew } from '../../../types/cms/dependency';

export type DependencyActionType = {
    type:
        | DependencyActionTypes.ADMIN_VIEW_ALL
        | DependencyActionTypes.ADMIN_VIEW
        | DependencyActionTypes.API_RESPONSE_SUCCESS
        | DependencyActionTypes.API_RESPONSE_ERROR
        | DependencyActionTypes.ADMIN_MAP_ALL_MODULES
        | DependencyActionTypes.ADMIN_MAP_ALL_CHAPTERS
        | DependencyActionTypes.ADMIN_MAP_ALL_LECTURES
        | DependencyActionTypes.ADMIN_UPDATE
        | DependencyActionTypes.ADMIN_DELETE
        | DependencyActionTypes.RESET;
    payload: {} | string;
};

export const dependencyAdminViewAll = (): DependencyActionType => ({
    type: DependencyActionTypes.ADMIN_VIEW_ALL,
    payload: {},
});

export const dependencyLoadMappedModules = (): DependencyActionType => ({
    type: DependencyActionTypes.ADMIN_MAP_ALL_MODULES,
    payload: {},
});

export const dependencyLoadMappedChapters = (parentId: string): DependencyActionType => ({
    type: DependencyActionTypes.ADMIN_MAP_ALL_CHAPTERS,
    payload: { parentId },
});

export const dependencyLoadMappedLectures = (parentId: string): DependencyActionType => ({
    type: DependencyActionTypes.ADMIN_MAP_ALL_LECTURES,
    payload: { parentId },
});

export const dependencyAdminView = (id: string, type: string): DependencyActionType => ({
    type: DependencyActionTypes.ADMIN_VIEW,
    payload: { id, type },
});

export const dependencyAdminUpdate = (data: DependencyNew) => ({
    type: DependencyActionTypes.ADMIN_UPDATE,
    payload: data,
});

export const dependencyAdminDelete = (id: string, type: string) => ({
    type: DependencyActionTypes.ADMIN_DELETE,
    payload: { id, type },
});

export const dependencyApiResponseSuccess = (actionType: string, data: DependencyDetails | {}): DependencyActionType => ({
    type: DependencyActionTypes.API_RESPONSE_SUCCESS,
    payload: {
        actionType,
        data,
    },
});

export const dependencyApiResponseError = (actionType: string, error: string): DependencyActionType => ({
    type: DependencyActionTypes.API_RESPONSE_ERROR,
    payload: {
        actionType,
        error,
    },
});

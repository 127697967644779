export enum ReportActionTypes {
    USER_VIEW_ALL = '@@report/USER_VIEW_ALL',
    USER_CREATE = '@@report/USER_CREATE',

    ADMIN_VIEW_ALL = '@@report/ADMIN_VIEW_ALL',
    ADMIN_VIEW_DETAILS = '@@report/ADMIN_VIEW_DETAILS',
    ADMIN_CLOSE = '@@report/ADMIN_CLOSE',

    API_RESPONSE_ERROR = '@@report/API_RESPONSE_ERROR',
    API_RESPONSE_SUCCESS = '@@report/API_RESPONSE_SUCCESS',

    RESET = '@@report/RESET',
}

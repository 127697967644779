import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
    apiAdminViewAll,
    apiAdminViewDetails,
    apiUserViewAll,
    apiAdminReply,
    apiAdminClose,
    apiAdminArchive,
    apiAdminViewArchived,
    apiUserReply,
    apiUserCreate,
    apiUserViewDetails,
} from '../../../helpers/api/cms/ticket';
import { ticketApiResponseError, ticketApiResponseSuccess } from './actions';
import { TicketActionTypes } from './constants';
import { TicketNew, TicketReplyNew, TicketSummary } from '../../../types/support/supportTicket';

type TicketPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

type TicketReplyNewPayloadType = {
    payload: TicketReplyNew;
    type: string;
};

type TicketNewPayloadType = {
    payload: TicketNew;
    type: string;
};

type TicketSummaryResponse = {
    id: string;
    subject: string;
    status: number;
};

function* userViewAll(): SagaIterator {
    try {
        const response = yield call(apiUserViewAll);
        const responseData: TicketSummary[] = response.data.map((ticket: TicketSummaryResponse) => {
            return Object.assign(ticket, {
                id_short: ticket.id.substring(0, 8),
                status: ticket.status,
            });
        });
        yield put(ticketApiResponseSuccess(TicketActionTypes.USER_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(ticketApiResponseError(TicketActionTypes.USER_VIEW_ALL, error));
    }
}

function* adminViewAll(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll);
        const responseData: TicketSummary[] = response.data.map((ticket: TicketSummary) => {
            return Object.assign(ticket, {
                id_short: ticket.id.substring(0, 8),
            });
        });
        yield put(ticketApiResponseSuccess(TicketActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(ticketApiResponseError(TicketActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewArchived(): SagaIterator {
    try {
        const response = yield call(apiAdminViewArchived);
        const responseData: TicketSummary[] = response.data.map((ticket: TicketSummary) => {
            return Object.assign(ticket, {
                id_short: ticket.id.substring(0, 8),
            });
        });
        yield put(ticketApiResponseSuccess(TicketActionTypes.ADMIN_VIEW_ARCHIVED, responseData));
    } catch (error: any) {
        yield put(ticketApiResponseError(TicketActionTypes.ADMIN_VIEW_ARCHIVED, error));
    }
}

function* adminClose({ payload: { id } }: TicketPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminClose, id);
        yield put(ticketApiResponseSuccess(TicketActionTypes.ADMIN_CLOSE, response.data));
    } catch (error: any) {
        yield put(ticketApiResponseError(TicketActionTypes.ADMIN_CLOSE, error));
    }
}

function* adminArchive({ payload: { id } }: TicketPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminArchive, id);
        yield put(ticketApiResponseSuccess(TicketActionTypes.ADMIN_ARCHIVE, response.data));
    } catch (error: any) {
        yield put(ticketApiResponseError(TicketActionTypes.ADMIN_ARCHIVE, error));
    }
}

function* adminReply({ payload }: TicketReplyNewPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminReply, payload);
        yield put(ticketApiResponseSuccess(TicketActionTypes.USER_REPLY, response.data));
    } catch (error: any) {
        yield put(ticketApiResponseError(TicketActionTypes.USER_REPLY, error));
    }
}

function* userReply({ payload }: TicketReplyNewPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserReply, payload);
        yield put(ticketApiResponseSuccess(TicketActionTypes.ADMIN_REPLY, response.data));
    } catch (error: any) {
        yield put(ticketApiResponseError(TicketActionTypes.ADMIN_REPLY, error));
    }
}

function* adminViewDetails({ payload: { id } }: TicketPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(ticketApiResponseSuccess(TicketActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(ticketApiResponseError(TicketActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

function* userCreate({ payload }: TicketNewPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserCreate, payload);
        yield put(ticketApiResponseSuccess(TicketActionTypes.USER_CREATE, response.data));
    } catch (error: any) {
        yield put(ticketApiResponseError(TicketActionTypes.USER_CREATE, error));
    }
}

function* userViewDetails({ payload: { id } }: TicketPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewDetails, id);
        yield put(ticketApiResponseSuccess(TicketActionTypes.USER_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(ticketApiResponseError(TicketActionTypes.USER_VIEW_DETAILS, error));
    }
}

export function* watchAdminViewDetails() {
    yield takeEvery(TicketActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

export function* watchAdminViewAll() {
    yield takeEvery(TicketActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchUserViewAll() {
    yield takeEvery(TicketActionTypes.USER_VIEW_ALL, userViewAll);
}

export function* watchAdminReply() {
    yield takeEvery(TicketActionTypes.ADMIN_REPLY, adminReply);
}

export function* watchAdminClose() {
    yield takeEvery(TicketActionTypes.ADMIN_CLOSE, adminClose);
}

export function* watchAdminArchive() {
    yield takeEvery(TicketActionTypes.ADMIN_ARCHIVE, adminArchive);
}

export function* watchAdminViewArchived() {
    yield takeEvery(TicketActionTypes.ADMIN_VIEW_ARCHIVED, adminViewArchived);
}

export function* watchUserCreate() {
    yield takeEvery(TicketActionTypes.USER_CREATE, userCreate);
}

export function* watchUserViewDetails() {
    yield takeEvery(TicketActionTypes.USER_VIEW_DETAILS, userViewDetails);
}

export function* watchUserReply() {
    yield takeEvery(TicketActionTypes.USER_REPLY, userReply);
}

function* ticketSaga() {
    yield all([
        fork(watchAdminArchive),
        fork(watchAdminClose),
        fork(watchAdminViewAll),
        fork(watchAdminViewArchived),
        fork(watchAdminViewDetails),
        fork(watchAdminReply),
        fork(watchUserCreate),
        fork(watchUserReply),
        fork(watchUserViewDetails),
        fork(watchUserViewAll),
    ]);
}

export default ticketSaga;

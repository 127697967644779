import { CommentActionTypes } from './constants';
import { CommentFilter, CommentNew, CommentResponse } from '../../../types/cms/comment';

export type CommentActionType = {
    type:
        | CommentActionTypes.API_RESPONSE_SUCCESS
        | CommentActionTypes.API_RESPONSE_ERROR
        | CommentActionTypes.ADMIN_VIEW_ALL
        | CommentActionTypes.ADMIN_VIEW_DETAILS
        | CommentActionTypes.ADMIN_UPDATE
        | CommentActionTypes.ADMIN_DELETE
        | CommentActionTypes.USER_GET
        | CommentActionTypes.USER_SEND
        | CommentActionTypes.RESET;
    payload: {} | string;
};

export const commentUserSend = (data: CommentNew): CommentActionType => ({
    type: CommentActionTypes.USER_SEND,
    payload: data,
});

export const commentUserGet = (domain: string, domainId: string): CommentActionType => ({
    type: CommentActionTypes.USER_GET,
    payload: { domain, domainId },
});

export const commentAdminViewAll = (props: CommentFilter): CommentActionType => ({
    type: CommentActionTypes.ADMIN_VIEW_ALL,
    payload: props,
});

export const commentAdminViewDetails = (id: string, domain: string): CommentActionType => ({
    type: CommentActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id, domain },
});

export const commentAdminUpdate = (commentData: CommentNew): CommentActionType => ({
    type: CommentActionTypes.ADMIN_UPDATE,
    payload: commentData,
});

export const commentAdminDelete = (domain: string, commentId: string): CommentActionType => ({
    type: CommentActionTypes.ADMIN_DELETE,
    payload: { domain, commentId },
});

export const commentApiResponseSuccess = (actionType: string, data: CommentResponse | {}): CommentActionType => ({
    type: CommentActionTypes.API_RESPONSE_SUCCESS,
    payload: {
        actionType,
        data,
    },
});

export const commentApiResponseError = (actionType: string, error: string): CommentActionType => ({
    type: CommentActionTypes.API_RESPONSE_ERROR,
    payload: {
        actionType,
        error,
    },
});

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { ReportNew } from '../../../types/support/supportReport';

const api = new APICore();

function apiUserViewAll() {
    return api.get(`${apiBaseUrl}/reports/`, null);
}

function apiAdminViewAll() {
    return api.get(`${apiBaseUrl}/reports/admin`, null);
}

function apiAdminClose(params: { id: string; type: string; status: number }) {
    return api.create(`${apiBaseUrl}/reports/admin/close`, params);
}

function apiAdminViewDetails(id: string, type: string) {
    return api.get(`${apiBaseUrl}/reports/admin/view/${id}/${type}`, null);
}

function apiUserCreate(type: string, data: ReportNew) {
    return api.create(`${apiBaseUrl}/reports/create/${type}`, data);
}

export { apiUserViewAll, apiAdminViewAll, apiAdminViewDetails, apiAdminClose, apiUserCreate };

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { AdminPanelActionTypes } from './constants';
import { adminPanelApiResponseError, adminPanelApiResponseSuccess } from './actions';
import { apiAdminClearCache } from '../../helpers/api/admin/panel';

function* adminClearCache(): SagaIterator {
    try {
        const response = yield call(apiAdminClearCache);
        yield put(adminPanelApiResponseSuccess(AdminPanelActionTypes.ADMIN_CLEAR_CACHE, response.data));
    } catch (error: any) {
        yield put(adminPanelApiResponseError(AdminPanelActionTypes.ADMIN_CLEAR_CACHE, error));
    }
}

export function* watchAdminClearCache(): SagaIterator {
    yield takeEvery(AdminPanelActionTypes.ADMIN_CLEAR_CACHE, adminClearCache);
}

function* AdminPanelSaga() {
    yield all([fork(watchAdminClearCache)]);
}

export default AdminPanelSaga;

export enum EvaluatorActionTypes {
    USER_ADD_TEST_CASE = '@@evaluator/USER_ADD_TEST_CASE',
    USER_CHANGE_OUT_TAB = '@@evaluator/USER_CHANGE_OUT_TAB',
    USER_CHANGE_TEST_CASE = '@@evaluator/USER_CHANGE_TEST_CASE',
    USER_REMOVE_TEST_CASE = '@@evaluator/USER_REMOVE_TEST_CASE',
    USER_UPDATE_LANGUAGE = '@@evaluator/USER_UPDATE_LANGUAGE',
    USER_UPDATE_SOURCE_CODE = '@@evaluator/USER_UPDATE_SOURCE_CODE',
    USER_UPDATE_TEST_CASE = '@@evaluator/USER_UPDATE_TEST_CASE',
    USER_SET_FIRST_TEST_CASE = '@@evaluator/USER_SET_FIRST_TEST_CASE',
}

import { SolutionActionTypes } from './constants';
import { SolutionUserDetails, SolutionUserSummary } from '../../../types/problem/solution';

export type SolutionActionType = {
    type:
        | SolutionActionTypes.API_RESPONSE_SUCCESS
        | SolutionActionTypes.API_RESPONSE_ERROR
        | SolutionActionTypes.USER_FILTER
        | SolutionActionTypes.USER_VIEW_ALL
        | SolutionActionTypes.USER_VIEW_DETAILS
        | SolutionActionTypes.RESET;
    payload: {} | string;
};

export const solutionApiResponseSuccess = (actionType: string, data: SolutionUserSummary | SolutionUserDetails | {}): SolutionActionType => ({
    type: SolutionActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const solutionApiResponseError = (actionType: string, error: string): SolutionActionType => ({
    type: SolutionActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const solutionUserViewAll = (page: number, size: number): SolutionActionType => ({
    type: SolutionActionTypes.USER_VIEW_ALL,
    payload: { page, size },
});

export const solutionUserViewDetails = (id: string): SolutionActionType => ({
    type: SolutionActionTypes.USER_VIEW_DETAILS,
    payload: { id },
});

export const solutionUserFilter = (id: string, page: number, size: number): SolutionActionType => ({
    type: SolutionActionTypes.USER_FILTER,
    payload: { id, page, size },
});

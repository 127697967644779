export enum AdminGalleryActionTypes {
    API_RESPONSE_SUCCESS = '@@adminGallery/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@adminGallery/API_RESPONSE_ERROR',

    ADMIN_IMAGES_ALL = '@@adminGallery/ADMIN_IMAGES_ALL',
    ADMIN_IMAGES_ALL_SHORT = '@@adminGallery/ADMIN_IMAGES_ALL_SHORT',
    ADMIN_IMAGES_DELETE = '@@adminGallery/ADMIN_IMAGES_DELETE',
    ADMIN_IMAGES_UPLOAD = '@@adminGallery/ADMIN_IMAGES_UPLOAD',

    ADMIN_AVATARS_ALL = '@@adminGallery/ADMIN_AVATARS_ALL',
}

import { AiMessageActionTypes } from './constants';
import { AiMessageDetails, AiMessageNew, AiMessageSummary } from '../../../types/ai/aiMessage';

export type AiMessageActionType = {
    type:
        | AiMessageActionTypes.API_RESPONSE_SUCCESS
        | AiMessageActionTypes.API_RESPONSE_ERROR
        | AiMessageActionTypes.ADMIN_VIEW_ALL
        | AiMessageActionTypes.ADMIN_VIEW_DETAILS
        | AiMessageActionTypes.USER_CREATE
        | AiMessageActionTypes.RESET;
    payload: {} | string;
};

export const aiMessageAllApiResponseSuccess = (actionType: string, data: AiMessageDetails | AiMessageSummary[] | {}): AiMessageActionType => ({
    type: AiMessageActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const aiMessageApiResponseError = (actionType: string, error: string): AiMessageActionType => ({
    type: AiMessageActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const aiMessageAdminViewAll = (): AiMessageActionType => ({
    type: AiMessageActionTypes.ADMIN_VIEW_ALL,
    payload: {},
});

export const aiMessageAdminViewDetails = (id: string): AiMessageActionType => ({
    type: AiMessageActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

export const aiMessageUserCreate = (data: AiMessageNew): AiMessageActionType => ({
    type: AiMessageActionTypes.USER_CREATE,
    payload: data,
});

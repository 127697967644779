import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { aiMessageAllApiResponseSuccess, aiMessageApiResponseError } from './actions';
import { AiMessageActionTypes } from './constants';
import { apiAdminViewAll, apiAdminViewDetails, apiUserCreate } from '../../../helpers/api/ai/message';
import { AiMessageNew } from '../../../types/ai/aiMessage';

type AdminAiMessageDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

type UserCreatePayloadType = {
    payload: AiMessageNew;
    type: string;
};

function* adminViewAll(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll);
        yield put(aiMessageAllApiResponseSuccess(AiMessageActionTypes.ADMIN_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(aiMessageApiResponseError(AiMessageActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewDetails({ payload: { id } }: AdminAiMessageDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(aiMessageAllApiResponseSuccess(AiMessageActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(aiMessageApiResponseError(AiMessageActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

function* userCreate({ payload }: UserCreatePayloadType): SagaIterator {
    try {
        const response = yield call(apiUserCreate, payload);
        yield put(aiMessageAllApiResponseSuccess(AiMessageActionTypes.USER_CREATE, response.data));
    } catch (error: any) {
        yield put(aiMessageApiResponseError(AiMessageActionTypes.USER_CREATE, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(AiMessageActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewDetails() {
    yield takeEvery(AiMessageActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

export function* watchUserCreate() {
    yield takeEvery(AiMessageActionTypes.USER_CREATE, userCreate);
}

function* AiMessageSaga() {
    yield all([fork(watchAdminViewAll), fork(watchAdminViewDetails), fork(watchUserCreate)]);
}

export default AiMessageSaga;

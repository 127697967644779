export enum AnswerActionTypes {
    API_RESPONSE_SUCCESS = '@@answer/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@answer/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL = '@@answer/ADMIN_VIEW_ALL',
    ADMIN_VIEW_ALL_SHORT = '@@answer/ADMIN_VIEW_ALL_SHORT',
    ADMIN_VIEW_DETAILS = '@@answer/ADMIN_VIEW_DETAILS',
    ADMIN_DELETE = '@@answer/ADMIN_DELETE',
    ADMIN_UPSERT = '@@answer/ADMIN_UPSERT',

    RESET = '@@answer/RESET',
}

import { TimespentActionTypes } from './constants';
import { TimespentNew } from '../../../../types/user/logs/timespent';

export type TimespentActionType = {
    type: TimespentActionTypes.ADMIN_VIEW_ALL | TimespentActionTypes.USER_CREATE | TimespentActionTypes.API_RESPONSE_ERROR | TimespentActionTypes.API_RESPONSE_SUCCESS;
    payload: {} | string;
};

export const timespentAdminViewAll = (page: number, size: number): TimespentActionType => ({
    type: TimespentActionTypes.ADMIN_VIEW_ALL,
    payload: { page, size },
});

export const timespentUserCreate = (data: TimespentNew): TimespentActionType => ({
    type: TimespentActionTypes.USER_CREATE,
    payload: data,
});

export const timespentApiResponseSuccess = (actionType: string, data: {}): TimespentActionType => ({
    type: TimespentActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const timespentApiResponseError = (actionType: string, error: string): TimespentActionType => ({
    type: TimespentActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

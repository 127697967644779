import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { purchaseMessageAllApiResponseSuccess, purchaseMessageApiResponseError } from './actions';
import { UserPurchaseNew } from '../../../types/user/purchase';
import { UserPurchaseActionTypes } from './constants';
import { apiUserCreate } from '../../../helpers/api/user/purchase';

type UserCreatePayloadType = {
    payload: UserPurchaseNew;
    type: string;
};

function* userCreate({ payload }: UserCreatePayloadType): SagaIterator {
    try {
        const response = yield call(apiUserCreate, payload.model, payload.modelId);
        yield put(purchaseMessageAllApiResponseSuccess(UserPurchaseActionTypes.USER_CREATE, response.data));
    } catch (error: any) {
        yield put(purchaseMessageApiResponseError(UserPurchaseActionTypes.USER_CREATE, error));
    }
}

export function* watchUserCreate() {
    yield takeEvery(UserPurchaseActionTypes.USER_CREATE, userCreate);
}

function* PurchaseSaga() {
    yield all([fork(watchUserCreate)]);
}

export default PurchaseSaga;

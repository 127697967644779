import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { InvoiceNew } from '../../../types/shop/invoice';

const api = new APICore();

function apiAdminViewAll() {
    return api.get(`${apiBaseUrl}/invoices/admin`, null);
}

function apiAdminViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/invoices/admin/${id}`, null);
}

function apiUserViewAll() {
    return api.get(`${apiBaseUrl}/invoices`, null);
}

function apiUserViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/invoices/${id}`, null);
}

function apiUserCheckStatus(uniqueId: string) {
    return api.get(`${apiBaseUrl}/invoices/check_payment/${uniqueId}`, null);
}

function apiUserInvoiceCreate(data: InvoiceNew) {
    return api.create(`${apiBaseUrl}/invoices/create`, data);
}

export { apiAdminViewAll, apiUserInvoiceCreate, apiAdminViewDetails, apiUserViewDetails, apiUserViewAll, apiUserCheckStatus };

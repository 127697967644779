import { ShopBundleActionTypes } from './constants';
import { LoginLogDetails } from '../../../types/log/login';

export type BundleActionType = {
    type:
        | ShopBundleActionTypes.API_RESPONSE_SUCCESS
        | ShopBundleActionTypes.API_RESPONSE_ERROR
        | ShopBundleActionTypes.ADMIN_VIEW_ALL
        | ShopBundleActionTypes.ADMIN_VIEW_ALL_SHORT
        | ShopBundleActionTypes.USER_VIEW_DETAILS
        | ShopBundleActionTypes.USER_VIEW_ALL;
    payload: {} | string;
};

export const bundleApiResponseSuccess = (actionType: string, data: LoginLogDetails | {}): BundleActionType => ({
    type: ShopBundleActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const bundleApiResponseError = (actionType: string, error: string): BundleActionType => ({
    type: ShopBundleActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const bundleAdminViewAll = (): BundleActionType => ({
    type: ShopBundleActionTypes.ADMIN_VIEW_ALL,
    payload: {},
});

export const bundleAdminViewAllShort = (): BundleActionType => ({
    type: ShopBundleActionTypes.ADMIN_VIEW_ALL_SHORT,
    payload: {},
});

export const bundleUserViewDetails = (slug: string): BundleActionType => ({
    type: ShopBundleActionTypes.USER_VIEW_DETAILS,
    payload: { slug },
});

export const bundleUserViewAll = (): BundleActionType => ({
    type: ShopBundleActionTypes.USER_VIEW_ALL,
    payload: {},
});

import { QuizActionTypes } from './constants';
import { QuestionUserDetails, UserAnswerDetails } from '../../../types/theory/quiz';

const INIT_STATE = {
    apiUpsertSuccess: false,
    userQuestionDetails: null,
    userAnswerDetails: null,
    loading: false,
};

type QuizActionType = {
    type: QuizActionTypes.API_RESPONSE_SUCCESS | QuizActionTypes.API_RESPONSE_ERROR | QuizActionTypes.USER_VIEW_LECTURE_QUESTION | QuizActionTypes.USER_ANSWER_QUESTION | QuizActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: QuestionUserDetails | {};
        error?: string;
    };
};

type State = {
    apiUpsertSuccess: boolean;
    userQuestionDetails?: QuestionUserDetails | null;
    userAnswerDetails?: UserAnswerDetails | null;
    loading?: boolean;
};

const Quiz = (state: State = INIT_STATE, action: QuizActionType) => {
    switch (action.type) {
        case QuizActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case QuizActionTypes.USER_VIEW_LECTURE_QUESTION: {
                    return {
                        ...state,
                        userQuestionDetails: action.payload.data,
                    };
                }
                case QuizActionTypes.USER_ANSWER_QUESTION: {
                    return {
                        ...state,
                        userAnswerDetails: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case QuizActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case QuizActionTypes.USER_VIEW_LECTURE_QUESTION: {
                    return {
                        ...state,
                        userQuestionDetails: null,
                        error: action.payload.error,
                    };
                }
                case QuizActionTypes.USER_ANSWER_QUESTION: {
                    return {
                        ...state,
                        userAnswerDetails: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case QuizActionTypes.USER_VIEW_LECTURE_QUESTION:
            return {
                ...state,
                error: false,
                userQuestionDetails: null,
            };

        case QuizActionTypes.USER_ANSWER_QUESTION:
            return {
                ...state,
                error: false,
                userAnswerDetails: null,
            };

        case QuizActionTypes.RESET:
            return {
                ...state,
                userQuestionDetails: null,
                userAnswerDetails: null,
                loading: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default Quiz;

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';

const api = new APICore();

function apiUserViewLast() {
    return api.get(`${apiBaseUrl}/notifications/last`, null);
}

function apiUserViewAll() {
    return api.get(`${apiBaseUrl}/notifications/`, null);
}

function apiUserReadAll() {
    return api.get(`${apiBaseUrl}/notifications/read-all`, null);
}

function apiUserReadOne(id: String) {
    return api.create(`${apiBaseUrl}/notifications/read`, { id });
}

export { apiUserViewLast, apiUserViewAll, apiUserReadAll, apiUserReadOne };

import { APICore } from '../../apiCore';
import { apiBaseUrl } from '../../../../constants';

const api = new APICore();

function apiAdminViewAll() {
    return api.get(`${apiBaseUrl}/purchases/admin`, null);
}

function apiUserViewAll(page: number, size: number) {
    return api.get(`${apiBaseUrl}/settings/energy_logs/page/${page}/${size}`, null);
}

export { apiAdminViewAll, apiUserViewAll };

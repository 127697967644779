import { TicketDetails, TicketNew, TicketReplyNew, TicketSummary } from '../../../types/support/supportTicket';
import { TicketActionTypes } from './constants';

export type TicketActionType = {
    type:
        | TicketActionTypes.ADMIN_ARCHIVE
        | TicketActionTypes.ADMIN_CLOSE
        | TicketActionTypes.ADMIN_REPLY
        | TicketActionTypes.ADMIN_VIEW_ALL
        | TicketActionTypes.ADMIN_VIEW_ARCHIVED
        | TicketActionTypes.ADMIN_VIEW_DETAILS
        | TicketActionTypes.API_RESPONSE_ERROR
        | TicketActionTypes.API_RESPONSE_SUCCESS
        | TicketActionTypes.USER_CREATE
        | TicketActionTypes.USER_VIEW_ALL
        | TicketActionTypes.USER_VIEW_DETAILS
        | TicketActionTypes.USER_REPLY
        | TicketActionTypes.RESET;
    payload: {} | string;
};

export const ticketApiResponseSuccess = (actionType: string, data: TicketSummary | TicketDetails | {}): TicketActionType => ({
    type: TicketActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const ticketApiResponseError = (actionType: string, error: string): TicketActionType => ({
    type: TicketActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const ticketUserViewAll = (): TicketActionType => ({
    type: TicketActionTypes.USER_VIEW_ALL,
    payload: {},
});

export const ticketUserCreate = (ticket: TicketNew): TicketActionType => ({
    type: TicketActionTypes.USER_CREATE,
    payload: ticket,
});

export const ticketUserViewDetails = (id: string): TicketActionType => ({
    type: TicketActionTypes.USER_VIEW_DETAILS,
    payload: { id },
});

export const ticketUserReply = (reply: TicketReplyNew): TicketActionType => ({
    type: TicketActionTypes.USER_REPLY,
    payload: reply,
});

export const ticketAdminViewAll = (): TicketActionType => ({
    type: TicketActionTypes.ADMIN_VIEW_ALL,
    payload: {},
});

export const ticketAdminViewArchived = (): TicketActionType => ({
    type: TicketActionTypes.ADMIN_VIEW_ARCHIVED,
    payload: {},
});

export const ticketAdminViewDetails = (id: string): TicketActionType => ({
    type: TicketActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

export const ticketAdminReply = (reply: TicketReplyNew): TicketActionType => ({
    type: TicketActionTypes.ADMIN_REPLY,
    payload: reply,
});

export const ticketAdminClose = (id: string): TicketActionType => ({
    type: TicketActionTypes.ADMIN_CLOSE,
    payload: { id },
});

export const ticketAdminArchive = (id: string): TicketActionType => ({
    type: TicketActionTypes.ADMIN_ARCHIVE,
    payload: { id },
});

export const resetTicket = (): TicketActionType => ({
    type: TicketActionTypes.RESET,
    payload: {},
});

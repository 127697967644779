import { ReportActionTypes } from './constants';
import { ReportDetails, ReportSummary } from '../../../types/support/supportReport';

const INIT_STATE = {
    apiSuccess: false,
    loading: false,
    reportClosedSuccess: false,
    reportCreatedSuccess: false,
    reportedObjectId: null,
    userAllReports: null,
    adminAllReports: null,
    adminReportDetails: null,
};

type ReportActionType = {
    type:
        | ReportActionTypes.API_RESPONSE_ERROR
        | ReportActionTypes.API_RESPONSE_SUCCESS
        | ReportActionTypes.USER_VIEW_ALL
        | ReportActionTypes.USER_CREATE
        | ReportActionTypes.RESET
        | ReportActionTypes.ADMIN_VIEW_ALL
        | ReportActionTypes.ADMIN_CLOSE
        | ReportActionTypes.ADMIN_VIEW_DETAILS;
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    apiSuccess?: boolean;
    userAllReports: ReportSummary[] | null;
    adminAllReports: ReportSummary[] | null;
    adminReportDetails: ReportDetails | null;
    reportClosedSuccess: boolean;
    reportedObjectId: string | null;
    reportCreatedSuccess: boolean;
    loading?: boolean;
};

const Report = (state: State = INIT_STATE, action: ReportActionType) => {
    switch (action.type) {
        case ReportActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ReportActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        userAllReports: action.payload.data,
                        loading: false,
                    };
                }
                case ReportActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllReports: action.payload.data,
                        loading: false,
                    };
                }
                case ReportActionTypes.ADMIN_CLOSE: {
                    return {
                        ...state,
                        reportClosedSuccess: true,
                        loading: false,
                    };
                }
                case ReportActionTypes.USER_CREATE: {
                    return {
                        ...state,
                        reportedObjectId: action.payload.data,
                        reportCreatedSuccess: true,
                        loading: false,
                    };
                }
                case ReportActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminReportDetails: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
        case ReportActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ReportActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ReportActionTypes.USER_CREATE: {
                    return {
                        ...state,
                        reportCreatedSuccess: false,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ReportActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
        case ReportActionTypes.USER_VIEW_ALL:
            return { ...state, loading: true };
        case ReportActionTypes.ADMIN_VIEW_ALL:
            return { ...state, loading: true };
        case ReportActionTypes.USER_CREATE: {
            return {
                ...state,
                loading: true,
                reportCreatedSuccess: false,
            };
        }
        default:
            return { ...state };
    }
};

export default Report;

import { PageActionTypes } from './constants';
import { StaticPageData, UpdatedStaticPage } from '../../../types/cms/staticPage';

const INIT_STATE = {
    loading: false,
    allStaticPages: null,
    pageDetails: null,
    apiSuccess: false,
    updatedPage: null,
};

type PageActionType = {
    type:
        | PageActionTypes.API_RESPONSE_SUCCESS
        | PageActionTypes.ADMIN_VIEW_ALL
        | PageActionTypes.API_RESPONSE_ERROR
        | PageActionTypes.USER_VIEW
        | PageActionTypes.ADMIN_CREATE
        | PageActionTypes.ADMIN_UPDATE
        | PageActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: StaticPageData | {};
        error?: string;
    };
};

type State = {
    slug?: string;
    loading?: boolean;
    apiSuccess: boolean;
    pageDetails: null | StaticPageData;
    allStaticPages: null | StaticPageData[];
    updatedPage: null | UpdatedStaticPage;
};

const StaticPage = (state: State = INIT_STATE, action: PageActionType) => {
    switch (action.type) {
        case PageActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PageActionTypes.USER_VIEW: {
                    return {
                        ...state,
                        pageDetails: action.payload.data,
                        loading: false,
                    };
                }
                case PageActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        allStaticPages: action.payload.data,
                        loading: false,
                    };
                }
                case PageActionTypes.ADMIN_CREATE: {
                    return {
                        ...state,
                        updatedPage: action.payload.data,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                case PageActionTypes.ADMIN_UPDATE: {
                    return {
                        ...state,
                        updatedPage: action.payload.data,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PageActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PageActionTypes.USER_VIEW: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case PageActionTypes.ADMIN_CREATE: {
                    return {
                        ...state,
                        updatedPage: null,
                        apiSuccess: false,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case PageActionTypes.ADMIN_UPDATE: {
                    return {
                        ...state,
                        updatedPage: null,
                        apiSuccess: false,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case PageActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        allStaticPages: null,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PageActionTypes.USER_VIEW: {
            return {
                ...state,
                pageDetails: null,
                loading: false,
            };
        }
        case PageActionTypes.ADMIN_CREATE: {
            return {
                ...state,
                updatedPage: null,
                apiSuccess: false,
                loading: false,
            };
        }
        case PageActionTypes.ADMIN_UPDATE: {
            return {
                ...state,
                updatedPage: null,
                apiSuccess: false,
                loading: false,
            };
        }
        case PageActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case PageActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                loading: false,
                allStaticPages: null,
            };
        default:
            return { ...state };
    }
};

export default StaticPage;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { ChatActionTypes } from './constants';
import {
    apiUserSendMessage,
    apiUserViewAllContacts,
    apiAutoReadConversation,
    apiUserViewConversation,
    apiUserChangeConversationStatus,
    apiUserLoadFriends,
} from '../../../helpers/api/user/chat';
import { chatApiResponseSuccess, chatApiResponseError } from './actions';
import { MessageNew } from '../../../types/user/chat';

type ConversationPayload = {
    payload: {
        conversationId: string;
    };
    type: string;
};

type NewStatusPayload = {
    payload: {
        conversationId: string;
        status: number;
    };
    type: string;
};

type NewMessagePayload = {
    payload: MessageNew;
    type: string;
};

function* userViewAllContacts(): SagaIterator {
    try {
        let response = yield call(apiUserViewAllContacts);
        yield put(chatApiResponseSuccess(ChatActionTypes.USER_VIEW_ALL_CONTACTS, response.data));
    } catch (error: any) {
        yield put(chatApiResponseError(ChatActionTypes.USER_VIEW_ALL_CONTACTS, error));
    }
}

function* autoReadConversation({ payload: { conversationId } }: ConversationPayload): SagaIterator {
    try {
        let response = yield call(apiAutoReadConversation, conversationId);
        yield put(chatApiResponseSuccess(ChatActionTypes.USER_AUTO_READ_CONVERSATION, response.data));
    } catch (error: any) {
        yield put(chatApiResponseError(ChatActionTypes.USER_AUTO_READ_CONVERSATION, error));
    }
}

function* userViewConversation({ payload: { conversationId } }: ConversationPayload): SagaIterator {
    try {
        let response = yield call(apiUserViewConversation, conversationId);
        yield put(chatApiResponseSuccess(ChatActionTypes.USER_VIEW_CONVERSATION, response.data));
    } catch (error: any) {
        yield put(chatApiResponseError(ChatActionTypes.USER_VIEW_CONVERSATION, error));
    }
}

function* userChangeConversationStatus({ payload: { conversationId, status } }: NewStatusPayload): SagaIterator {
    try {
        let response = yield call(apiUserChangeConversationStatus, conversationId, status);
        yield put(chatApiResponseSuccess(ChatActionTypes.USER_CHANGE_CONVERSATION_STATUS, response.data));
    } catch (error: any) {
        yield put(chatApiResponseError(ChatActionTypes.USER_CHANGE_CONVERSATION_STATUS, error));
    }
}

function* userSendMessage({ payload }: NewMessagePayload): SagaIterator {
    try {
        let response = yield call(apiUserSendMessage, payload);
        yield put(chatApiResponseSuccess(ChatActionTypes.USER_SEND_MESSAGE, response.data));
    } catch (error: any) {
        yield put(chatApiResponseError(ChatActionTypes.USER_SEND_MESSAGE, error));
    }
}

function* userLoadAllFriends(): SagaIterator {
    try {
        let response = yield call(apiUserLoadFriends);
        yield put(chatApiResponseSuccess(ChatActionTypes.USER_LOAD_ALL_FRIENDS, response.data));
    } catch (error: any) {
        yield put(chatApiResponseError(ChatActionTypes.USER_LOAD_ALL_FRIENDS, error));
    }
}

export function* watchUserSendMessage() {
    yield takeEvery(ChatActionTypes.USER_SEND_MESSAGE, userSendMessage);
}

export function* watchUserLoadAllFriends() {
    yield takeEvery(ChatActionTypes.USER_LOAD_ALL_FRIENDS, userLoadAllFriends);
}

export function* watchUserViewAllContacts() {
    yield takeEvery(ChatActionTypes.USER_VIEW_ALL_CONTACTS, userViewAllContacts);
}

export function* watchUserChangeConversationStatus() {
    yield takeEvery(ChatActionTypes.USER_CHANGE_CONVERSATION_STATUS, userChangeConversationStatus);
}

export function* watchAutoReadConversation() {
    yield takeEvery(ChatActionTypes.USER_AUTO_READ_CONVERSATION, autoReadConversation);
}

export function* watchUserViewConversation() {
    yield takeEvery(ChatActionTypes.USER_VIEW_CONVERSATION, userViewConversation);
}

function* energyLogsSaga() {
    yield all([
        fork(watchUserLoadAllFriends),
        fork(watchUserSendMessage),
        fork(watchUserChangeConversationStatus),
        fork(watchUserViewAllContacts),
        fork(watchAutoReadConversation),
        fork(watchUserViewConversation),
    ]);
}

export default energyLogsSaga;

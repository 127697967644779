import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { ProblemHintNew } from '../../../types/problem/problemHint';

const api = new APICore();

function apiAdminViewHints(problemId: string) {
    return api.get(`${apiBaseUrl}/problems_hints/admin/problem/${problemId}`, null);
}

function apiAdminView(hintId: string) {
    return api.get(`${apiBaseUrl}/problems_hints/admin/${hintId}`, null);
}

function apiAdminCreate(data: ProblemHintNew) {
    return api.create(`${apiBaseUrl}/problems_hints/admin`, data);
}

function apiAdminUpdate(data: ProblemHintNew) {
    return api.update(`${apiBaseUrl}/problems_hints/admin`, data);
}

function apiAdminDelete(hintId: string) {
    return api.delete(`${apiBaseUrl}/problems_hints/admin/${hintId}`);
}

function apiUserView(hintId: string) {
    return api.get(`${apiBaseUrl}/problems_hints/${hintId}`, null);
}

function apiUserViewForProblem(problemId: string) {
    return api.get(`${apiBaseUrl}/problems_hints/problem/${problemId}`, null);
}

export { apiAdminViewHints, apiAdminView, apiAdminDelete, apiAdminCreate, apiAdminUpdate, apiUserView, apiUserViewForProblem };

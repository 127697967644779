import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { ProblemTestNew } from '../../../types/problem/problemTest';
import { apiAdminCreate, apiAdminDelete, apiAdminUpdate, apiAdminView, apiAdminViewTests } from '../../../helpers/api/problem/problemTest';
import { prbTestApiResponseError, prbTestApiResponseSuccess } from './actions';
import { ProblemTestActionTypes } from './constants';

type AdminProblemTestsPayloadType = {
    payload: { problemId: string };
    type: string;
};

type AdminProblemTestPayloadType = {
    payload: { testId: string };
    type: string;
};

type AdminProblemTestNewPayloadType = {
    payload: ProblemTestNew;
    type: string;
};

function* adminViewAllTests({ payload: { problemId } }: AdminProblemTestsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewTests, problemId);
        yield put(prbTestApiResponseSuccess(ProblemTestActionTypes.ADMIN_VIEW_ALL_PROBLEM, response.data));
    } catch (error: any) {
        yield put(prbTestApiResponseError(ProblemTestActionTypes.ADMIN_VIEW_ALL_PROBLEM, error));
    }
}

function* adminViewTest({ payload: { testId } }: AdminProblemTestPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminView, testId);
        yield put(prbTestApiResponseSuccess(ProblemTestActionTypes.ADMIN_VIEW, response.data));
    } catch (error: any) {
        yield put(prbTestApiResponseError(ProblemTestActionTypes.ADMIN_VIEW, error));
    }
}

function* adminUpsert({ payload }: AdminProblemTestNewPayloadType): SagaIterator {
    try {
        const response = yield call(payload.id ? apiAdminUpdate : apiAdminCreate, payload);
        yield put(prbTestApiResponseSuccess(ProblemTestActionTypes.ADMIN_UPSERT, response.data));
    } catch (error: any) {
        yield put(prbTestApiResponseError(ProblemTestActionTypes.ADMIN_UPSERT, error));
    }
}

function* adminDelete({ payload: { testId } }: AdminProblemTestPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminDelete, testId);
        yield put(prbTestApiResponseSuccess(ProblemTestActionTypes.ADMIN_DELETE, response.data));
    } catch (error: any) {
        yield put(prbTestApiResponseError(ProblemTestActionTypes.ADMIN_DELETE, error));
    }
}

export function* watchAdminViewAllTests() {
    yield takeEvery(ProblemTestActionTypes.ADMIN_VIEW_ALL_PROBLEM, adminViewAllTests);
}

export function* watchAdminViewTest() {
    yield takeEvery(ProblemTestActionTypes.ADMIN_VIEW, adminViewTest);
}

export function* watchAdminUpsert() {
    yield takeEvery(ProblemTestActionTypes.ADMIN_UPSERT, adminUpsert);
}

export function* watchAdminDelete() {
    yield takeEvery(ProblemTestActionTypes.ADMIN_DELETE, adminDelete);
}

function* ProblemTestSaga() {
    yield all([fork(watchAdminViewAllTests), fork(watchAdminViewTest), fork(watchAdminUpsert), fork(watchAdminDelete)]);
}

export default ProblemTestSaga;

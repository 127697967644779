import { CommentActionTypes } from './constants';
import { CommentDetails, PaginatedCommentSummary } from '../../../types/cms/comment';

const INIT_STATE = {
    loading: false,
    apiSuccess: false,
    sendSuccess: false,
    deleteSuccess: false,
    updateSuccess: false,
    adminAllComments: null,
    adminCommentDetails: null,
};

type CommentActionType = {
    type:
        | CommentActionTypes.API_RESPONSE_SUCCESS
        | CommentActionTypes.API_RESPONSE_ERROR
        | CommentActionTypes.ADMIN_VIEW_ALL
        | CommentActionTypes.ADMIN_VIEW_DETAILS
        | CommentActionTypes.ADMIN_UPDATE
        | CommentActionTypes.ADMIN_DELETE
        | CommentActionTypes.USER_SEND
        | CommentActionTypes.USER_GET
        | CommentActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    apiSuccess: boolean;
    deleteSuccess: boolean;
    updateSuccess: boolean;
    sendSuccess: boolean;
    adminAllComments?: PaginatedCommentSummary | null;
    adminCommentDetails?: CommentDetails | null;
    userViewComments?: CommentDetails[] | null;
    loading?: boolean;
};

const Comment = (state: State = INIT_STATE, action: CommentActionType) => {
    switch (action.type) {
        case CommentActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CommentActionTypes.USER_GET: {
                    return {
                        ...state,
                        userViewComments: action.payload.data,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                case CommentActionTypes.USER_SEND: {
                    return {
                        ...state,
                        sendSuccess: true,
                        loading: false,
                    };
                }
                case CommentActionTypes.ADMIN_DELETE: {
                    return {
                        ...state,
                        deleteSuccess: true,
                        loading: false,
                    };
                }
                case CommentActionTypes.ADMIN_UPDATE: {
                    return {
                        ...state,
                        updateSuccess: true,
                        loading: false,
                    };
                }
                case CommentActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        apiSuccess: true,
                        loading: false,
                        adminAllComments: action.payload.data,
                    };
                }
                case CommentActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        apiSuccess: true,
                        loading: false,
                        adminCommentDetails: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case CommentActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case CommentActionTypes.USER_SEND: {
                    return {
                        ...state,
                        error: action.payload.error,
                        apiSuccess: false,
                        loading: false,
                    };
                }
                case CommentActionTypes.ADMIN_UPDATE: {
                    return {
                        ...state,
                        apiSuccess: false,
                        loading: false,
                        updateSuccess: false,
                    };
                }
                default:
                    return { ...state };
            }

        case CommentActionTypes.ADMIN_DELETE:
            return {
                ...state,
                userViewComments: null,
                deleteSuccess: false,
            };
        case CommentActionTypes.ADMIN_UPDATE:
            return {
                ...state,
                updateSuccess: false,
            };
        case CommentActionTypes.ADMIN_VIEW_DETAILS:
            return {
                ...state,
                adminCommentDetails: null,
            };
        case CommentActionTypes.USER_SEND:
            return {
                ...state,
                sendSuccess: false,
                loading: true,
            };

        case CommentActionTypes.USER_GET:
        case CommentActionTypes.RESET:
            return {
                ...state,
                apiSuccess: false,
                loading: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default Comment;

import { AnswerActionTypes } from './constants';
import { AnswerDetails, AnswerSelectableShort } from '../../../types/quiz/answer';

const INIT_STATE = {
    apiUpsertSuccess: false,
    adminAllAnswers: null,
    adminAllAnswersShort: null,
    adminAnswerDetails: null,
    loading: false,
};

type AnswerActionType = {
    type:
        | AnswerActionTypes.API_RESPONSE_SUCCESS
        | AnswerActionTypes.API_RESPONSE_ERROR
        | AnswerActionTypes.ADMIN_VIEW_ALL
        | AnswerActionTypes.ADMIN_VIEW_ALL_SHORT
        | AnswerActionTypes.ADMIN_VIEW_DETAILS
        | AnswerActionTypes.ADMIN_DELETE
        | AnswerActionTypes.ADMIN_UPSERT
        | AnswerActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: AnswerDetails | {};
        error?: string;
    };
};

type State = {
    apiUpsertSuccess: boolean;
    adminAllAnswers?: AnswerDetails[] | null;
    adminAllAnswersShort?: AnswerSelectableShort[] | null;
    adminAnswerDetails?: AnswerDetails | null;
    loading?: boolean;
};

const Answer = (state: State = INIT_STATE, action: AnswerActionType) => {
    switch (action.type) {
        case AnswerActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AnswerActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllAnswers: action.payload.data,
                        loading: false,
                    };
                }
                case AnswerActionTypes.ADMIN_VIEW_ALL_SHORT: {
                    return {
                        ...state,
                        adminAllAnswersShort: action.payload.data,
                    };
                }
                case AnswerActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminAnswerDetails: action.payload.data,
                    };
                }
                case AnswerActionTypes.ADMIN_UPSERT: {
                    return {
                        ...state,
                        apiUpsertSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }

        case AnswerActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AnswerActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminAnswerDetails: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case AnswerActionTypes.ADMIN_VIEW_DETAILS:
            return {
                ...state,
                error: false,
                adminAnswerDetails: null,
                apiUpsertSuccess: false,
            };

        case AnswerActionTypes.ADMIN_UPSERT:
            return {
                ...state,
                apiUpsertSuccess: false,
            };

        case AnswerActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                loading: true,
                error: false,
            };

        case AnswerActionTypes.RESET:
            return {
                ...state,
                loading: false,
                apiUpsertSuccess: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default Answer;

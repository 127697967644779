import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { FriendshipActionTypes } from './constants';
import {
    apiUserViewLast,
    apiUserViewAll,
    apiUserCountFriends,
    apiUserDestroyFriendship,
    apiUserAcceptFriendship,
    apiUserViewAllIncoming,
    apiUserAddFriend,
    apiUserCheckFriendship,
} from '../../../helpers/api/user/friendship';
import { friendshipApiResponseError, friendshipApiResponseSuccess } from './actions';

type UsernameFriendshipPayload = {
    payload: {
        username: string;
    };
    type: string;
};

type FriendshipRequestPayload = {
    payload: {
        friendshipId: string;
    };
    type: string;
};

type FriendshipUsernamePayload = {
    payload: {
        username: string;
    };
    type: string;
};

function* userViewLast({ payload: { username } }: FriendshipUsernamePayload): SagaIterator {
    try {
        const response = yield call(apiUserViewLast, username);
        yield put(friendshipApiResponseSuccess(FriendshipActionTypes.USER_VIEW_LAST, response.data));
    } catch (error: any) {
        yield put(friendshipApiResponseError(FriendshipActionTypes.USER_VIEW_LAST, error));
    }
}

function* userCountFriends({ payload: { username } }: FriendshipUsernamePayload): SagaIterator {
    try {
        const response = yield call(apiUserCountFriends, username);
        yield put(friendshipApiResponseSuccess(FriendshipActionTypes.USER_COUNT_FRIENDS, response.data));
    } catch (error: any) {
        yield put(friendshipApiResponseError(FriendshipActionTypes.USER_COUNT_FRIENDS, error));
    }
}

function* userViewAll({ payload: { username } }: UsernameFriendshipPayload): SagaIterator {
    try {
        const response = yield call(apiUserViewAll, username);
        yield put(friendshipApiResponseSuccess(FriendshipActionTypes.USER_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(friendshipApiResponseError(FriendshipActionTypes.USER_VIEW_ALL, error));
    }
}

function* userDestroyFriendship({ payload: { friendshipId } }: FriendshipRequestPayload): SagaIterator {
    try {
        const response = yield call(apiUserDestroyFriendship, friendshipId);
        yield put(friendshipApiResponseSuccess(FriendshipActionTypes.USER_DESTROY_FRIENDSHIP, response.data));
    } catch (error: any) {
        yield put(friendshipApiResponseError(FriendshipActionTypes.USER_DESTROY_FRIENDSHIP, error));
    }
}

function* userAcceptFriendship({ payload: { friendshipId } }: FriendshipRequestPayload): SagaIterator {
    try {
        const response = yield call(apiUserAcceptFriendship, friendshipId);
        yield put(friendshipApiResponseSuccess(FriendshipActionTypes.USER_ACCEPT_FRIENDSHIP, response.data));
    } catch (error: any) {
        yield put(friendshipApiResponseError(FriendshipActionTypes.USER_ACCEPT_FRIENDSHIP, error));
    }
}

function* userCheckFriendship({ payload: { username } }: UsernameFriendshipPayload): SagaIterator {
    try {
        const response = yield call(apiUserCheckFriendship, username);
        yield put(friendshipApiResponseSuccess(FriendshipActionTypes.USER_CHECK_FRIENDSHIP, response.data));
    } catch (error: any) {
        yield put(friendshipApiResponseError(FriendshipActionTypes.USER_CHECK_FRIENDSHIP, error));
    }
}

function* userViewAllIncoming(): SagaIterator {
    try {
        const response = yield call(apiUserViewAllIncoming);
        yield put(friendshipApiResponseSuccess(FriendshipActionTypes.USER_VIEW_ALL_INCOMING, response.data));
    } catch (error: any) {
        yield put(friendshipApiResponseError(FriendshipActionTypes.USER_VIEW_ALL_INCOMING, error));
    }
}

function* userSendFriendRequest({ payload: { username } }: UsernameFriendshipPayload): SagaIterator {
    try {
        const response = yield call(apiUserAddFriend, username);
        yield put(friendshipApiResponseSuccess(FriendshipActionTypes.USER_SEND_REQUEST, response.data));
    } catch (error: any) {
        yield put(friendshipApiResponseError(FriendshipActionTypes.USER_SEND_REQUEST, error));
    }
}

export function* watchUserViewLast() {
    yield takeEvery(FriendshipActionTypes.USER_VIEW_LAST, userViewLast);
}

export function* watchUserSendFriendRequest() {
    yield takeEvery(FriendshipActionTypes.USER_SEND_REQUEST, userSendFriendRequest);
}

export function* watchUserViewAllIncoming() {
    yield takeEvery(FriendshipActionTypes.USER_VIEW_ALL_INCOMING, userViewAllIncoming);
}

export function* watchUserAcceptFriendship() {
    yield takeEvery(FriendshipActionTypes.USER_ACCEPT_FRIENDSHIP, userAcceptFriendship);
}

export function* watchUserViewAll() {
    yield takeEvery(FriendshipActionTypes.USER_VIEW_ALL, userViewAll);
}

export function* watchUserCountFriends() {
    yield takeEvery(FriendshipActionTypes.USER_COUNT_FRIENDS, userCountFriends);
}

export function* watchUserDestroyFriendship() {
    yield takeEvery(FriendshipActionTypes.USER_DESTROY_FRIENDSHIP, userDestroyFriendship);
}

export function* watchUserCheckFriendship() {
    yield takeEvery(FriendshipActionTypes.USER_CHECK_FRIENDSHIP, userCheckFriendship);
}

function* energyLogsSaga() {
    yield all([
        fork(watchUserCheckFriendship),
        fork(watchUserSendFriendRequest),
        fork(watchUserViewAllIncoming),
        fork(watchUserAcceptFriendship),
        fork(watchUserViewLast),
        fork(watchUserViewAll),
        fork(watchUserCountFriends),
        fork(watchUserDestroyFriendship),
    ]);
}

export default energyLogsSaga;

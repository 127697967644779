import { APICore } from '../../apiCore';
import { apiBaseUrl } from '../../../../constants';
import { TimespentNew } from '../../../../types/user/logs/timespent';

const api = new APICore();

function apiAdminViewAll(page: number, size: number) {
    return api.get(`${apiBaseUrl}/timespent/admin/page/${page}/${size}`, null);
}

function apiUserSend(data: TimespentNew) {
    return api.create(`${apiBaseUrl}/timespent/create`, data);
}

export { apiAdminViewAll, apiUserSend };

import { AdminPanelActionTypes } from './constants';

const INIT_STATE = {
    apiUpsertSuccess: false,
    loading: false,
};

type AdminPanelActionType = {
    type: AdminPanelActionTypes.API_RESPONSE_SUCCESS | AdminPanelActionTypes.API_RESPONSE_ERROR | AdminPanelActionTypes.ADMIN_CLEAR_CACHE;
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    loading?: boolean;
};

const AdminPanel = (state: State = INIT_STATE, action: AdminPanelActionType) => {
    switch (action.type) {
        case AdminPanelActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AdminPanelActionTypes.ADMIN_CLEAR_CACHE: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AdminPanelActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AdminPanelActionTypes.ADMIN_CLEAR_CACHE: {
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case AdminPanelActionTypes.ADMIN_CLEAR_CACHE:
            return {
                ...state,
                error: false,
                loading: true,
            };

        default:
            return { ...state };
    }
};

export default AdminPanel;

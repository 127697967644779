export enum ShopBundleActionTypes {
    API_RESPONSE_SUCCESS = '@@shopBundle/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@shopBundle/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL = '@@shopBundle/ADMIN_VIEW_ALL',
    ADMIN_VIEW_ALL_SHORT = '@@shopBundle/ADMIN_VIEW_ALL_SHORT',

    USER_VIEW_ALL = '@@shopBundle/USER_VIEW_ALL',
    USER_VIEW_DETAILS = '@@shopBundle/ADMIN_VIEW_DETAILS',
}

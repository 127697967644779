import { ProblemHintActionTypes } from './constants';
import { ProblemHintNew } from '../../../types/problem/problemHint';

export type ProblemHintActionType = {
    type:
        | ProblemHintActionTypes.API_RESPONSE_ERROR
        | ProblemHintActionTypes.API_RESPONSE_SUCCESS
        | ProblemHintActionTypes.ADMIN_VIEW_ALL_PROBLEM
        | ProblemHintActionTypes.ADMIN_VIEW
        | ProblemHintActionTypes.ADMIN_UPSERT
        | ProblemHintActionTypes.ADMIN_DELETE
        | ProblemHintActionTypes.USER_VIEW_ALL_PROBLEM
        | ProblemHintActionTypes.USER_VIEW;
    payload: {} | string;
};

export const prbHintApiResponseSuccess = (actionType: string, data: {}): ProblemHintActionType => ({
    type: ProblemHintActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const prbHintApiResponseError = (actionType: string, error: string): ProblemHintActionType => ({
    type: ProblemHintActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const prbHintAdminViewAllProblem = (problemId: string): ProblemHintActionType => ({
    type: ProblemHintActionTypes.ADMIN_VIEW_ALL_PROBLEM,
    payload: { problemId },
});

export const prbHintAdminView = (hintId: string): ProblemHintActionType => ({
    type: ProblemHintActionTypes.ADMIN_VIEW,
    payload: { hintId },
});

export const prbHintAdminUpsert = (data: ProblemHintNew): ProblemHintActionType => ({
    type: ProblemHintActionTypes.ADMIN_UPSERT,
    payload: data,
});

export const prbHintAdminDelete = (hintId: string): ProblemHintActionType => ({
    type: ProblemHintActionTypes.ADMIN_DELETE,
    payload: { hintId },
});

export const prbHintUserViewAllProblem = (problemId: string): ProblemHintActionType => ({
    type: ProblemHintActionTypes.USER_VIEW_ALL_PROBLEM,
    payload: { problemId },
});

export const prbHintUserView = (hintId: string): ProblemHintActionType => ({
    type: ProblemHintActionTypes.USER_VIEW,
    payload: { hintId },
});

import { UserPurchaseActionTypes } from './constants';
import { UserPurchaseNew } from '../../../types/user/purchase';

export type UserPurchaseActionType = {
    type: UserPurchaseActionTypes.API_RESPONSE_SUCCESS | UserPurchaseActionTypes.API_RESPONSE_ERROR | UserPurchaseActionTypes.USER_CREATE | UserPurchaseActionTypes.RESET;
    payload: {} | string;
};

export const purchaseMessageAllApiResponseSuccess = (actionType: string, data: {}): UserPurchaseActionType => ({
    type: UserPurchaseActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const purchaseMessageApiResponseError = (actionType: string, error: string): UserPurchaseActionType => ({
    type: UserPurchaseActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const purchaseUserCreate = (data: UserPurchaseNew): UserPurchaseActionType => ({
    type: UserPurchaseActionTypes.USER_CREATE,
    payload: data,
});

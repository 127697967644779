import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { SchoolNew } from '../../../types/cms/school';

const api = new APICore();

function apiAdminGetAll() {
    return api.get(`${apiBaseUrl}/schools/admin`, null);
}

function apiAdminView(params: { schoolId: number }) {
    return api.get(`${apiBaseUrl}/schools/admin/${params.schoolId}`, null);
}

function apiAdminCreate(school: SchoolNew) {
    return api.create(`${apiBaseUrl}/schools/admin`, school);
}

function apiAdminUpdate(school: SchoolNew) {
    return api.update(`${apiBaseUrl}/schools/admin`, school);
}

function apiAdminDelete(params: { schoolId: number }) {
    return api.delete(`${apiBaseUrl}/schools/admin/${params.schoolId}`);
}

export { apiAdminGetAll, apiAdminCreate, apiAdminUpdate, apiAdminView, apiAdminDelete };

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { aiConversationApiResponseError, aiConversationApiResponseSuccess } from './actions';
import { AiConversationActionTypes } from './constants';
import { AiConversationSummary } from '../../../types/ai/aiConversation';
import { apiAdminViewAll, apiAdminViewDetails } from '../../../helpers/api/ai/conversation';

type AdminAiConversationDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

function* adminViewAll(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll);
        const responseData: AiConversationSummary[] = response.data.map((contact: AiConversationSummary) => {
            return Object.assign(contact, {
                id_short: contact.id.substring(0, 8),
            });
        });
        yield put(aiConversationApiResponseSuccess(AiConversationActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(aiConversationApiResponseError(AiConversationActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewDetails({ payload: { id } }: AdminAiConversationDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(aiConversationApiResponseSuccess(AiConversationActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(aiConversationApiResponseError(AiConversationActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(AiConversationActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewDetails() {
    yield takeEvery(AiConversationActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

function* AiConversationSaga() {
    yield all([fork(watchAdminViewAll), fork(watchAdminViewDetails)]);
}

export default AiConversationSaga;

import { UserPurchaseActionTypes } from './constants';

const INIT_STATE = {
    apiPurchaseSuccess: false,
    loading: false,
};

type AiMessageActionType = {
    type: UserPurchaseActionTypes.API_RESPONSE_SUCCESS | UserPurchaseActionTypes.API_RESPONSE_ERROR | UserPurchaseActionTypes.USER_CREATE | UserPurchaseActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    apiPurchaseSuccess: boolean;
    loading?: boolean;
};

const UserPurchase = (state: State = INIT_STATE, action: AiMessageActionType) => {
    switch (action.type) {
        case UserPurchaseActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case UserPurchaseActionTypes.USER_CREATE: {
                    return {
                        ...state,
                        apiPurchaseSuccess: true,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case UserPurchaseActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case UserPurchaseActionTypes.USER_CREATE: {
                    return {
                        ...state,
                        apiPurchaseSuccess: false,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case UserPurchaseActionTypes.USER_CREATE:
            return {
                ...state,
                apiPurchaseSuccess: false,
            };

        default:
            return { ...state };
    }
};

export default UserPurchase;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { NotificationActionTypes } from './constants';
import { apiUserViewLast, apiUserViewAll, apiUserReadAll, apiUserReadOne } from '../../../helpers/api/user/notification';
import { NotificationDetails } from '../../../types/user/notification';
import { notificationApiResponseError, notificationApiResponseSuccess } from './actions';
import { userNotificationMenuItems } from '../../../types/user/notification';

type NotificationReadPayload = {
    payload: {
        id: string;
    };
    type: string;
};

function* userViewLast(): SagaIterator {
    try {
        const response = yield call(apiUserViewLast);
        const responseData: NotificationDetails[] = response.data.map((notification: NotificationDetails) => {
            return Object.assign(notification, {
                title: notification.title.length > 30 ? notification.title.substring(0, 30) + '...' : notification.title,
                variant: 'info',
                icon: 'mdi ' + userNotificationMenuItems[notification.type].icon,
                isRead: notification.readStatus === 2,
            });
        });
        yield put(notificationApiResponseSuccess(NotificationActionTypes.USER_VIEW_LAST, responseData));
    } catch (error: any) {
        yield put(notificationApiResponseError(NotificationActionTypes.USER_VIEW_LAST, error));
    }
}

function* userReadAll(): SagaIterator {
    try {
        const response = yield call(apiUserReadAll);
        yield put(notificationApiResponseSuccess(NotificationActionTypes.USER_READ_ALL, response));
    } catch (error: any) {
        yield put(notificationApiResponseError(NotificationActionTypes.USER_READ_ALL, error));
    }
}

function* userViewAll(): SagaIterator {
    try {
        const response = yield call(apiUserViewAll);
        const responseData: NotificationDetails[] = response.data.map((notification: NotificationDetails) => {
            return Object.assign(notification, {
                title: notification.title.length > 30 ? notification.title.substring(0, 30) + '...' : notification.title,
                variant: 'info',
                icon: 'mdi ' + userNotificationMenuItems[notification.type].icon,
                isRead: notification.readStatus === 2,
            });
        });
        yield put(notificationApiResponseSuccess(NotificationActionTypes.USER_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(notificationApiResponseError(NotificationActionTypes.USER_VIEW_ALL, error));
    }
}

function* userReadOne({ payload: { id } }: NotificationReadPayload): SagaIterator {
    try {
        const response = yield call(apiUserReadOne, id);
        yield put(notificationApiResponseSuccess(NotificationActionTypes.USER_READ, response));
    } catch (error: any) {
        yield put(notificationApiResponseError(NotificationActionTypes.USER_READ, error));
    }
}

export function* watchUserReadOne() {
    yield takeEvery(NotificationActionTypes.USER_READ, userReadOne);
}

export function* watchUserViewLast() {
    yield takeEvery(NotificationActionTypes.USER_VIEW_LAST, userViewLast);
}

export function* watchUserViewAll() {
    yield takeEvery(NotificationActionTypes.USER_VIEW_ALL, userViewAll);
}

export function* watchUserReadAll() {
    yield takeEvery(NotificationActionTypes.USER_READ_ALL, userReadAll);
}

function* energyLogsSaga() {
    yield all([fork(watchUserViewLast), fork(watchUserViewAll), fork(watchUserReadAll), fork(watchUserReadOne)]);
}

export default energyLogsSaga;

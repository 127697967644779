import { TagActionTypes } from './constants';
import { TagDetails, TagNew } from '../../../types/cms/tag';

export type TagActionType = {
    type:
        | TagActionTypes.ADMIN_VIEW_ALL
        | TagActionTypes.ADMIN_CREATE
        | TagActionTypes.ADMIN_UPDATE
        | TagActionTypes.ADMIN_VIEW
        | TagActionTypes.API_RESPONSE_SUCCESS
        | TagActionTypes.API_RESPONSE_ERROR
        | TagActionTypes.USER_VIEW_ALL
        | TagActionTypes.RESET;
    payload: {} | string;
};

export const tagUserViewAll = (entity: string): TagActionType => ({
    type: TagActionTypes.USER_VIEW_ALL,
    payload: { entity },
});

export const tagAdminViewAll = (): TagActionType => ({
    type: TagActionTypes.ADMIN_VIEW_ALL,
    payload: {},
});

export const tagAdminView = (tagId: string): TagActionType => ({
    type: TagActionTypes.ADMIN_VIEW,
    payload: { tagId },
});

export const tagAdminUpdate = (tag: TagNew): TagActionType => ({
    type: TagActionTypes.ADMIN_UPDATE,
    payload: tag,
});

export const tagAdminCreate = (tag: TagNew): TagActionType => ({
    type: TagActionTypes.ADMIN_CREATE,
    payload: tag,
});

export const tagApiResponseSuccess = (actionType: string, data: TagDetails | {}): TagActionType => ({
    type: TagActionTypes.API_RESPONSE_SUCCESS,
    payload: {
        actionType,
        data,
    },
});

export const tagApiResponseError = (actionType: string, error: string): TagActionType => ({
    type: TagActionTypes.API_RESPONSE_ERROR,
    payload: {
        actionType,
        error,
    },
});

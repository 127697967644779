import { LectureActionTypes } from './constants';
import { LectureDetails, LectureNew, LectureSummary } from '../../../types/theory/lecture';

export type LectureActionType = {
    type:
        | LectureActionTypes.API_RESPONSE_SUCCESS
        | LectureActionTypes.API_RESPONSE_ERROR
        | LectureActionTypes.ADMIN_VIEW_ALL
        | LectureActionTypes.ADMIN_VIEW_ALL_SHORT
        | LectureActionTypes.ADMIN_VIEW_DETAILS
        | LectureActionTypes.ADMIN_DELETE
        | LectureActionTypes.ADMIN_UPSERT
        | LectureActionTypes.USER_VIEW_DETAILS
        | LectureActionTypes.USER_VIEW_RECAP
        | LectureActionTypes.RESET;
    payload: {} | string;
};

export const lectureApiResponseSuccess = (actionType: string, data: LectureSummary | LectureDetails | {}): LectureActionType => ({
    type: LectureActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const lectureApiResponseError = (actionType: string, error: string): LectureActionType => ({
    type: LectureActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const lectureAdminViewAll = (page: number, size: number): LectureActionType => ({
    type: LectureActionTypes.ADMIN_VIEW_ALL,
    payload: { page, size },
});

export const lectureAdminViewAllShort = (): LectureActionType => ({
    type: LectureActionTypes.ADMIN_VIEW_ALL_SHORT,
    payload: {},
});

export const lectureAdminViewDetails = (id: string): LectureActionType => ({
    type: LectureActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

export const lectureAdminUpsert = (data: LectureNew): LectureActionType => ({
    type: LectureActionTypes.ADMIN_UPSERT,
    payload: data,
});

export const lectureUserViewDetails = (slug: string): LectureActionType => ({
    type: LectureActionTypes.USER_VIEW_DETAILS,
    payload: { slug },
});

export const lectureUserViewRecap = (slug: string): LectureActionType => ({
    type: LectureActionTypes.USER_VIEW_RECAP,
    payload: { slug },
});

export const lectureUserReset = (): LectureActionType => ({
    type: LectureActionTypes.RESET,
    payload: {},
});

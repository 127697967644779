import { UserRankingActionTypes } from './constants';
import { RankingDetails } from '../../../types/user/ranking';

const INIT_STATE = {
    apiSuccess: false,
    loading: false,
    setCanSend: true,
    rankingData: null,
};

type RankingActionType = {
    type: UserRankingActionTypes.API_RESPONSE_ERROR | UserRankingActionTypes.API_RESPONSE_SUCCESS | UserRankingActionTypes.USER_VIEW | UserRankingActionTypes.RESET;

    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    apiSuccess?: boolean;
    loading?: boolean;
    error?: string | null;
    setCanSend: boolean;
    rankingData: RankingDetails[] | null;
};

const Ranking = (state: State = INIT_STATE, action: RankingActionType) => {
    switch (action.type) {
        case UserRankingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case UserRankingActionTypes.USER_VIEW: {
                    return {
                        ...state,
                        rankingData: action.payload.data,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }
        case UserRankingActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case UserRankingActionTypes.USER_VIEW:
                    return {
                        loading: true,
                        apiSuccess: false,
                    };
                default:
                    return { ...state };
            }
        case UserRankingActionTypes.USER_VIEW: {
            return {
                ...state,
                loading: false,
                apiSuccess: false,
                rankingData: null,
            };
        }
        default:
            return { ...state };
    }
};

export default Ranking;

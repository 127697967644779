import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { PersonalDataBody, PreferenceResponse, ProfileDataBody, UserDeleteAccount, UserNewPassword } from '../../../types/user/settings';

const api = new APICore();

function apiUserViewPersonalData() {
    return api.get(`${apiBaseUrl}/settings/personal-data`, null);
}

function apiUserViewProfileData() {
    return api.get(`${apiBaseUrl}/settings/profile-data`, null);
}

function apiUserViewPreferenceData() {
    return api.get(`${apiBaseUrl}/settings/preference-data`, null);
}

function apiUserUpdatePersonalData(body: PersonalDataBody) {
    return api.update(`${apiBaseUrl}/settings/personal-data`, body);
}

function apiUserUploadProfileAvatar(formData: FormData) {
    return api.createWithFileData(`${apiBaseUrl}/settings/upload-avatar`, formData);
}

function apiUserUpdateProfileData(body: ProfileDataBody) {
    return api.update(`${apiBaseUrl}/settings/profile-data`, body);
}

function apiUserUpdatePassword(body: UserNewPassword) {
    return api.update(`${apiBaseUrl}/settings/change-password`, body);
}

function apiUserUpdatePreferenceData(body: PreferenceResponse) {
    return api.update(`${apiBaseUrl}/settings/update-preferences`, body);
}

function apiUserDeleteAccount(body: UserDeleteAccount) {
    return api.update(`${apiBaseUrl}/settings/delete`, body);
}

export {
    apiUserViewPersonalData,
    apiUserViewProfileData,
    apiUserViewPreferenceData,
    apiUserDeleteAccount,
    apiUserUpdatePreferenceData,
    apiUserUpdatePersonalData,
    apiUserUploadProfileAvatar,
    apiUserUpdateProfileData,
    apiUserUpdatePassword,
};

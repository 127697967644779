import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiUserViewAll, apiAdminViewAll, apiAdminViewDetails, apiAdminClose, apiUserCreate } from '../../../helpers/api/cms/report';
import { reportApiResponseError, reportApiResponseSuccess } from './actions';
import { ReportActionTypes } from './constants';
import { ReportNew } from '../../../types/support/supportReport';

type ReportPayloadType = {
    payload: {
        id: string;
        type: string;
    };
    type: string;
};

type ReportCreatePayloadType = {
    payload: ReportNew;
    type: string;
};

type ReportClosePayloadType = {
    payload: {
        id: string;
        type: string;
        status: number;
    };
    type: string;
};

function* userViewAll(): SagaIterator {
    try {
        const response = yield call(apiUserViewAll);
        yield put(reportApiResponseSuccess(ReportActionTypes.USER_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(reportApiResponseError(ReportActionTypes.USER_VIEW_ALL, error));
    }
}

function* adminViewAll(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll);
        yield put(reportApiResponseSuccess(ReportActionTypes.ADMIN_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(reportApiResponseError(ReportActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewDetails({ payload: { id, type } }: ReportPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id, type);
        yield put(reportApiResponseSuccess(ReportActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(reportApiResponseError(ReportActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

function* adminClose({ payload: { id, type, status } }: ReportClosePayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminClose, { id, type, status });
        yield put(reportApiResponseSuccess(ReportActionTypes.ADMIN_CLOSE, response.data));
    } catch (error: any) {
        yield put(reportApiResponseError(ReportActionTypes.ADMIN_CLOSE, error));
    }
}

function* userCreate({ payload }: ReportCreatePayloadType): SagaIterator {
    try {
        const type = payload.typeApi!!;
        delete payload.typeApi;
        const response = yield call(apiUserCreate, type, payload);
        yield put(reportApiResponseSuccess(ReportActionTypes.USER_CREATE, response.data));
    } catch (error: any) {
        yield put(reportApiResponseError(ReportActionTypes.USER_CREATE, error));
    }
}

export function* watchUserViewAll() {
    yield takeEvery(ReportActionTypes.USER_VIEW_ALL, userViewAll);
}

export function* watchUserCreate() {
    yield takeEvery(ReportActionTypes.USER_CREATE, userCreate);
}

export function* watchAdminClose() {
    yield takeEvery(ReportActionTypes.ADMIN_CLOSE, adminClose);
}

export function* watchAdminViewDetails() {
    yield takeEvery(ReportActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

export function* watchAdminViewAll() {
    yield takeEvery(ReportActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

function* reportSaga() {
    yield all([fork(watchUserCreate), fork(watchUserViewAll), fork(watchAdminViewAll), fork(watchAdminViewDetails), fork(watchAdminClose)]);
}

export default reportSaga;

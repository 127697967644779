import { ProblemTestActionTypes } from './constants';
import { ProblemTestNew } from '../../../types/problem/problemTest';

export type ProblemTestActionType = {
    type:
        | ProblemTestActionTypes.API_RESPONSE_ERROR
        | ProblemTestActionTypes.API_RESPONSE_SUCCESS
        | ProblemTestActionTypes.ADMIN_VIEW_ALL_PROBLEM
        | ProblemTestActionTypes.ADMIN_VIEW
        | ProblemTestActionTypes.ADMIN_UPSERT
        | ProblemTestActionTypes.ADMIN_DELETE;
    payload: {} | string;
};

export const prbTestApiResponseSuccess = (actionType: string, data: {}): ProblemTestActionType => ({
    type: ProblemTestActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const prbTestApiResponseError = (actionType: string, error: string): ProblemTestActionType => ({
    type: ProblemTestActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const prbTestAdminViewAllProblem = (problemId: string): ProblemTestActionType => ({
    type: ProblemTestActionTypes.ADMIN_VIEW_ALL_PROBLEM,
    payload: { problemId },
});

export const prbTestAdminView = (testId: string): ProblemTestActionType => ({
    type: ProblemTestActionTypes.ADMIN_VIEW,
    payload: { testId },
});

export const prbTestAdminUpsert = (data: ProblemTestNew): ProblemTestActionType => ({
    type: ProblemTestActionTypes.ADMIN_UPSERT,
    payload: data,
});

export const prbTestAdminDelete = (testId: string): ProblemTestActionType => ({
    type: ProblemTestActionTypes.ADMIN_DELETE,
    payload: { testId },
});

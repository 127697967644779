export enum TicketActionTypes {
    ADMIN_ARCHIVE = '@@ticket/ADMIN_ARCHIVE',
    ADMIN_CLOSE = '@@ticket/ADMIN_CLOSE',
    ADMIN_REPLY = '@@ticket/ADMIN_REPLY',
    ADMIN_VIEW_ALL = '@@ticket/ADMIN_VIEW_ALL',
    ADMIN_VIEW_ARCHIVED = '@@ticket/ADMIN_VIEW_ARCHIVED',
    ADMIN_VIEW_DETAILS = '@@ticket/ADMIN_VIEW_DETAILS',

    API_RESPONSE_ERROR = '@@ticket/API_RESPONSE_ERROR',
    API_RESPONSE_SUCCESS = '@@ticket/API_RESPONSE_SUCCESS',

    USER_CREATE = '@@ticket/USER_CREATE',
    USER_REPLY = '@@ticket/USER_REPLY',
    USER_VIEW_ALL = '@@ticket/USER_VIEW_ALL',
    USER_VIEW_DETAILS = '@@ticket/USER_VIEW_DETAILS',

    RESET = '@@ticket/RESET',
}

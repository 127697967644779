import { ChapterDetails, ChapterSelectableShort, ChapterSummary } from '../../../types/theory/chapter';
import { ChapterActionTypes } from './constants';

const INIT_STATE = {
    apiUpsertSuccess: false,
    adminAllChapters: null,
    adminAllChaptersShort: null,
    adminChapterDetails: null,
    loading: false,
};

type ChapterActionType = {
    type:
        | ChapterActionTypes.API_RESPONSE_SUCCESS
        | ChapterActionTypes.API_RESPONSE_ERROR
        | ChapterActionTypes.ADMIN_VIEW_ALL
        | ChapterActionTypes.ADMIN_VIEW_ALL_SHORT
        | ChapterActionTypes.ADMIN_VIEW_DETAILS
        | ChapterActionTypes.ADMIN_DELETE
        | ChapterActionTypes.ADMIN_UPSERT
        | ChapterActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: ChapterSummary | ChapterDetails | {};
        error?: string;
    };
};

type State = {
    apiUpsertSuccess: boolean;
    adminAllChapters?: ChapterSummary[] | null;
    adminAllChaptersShort?: ChapterSelectableShort[] | null;
    adminChapterDetails?: ChapterDetails | null;
    loading?: boolean;
};

const Chapter = (state: State = INIT_STATE, action: ChapterActionType) => {
    switch (action.type) {
        case ChapterActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ChapterActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllChapters: action.payload.data,
                        loading: false,
                    };
                }
                case ChapterActionTypes.ADMIN_VIEW_ALL_SHORT: {
                    return {
                        ...state,
                        adminAllChaptersShort: action.payload.data,
                    };
                }
                case ChapterActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminChapterDetails: action.payload.data,
                    };
                }
                case ChapterActionTypes.ADMIN_UPSERT: {
                    return {
                        ...state,
                        apiUpsertSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }

        case ChapterActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ChapterActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminChapterDetails: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case ChapterActionTypes.ADMIN_VIEW_DETAILS:
            return {
                ...state,
                error: false,
                adminChapterDetails: null,
                apiUpsertSuccess: false,
            };

        case ChapterActionTypes.ADMIN_UPSERT:
            return {
                ...state,
                apiUpsertSuccess: false,
            };

        case ChapterActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                loading: true,
                error: false,
            };

        case ChapterActionTypes.RESET:
            return {
                ...state,
                loading: false,
                apiUpsertSuccess: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default Chapter;

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { ProblemTestNew } from '../../../types/problem/problemTest';

const api = new APICore();

function apiAdminViewTests(problemId: string) {
    return api.get(`${apiBaseUrl}/problems_tests/admin/problem/${problemId}`, null);
}

function apiAdminView(testId: string) {
    return api.get(`${apiBaseUrl}/problems_tests/admin/${testId}`, null);
}

function apiAdminCreate(data: ProblemTestNew) {
    return api.create(`${apiBaseUrl}/problems_tests/admin`, data);
}

function apiAdminUpdate(data: ProblemTestNew) {
    return api.update(`${apiBaseUrl}/problems_tests/admin`, data);
}

function apiAdminDelete(testId: string) {
    return api.delete(`${apiBaseUrl}/problems_tests/admin/${testId}`);
}

export { apiAdminViewTests, apiAdminView, apiAdminDelete, apiAdminCreate, apiAdminUpdate };

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiAdminViewAll, apiUserViewDetails, apiUserViewAll, apiUserInvoiceCreate, apiUserCheckStatus } from '../../../helpers/api/shop/invoice';
import { InvoiceNew, InvoiceSummary } from '../../../types/shop/invoice';
import { invoiceApiResponseError, invoiceApiResponseSuccess } from './actions';
import { ShopInvoiceActionTypes } from './constants';

type AdminInvoicePayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

type UserInvoiceCheckStatusPayloadType = {
    payload: {
        uniqueId: string;
    };
    type: string;
};

type UserInvoicePayloadType = {
    payload: InvoiceNew;
    type: string;
};

function* adminViewAll(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll);
        const responseData: InvoiceSummary[] = response.data.map((invoice: InvoiceSummary) => {
            return Object.assign(invoice, {
                id_short: invoice.id.substring(0, 8),
            });
        });
        yield put(invoiceApiResponseSuccess(ShopInvoiceActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(invoiceApiResponseError(ShopInvoiceActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* userCheckStatus({ payload: { uniqueId } }: UserInvoiceCheckStatusPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserCheckStatus, uniqueId);
        yield put(invoiceApiResponseSuccess(ShopInvoiceActionTypes.USER_CHECK_STATUS, response.data));
    } catch (error: any) {
        yield put(invoiceApiResponseError(ShopInvoiceActionTypes.USER_CHECK_STATUS, error));
    }
}
function* userViewDetails({ payload: { id } }: AdminInvoicePayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewDetails, id);
        yield put(invoiceApiResponseSuccess(ShopInvoiceActionTypes.USER_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(invoiceApiResponseError(ShopInvoiceActionTypes.USER_VIEW_DETAILS, error));
    }
}

function* userInvoiceCreate({ payload }: UserInvoicePayloadType): SagaIterator {
    try {
        const response = yield call(apiUserInvoiceCreate, payload);
        yield put(invoiceApiResponseSuccess(ShopInvoiceActionTypes.USER_SEND_INVOICE, response.data));
    } catch (error: any) {
        yield put(invoiceApiResponseError(ShopInvoiceActionTypes.USER_SEND_INVOICE, error));
    }
}

function* userViewAll(): SagaIterator {
    try {
        const response = yield call(apiUserViewAll);
        yield put(invoiceApiResponseSuccess(ShopInvoiceActionTypes.USER_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(invoiceApiResponseError(ShopInvoiceActionTypes.USER_VIEW_ALL, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(ShopInvoiceActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchUserViewDetails() {
    yield takeEvery(ShopInvoiceActionTypes.USER_VIEW_DETAILS, userViewDetails);
}

export function* watchUserViewAll() {
    yield takeEvery(ShopInvoiceActionTypes.USER_VIEW_ALL, userViewAll);
}

export function* watchUserInvoiceCreate() {
    yield takeEvery(ShopInvoiceActionTypes.USER_SEND_INVOICE, userInvoiceCreate);
}

export function* watchUserCheckStatus() {
    yield takeEvery(ShopInvoiceActionTypes.USER_CHECK_STATUS, userCheckStatus);
}

function* ShopBundleSaga() {
    yield all([fork(watchAdminViewAll), fork(watchUserViewDetails), fork(watchUserViewAll), fork(watchUserInvoiceCreate), fork(watchUserCheckStatus)]);
}

export default ShopBundleSaga;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { TimespentActionTypes } from './constants';
import { apiAdminViewAll, apiUserSend } from '../../../../helpers/api/user/logs/timespent';
import { TimespentDetails, TimespentNew } from '../../../../types/user/logs/timespent';
import { timespentApiResponseError, timespentApiResponseSuccess } from './actions';

type AdminAllPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

type UserTimespentPayloadType = {
    payload: TimespentNew;
    type: string;
};

function* adminViewAll({ payload: { page, size } }: AdminAllPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll, page, size);
        yield put(timespentApiResponseSuccess(TimespentActionTypes.ADMIN_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(timespentApiResponseError(TimespentActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* userCreate({ payload }: UserTimespentPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserSend, payload);
        yield put(timespentApiResponseSuccess(TimespentActionTypes.USER_CREATE, response.data));
    } catch (error: any) {
        yield put(timespentApiResponseError(TimespentActionTypes.USER_CREATE, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(TimespentActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchUserCreate() {
    yield takeEvery(TimespentActionTypes.USER_CREATE, userCreate);
}

function* timespentLogsSaga() {
    yield all([fork(watchAdminViewAll), fork(watchUserCreate)]);
}

export default timespentLogsSaga;

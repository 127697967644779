import React, { Suspense, useCallback, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { changeSidebarType } from 'redux/actions';
import { useRedux, useToggle, useUser, useViewport } from 'hooks';
import { changeBodyAttribute } from 'utils';
import * as layoutConstants from '../constants';
import useThemeCustomizer from '../components/old/ThemeCustomizer/useThemeCustomizer';
import { Widget, addResponseMessage, addUserMessage, dropMessages, toggleInputDisabled, toggleMsgLoader } from 'react-chat-widget';
import { AiChatMessage, AiRoles } from '../types/ai/aiChat';
import useAiMessage from '../hooks/ai/useAiMessage';
import { socket } from '../pages/user/chat/hooks/socket';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import CookieBar from '../components/organisms/CookieBar/CookieBar';

const Topbar = React.lazy(() => import('./Topbar'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const Footer = React.lazy(() => import('./Footer'));
const RightSidebar = React.lazy(() => import('./RightSidebar'));

const loading = () => <div className=''></div>;

const VerticalLayout = () => {
    const { dispatch, appSelector } = useRedux();
    const { width } = useViewport();
    const [isMenuOpened, toggleMenu] = useToggle();
    const { changeLayoutColorScheme } = useThemeCustomizer();
    const { userInfo, isUserAuthenticated } = useUser();

    const { layoutColor, leftSideBarTheme, leftSideBarType, layoutWidth } = appSelector((state) => ({
        layoutColor: state.Layout.layoutColor,
        layoutWidth: state.Layout.layoutWidth,
        leftSideBarTheme: state.Layout.leftSideBarTheme,
        leftSideBarType: state.Layout.leftSideBarType,
    }));

    /*
     * layout defaults
     */
    useEffect(() => {
        changeBodyAttribute('data-layout', layoutConstants.LayoutTypes.LAYOUT_VERTICAL);
    }, []);

    useEffect(() => {
        if (layoutColor === layoutConstants.LayoutColor.LAYOUT_COLOR_UNKNOWN && userInfo) {
            const userLayoutColor = userInfo?.darkMode ? layoutConstants.LayoutColor.LAYOUT_COLOR_DARK : layoutConstants.LayoutColor.LAYOUT_COLOR_LIGHT;
            changeLayoutColorScheme(userLayoutColor);
            changeBodyAttribute('data-layout-color', userLayoutColor);
        } else if (layoutColor === layoutConstants.LayoutColor.LAYOUT_COLOR_UNKNOWN && !isUserAuthenticated) {
            changeLayoutColorScheme(layoutConstants.LayoutColor.LAYOUT_COLOR_LIGHT);
            changeBodyAttribute('data-layout-color', layoutConstants.LayoutColor.LAYOUT_COLOR_LIGHT);
        } else {
            changeBodyAttribute('data-layout-color', layoutColor);
        }
    }, [layoutColor, userInfo, isUserAuthenticated]);

    useEffect(() => {
        changeBodyAttribute('data-layout-mode', layoutWidth);
    }, [layoutWidth]);

    useEffect(() => {
        changeBodyAttribute('data-leftbar-theme', leftSideBarTheme);
    }, [leftSideBarTheme]);

    useEffect(() => {
        changeBodyAttribute('data-leftbar-compact-mode', leftSideBarType);
    }, [leftSideBarType]);

    useEffect(() => {
        socket.on('newNotification', (data) => {
            toast.success(data.title, {
                toastId: data.id,
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'colored',
                icon: '🔔',
            });
        });
        socket.on('globalMessageSocket', (data) => {
            toast.error(data.message, {
                position: 'top-right',
                autoClose: 30000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'colored',
                icon: '🚨',
            });
        });
    }, []);

    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        toggleMenu();

        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove('sidebar-enable');
            } else {
                document.body.classList.add('sidebar-enable');
            }
        }
    };

    const updateDimensions = useCallback(() => {
        // activate the condensed sidebar if smaller devices like ipad or tablet
        if (width > 768 && width <= 1028) {
            dispatch(changeSidebarType(layoutConstants.SideBarWidth.LEFT_SIDEBAR_TYPE_CONDENSED));
        } else if (width > 1028) {
            dispatch(changeSidebarType(layoutConstants.SideBarWidth.LEFT_SIDEBAR_TYPE_FIXED));
        }
    }, [dispatch, width]);

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, [dispatch, updateDimensions]);

    const isCondensed = leftSideBarType === layoutConstants.SideBarWidth.LEFT_SIDEBAR_TYPE_CONDENSED;
    const isLight = leftSideBarTheme === layoutConstants.SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT;

    const [messagesSent, setMessagesSent] = React.useState<number>(0);
    const [aiMessages, setAiMessages] = React.useState<AiChatMessage[]>([]);
    const { userSendMessage, apiMessageSuccess, apiAssistantResponse, adminLoadMessageDetails, adminMessageDetails } = useAiMessage();

    useEffect(() => {
        if (apiMessageSuccess) {
            adminLoadMessageDetails(apiAssistantResponse.id);
            const aiResponse: AiChatMessage = { message: apiAssistantResponse.message, type: AiRoles.ASSISTANT, id: 'new' };
            setAiMessages([...aiMessages, aiResponse]);
        }
    }, [apiAssistantResponse]);

    const clearUIMessages = () => {
        if (messagesSent !== 0) {
            dropMessages();
            aiMessages.forEach((message) => {
                if (message.type === AiRoles.USER) {
                    addUserMessage(message.message);
                } else if (message.type === AiRoles.ASSISTANT) {
                    addResponseMessage(message.message);
                }
            });
            toggleInputDisabled();
            toggleMsgLoader();
        }
        setMessagesSent(messagesSent + 1);
    };

    useEffect(() => {
        if (aiMessages.length % 2 === 0) {
            clearUIMessages();
        }
    }, [aiMessages]);

    const handleNewUserMessage = (newMessage: string) => {
        toggleMsgLoader();
        toggleInputDisabled();
        userSendMessage({ content: newMessage });
        const userMessage: AiChatMessage = { message: newMessage, type: AiRoles.USER, id: 'new' };
        setAiMessages([...aiMessages, userMessage]);
    };

    return (
        <>
            <div className='wrapper'>
                <Suspense fallback={loading()}>
                    <LeftSidebar isCondensed={isCondensed} isLight={isLight} hideUserProfile={true} />
                </Suspense>
                <div className='content-page'>
                    <ToastContainer />
                    <div className='container' style={{ paddingBottom: 400 }}>
                        <Suspense fallback={loading()}>
                            <Topbar openLeftMenuCallBack={openMenu} hideLogo={true} />
                        </Suspense>
                        <Container fluid>
                            <Outlet />
                        </Container>
                    </div>

                    <Suspense fallback={loading()}>
                        <Footer />
                    </Suspense>
                </div>
            </div>

            <Widget senderPlaceHolder={'Scrie mesajul'} title={'Prof. Tomita'} subtitle={null} handleNewUserMessage={handleNewUserMessage} />

            <CookieBar />

            <Suspense fallback={loading()}>
                <RightSidebar />
            </Suspense>
        </>
    );
};
export default VerticalLayout;

import { StaticSearchActionTypes } from './constants';
import { StaticSearchData } from '../../../types/user/search';

const INIT_STATE = {
    apiSuccess: false,
    loading: false,
    error: null,
    staticSearchData: null,
};

type NotificationActionType = {
    type:
        | StaticSearchActionTypes.API_RESPONSE_ERROR
        | StaticSearchActionTypes.API_RESPONSE_SUCCESS
        | StaticSearchActionTypes.USER_VIEW_ALL
        | StaticSearchActionTypes.RESET;

    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    apiSuccess?: boolean;
    loading?: boolean;
    error?: string | null;
    staticSearchData: StaticSearchData[] | null;
};

const StaticSearch = (state: State = INIT_STATE, action: NotificationActionType) => {
    switch (action.type) {
        case StaticSearchActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case StaticSearchActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        staticSearchData: action.payload.data,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }
        case StaticSearchActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case StaticSearchActionTypes.USER_VIEW_ALL:
                    return {
                        loading: true,
                        apiSuccess: false,
                    };
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default StaticSearch;

export enum FriendshipActionTypes {
    API_RESPONSE_ERROR = '@@friendship/API_RESPONSE_ERROR',
    API_RESPONSE_SUCCESS = '@@friendship/API_RESPONSE_SUCCESS',
    USER_VIEW_LAST = '@@friendship/USER_VIEW_LAST',
    USER_ACCEPT_FRIENDSHIP = '@@friendship/USER_ACCEPT_FRIENDSHIP',
    USER_SEND_REQUEST = '@@friendship/USER_SEND_REQUEST',
    USER_VIEW_ALL = '@@friendship/USER_VIEW_ALL',
    USER_CHECK_FRIENDSHIP = '@@friendship/USER_CHECK_FRIENDSHIP',
    USER_VIEW_ALL_INCOMING = '@@friendship/USER_VIEW_ALL_INCOMING',
    USER_DESTROY_FRIENDSHIP = '@@friendship_USER_DESTROY_FRIENDSHIP',
    USER_COUNT_FRIENDS = '@@friendship/USER_COUNT_FRIENDS',

    RESET = '@@friendship/RESET',
}

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { AiConfigNew } from '../../../types/ai/aiConfig';

const api = new APICore();

function apiAdminViewAll() {
    return api.get(`${apiBaseUrl}/ai/conversations/admin`, null);
}

function apiAdminViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/ai/conversations/admin/${id}`, null);
}

export { apiAdminViewAll, apiAdminViewDetails };

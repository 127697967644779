import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiAdminViewAll, apiAdminUpdate } from '../../../helpers/api/ai/config';
import { AiConfigDetails, AiConfigNew } from '../../../types/ai/aiConfig';
import { AiConfigActionTypes } from './constants';
import { aiConfigApiResponseError, aiConfigApiResponseSuccess } from './actions';

type AdminUpdatePayloadType = {
    payload: AiConfigNew;
    type: string;
};

function* adminViewAll(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll);
        const responseData: AiConfigDetails[] = response.data.map((contact: AiConfigDetails) => {
            return Object.assign(contact, {
                id_short: contact.id.substring(0, 8),
                capped_value: contact.parameterValue.substring(0, 20),
            });
        });
        yield put(aiConfigApiResponseSuccess(AiConfigActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(aiConfigApiResponseError(AiConfigActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminUpdate({ payload }: AdminUpdatePayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminUpdate, payload);
        yield put(aiConfigApiResponseSuccess(AiConfigActionTypes.ADMIN_UPDATE, response.data));
    } catch (error: any) {
        yield put(aiConfigApiResponseError(AiConfigActionTypes.ADMIN_UPDATE, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(AiConfigActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminUpdate() {
    yield takeEvery(AiConfigActionTypes.ADMIN_UPDATE, adminUpdate);
}

function* AiConfigSaga() {
    yield all([fork(watchAdminViewAll), fork(watchAdminUpdate)]);
}

export default AiConfigSaga;

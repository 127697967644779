import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';

const api = new APICore();

function apiUserLoadCountyRanking(county: string) {
    return api.get(`${apiBaseUrl}/ranking/${county}`, null);
}

export { apiUserLoadCountyRanking };

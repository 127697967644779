import { UserRoleDetails } from './user';

export type FriendDetails = {
    id: string;
    details: UserRoleDetails;
    county: string;
};

export enum FriendshipStatus {
    NON_EXISTENT = -1,
    PENDING,
    ACCEPTED,
    REJECTED,
}

export type FriendshipDetails = {
    id: string;
    senderId: string;
    receiverId: string;
    status: number
}

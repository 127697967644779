import { SchoolActionTypes } from './constants';
import { SchoolDetails } from '../../../types/cms/school';

const INIT_STATE = {
    loading: false,
    adminAllSchools: null,
    updatedSchool: null,
    deletedSchool: null,
    adminSchoolDetails: null,
};

type SchoolActionType = {
    type:
        | SchoolActionTypes.API_RESPONSE_SUCCESS
        | SchoolActionTypes.ADMIN_UPDATE
        | SchoolActionTypes.ADMIN_CREATE
        | SchoolActionTypes.ADMIN_DELETE
        | SchoolActionTypes.API_RESPONSE_ERROR
        | SchoolActionTypes.USER_VIEW_ALL
        | SchoolActionTypes.ADMIN_VIEW_ALL
        | SchoolActionTypes.ADMIN_VIEW
        | SchoolActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    adminAllSchools: SchoolDetails[] | null;
    adminSchoolDetails: SchoolDetails | null;
    updatedSchool: SchoolDetails | null;
    deletedSchool: SchoolDetails | null;
    loading?: boolean;
};

const School = (state: State = INIT_STATE, action: SchoolActionType) => {
    switch (action.type) {
        case SchoolActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case SchoolActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllSchools: action.payload.data,
                        loading: false,
                    };
                }
                case SchoolActionTypes.ADMIN_VIEW: {
                    return {
                        ...state,
                        adminSchoolDetails: action.payload.data,
                        loading: false,
                    };
                }
                case SchoolActionTypes.ADMIN_CREATE: {
                    return {
                        ...state,
                        updatedSchool: action.payload.data,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                case SchoolActionTypes.ADMIN_UPDATE: {
                    return {
                        ...state,
                        updatedSchool: action.payload.data,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                case SchoolActionTypes.ADMIN_DELETE: {
                    return {
                        ...state,
                        deletedSchool: action.payload.data,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case SchoolActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case SchoolActionTypes.ADMIN_CREATE: {
                    return {
                        ...state,
                        updatedSchool: null,
                        apiSuccess: false,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case SchoolActionTypes.ADMIN_UPDATE: {
                    return {
                        ...state,
                        updatedSchool: null,
                        apiSuccess: false,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case SchoolActionTypes.ADMIN_DELETE: {
                    return {
                        ...state,
                        deletedSchool: null,
                        apiSuccess: false,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case SchoolActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case SchoolActionTypes.ADMIN_VIEW: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
        case SchoolActionTypes.ADMIN_VIEW_ALL: {
            return {
                ...state,
                adminAllSchools: null,
                error: false,
                loading: false,
            };
        }
        case SchoolActionTypes.ADMIN_CREATE: {
            return {
                ...state,
                updatedSchool: null,
                apiSuccess: false,
                loading: false,
            };
        }
        case SchoolActionTypes.ADMIN_UPDATE: {
            return {
                ...state,
                updatedSchool: null,
                apiSuccess: false,
                loading: false,
            };
        }
        case SchoolActionTypes.ADMIN_DELETE: {
            return {
                ...state,
                deletedSchool: null,
                apiSuccess: false,
                loading: false,
            };
        }
        case SchoolActionTypes.ADMIN_VIEW: {
            return {
                ...state,
                adminSchoolDetails: null,
                error: false,
                loading: false,
            };
        }
        case SchoolActionTypes.RESET:
            return {
                ...state,
                loading: false,
                adminAllSchools: null,
                error: false,
            };

        default:
            return { ...state };
    }
};

export default School;

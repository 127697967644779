import { SeoActionTypes } from './constants';
import { SeoDataDetails, SeoDataSummary } from '../../../types/cms/seoData';

const INIT_STATE = {
    apiUpsertSuccess: false,
    adminAllSeo: null,
    adminSeoDetails: null,
    userAllSeo: null,
    userSeoDetails: null,
    loading: false,
};

type SeoActionType = {
    type:
        | SeoActionTypes.API_RESPONSE_SUCCESS
        | SeoActionTypes.API_RESPONSE_ERROR
        | SeoActionTypes.ADMIN_VIEW_ALL
        | SeoActionTypes.ADMIN_VIEW_DETAILS
        | SeoActionTypes.ADMIN_DELETE
        | SeoActionTypes.ADMIN_UPSERT
        | SeoActionTypes.USER_VIEW_DETAILS
        | SeoActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: SeoDataSummary | SeoDataDetails | {};
        error?: string;
    };
};

type State = {
    apiUpsertSuccess: boolean;
    adminAllSeo?: SeoDataSummary[] | null;
    adminSeoDetails?: SeoDataDetails | null;
    userSeoDetails?: SeoDataDetails | null;
    loading?: boolean;
};

const SeoData = (state: State = INIT_STATE, action: SeoActionType) => {
    switch (action.type) {
        case SeoActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case SeoActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllSeo: action.payload.data,
                        loading: false,
                    };
                }
                case SeoActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminSeoDetails: action.payload.data,
                    };
                }
                case SeoActionTypes.USER_VIEW_DETAILS: {
                    return {
                        ...state,
                        userSeoDetails: action.payload.data,
                    };
                }
                case SeoActionTypes.ADMIN_UPSERT: {
                    return {
                        ...state,
                        apiUpsertSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }

        case SeoActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case SeoActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminSeoDetails: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case SeoActionTypes.ADMIN_VIEW_DETAILS:
            return {
                ...state,
                error: false,
                adminSeoDetails: null,
                apiUpsertSuccess: false,
            };

        case SeoActionTypes.ADMIN_UPSERT:
            return {
                ...state,
                apiUpsertSuccess: false,
            };

        case SeoActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                loading: true,
                error: false,
                adminAllSeo: null,
            };

        case SeoActionTypes.USER_VIEW_DETAILS:
            return {
                ...state,
                loading: true,
                error: false,
                userSeoDetails: null,
            };

        case SeoActionTypes.RESET:
            return {
                ...state,
                loading: false,
                apiUpsertSuccess: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default SeoData;

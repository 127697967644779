import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiAdminCreate, apiAdminUpdate, apiAdminViewAll, apiAdminViewDetails, apiUserViewDetails } from '../../../helpers/api/cms/seoData';
import { PaginatedSeoSummary, SeoDataFilter, SeoDataNew, SeoDataSummary, SeoEntityType } from '../../../types/cms/seoData';
import { seoApiResponseError, seoApiResponseSuccess } from './actions';
import { SeoActionTypes } from './constants';

type SeoDataDetailsPayloadType = {
    payload: {
        type: SeoEntityType;
        slug: string;
    };
    type: string;
};

type AdminSeoAllPaginatedPayloadType = {
    payload: {
        filters: SeoDataFilter;
    };
    type: string;
};

type AdminSeoDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

type AdminSeoUpsertPayloadType = {
    payload: SeoDataNew;
    type: string;
};

function* adminViewAll({ payload: { filters } }: AdminSeoAllPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll, filters);
        const responseItems: SeoDataSummary[] = response.data.items.map((seo: SeoDataSummary) => {
            return Object.assign(seo, {
                id_short: seo.id.substring(0, 8),
            });
        });
        const responseData: PaginatedSeoSummary = {
            items: responseItems,
            total: response.data.total,
        };

        yield put(seoApiResponseSuccess(SeoActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(seoApiResponseError(SeoActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewDetails({ payload: { id } }: AdminSeoDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(seoApiResponseSuccess(SeoActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(seoApiResponseError(SeoActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

function* adminUpsert({ payload }: AdminSeoUpsertPayloadType): SagaIterator {
    try {
        const response = yield call(payload.id ? apiAdminUpdate : apiAdminCreate, payload);
        yield put(seoApiResponseSuccess(SeoActionTypes.ADMIN_UPSERT, response.data));
    } catch (error: any) {
        yield put(seoApiResponseError(SeoActionTypes.ADMIN_UPSERT, error));
    }
}

function* userViewDetails({ payload: { type, slug } }: SeoDataDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewDetails, type, slug);
        yield put(seoApiResponseSuccess(SeoActionTypes.USER_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(seoApiResponseError(SeoActionTypes.USER_VIEW_DETAILS, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(SeoActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewDetails() {
    yield takeEvery(SeoActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

export function* watchAdminUpsert() {
    yield takeEvery(SeoActionTypes.ADMIN_UPSERT, adminUpsert);
}

export function* watchUserViewDetails() {
    yield takeEvery(SeoActionTypes.USER_VIEW_DETAILS, userViewDetails);
}

function* SeoDataSaga() {
    yield all([fork(watchAdminViewAll), fork(watchAdminViewDetails), fork(watchAdminUpsert), fork(watchUserViewDetails)]);
}

export default SeoDataSaga;

export enum AiMessageActionTypes {
    API_RESPONSE_SUCCESS = '@@aiMessage/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@aiMessage/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL = '@@aiMessage/ADMIN_VIEW_ALL',
    ADMIN_VIEW_DETAILS = '@@aiMessage/ADMIN_VIEW_DETAILS',

    USER_CREATE = '@@aiMessage/USER_CREATE',

    RESET = '@@aiMessage/RESET',
}

import { SeoActionTypes } from './constants';
import { SeoDataDetails, SeoDataFilter, SeoDataNew, SeoEntityType } from '../../../types/cms/seoData';

export type SeoActionType = {
    type:
        | SeoActionTypes.API_RESPONSE_SUCCESS
        | SeoActionTypes.API_RESPONSE_ERROR
        | SeoActionTypes.ADMIN_VIEW_ALL
        | SeoActionTypes.ADMIN_VIEW_DETAILS
        | SeoActionTypes.ADMIN_DELETE
        | SeoActionTypes.ADMIN_UPSERT
        | SeoActionTypes.USER_VIEW_DETAILS
        | SeoActionTypes.RESET;
    payload: {} | string;
};

export const seoApiResponseSuccess = (actionType: string, data: SeoDataNew | SeoDataDetails | {}): SeoActionType => ({
    type: SeoActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const seoApiResponseError = (actionType: string, error: string): SeoActionType => ({
    type: SeoActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const seoAdminViewAll = (filters: SeoDataFilter): SeoActionType => ({
    type: SeoActionTypes.ADMIN_VIEW_ALL,
    payload: { filters },
});

export const seoAdminViewDetails = (id: string): SeoActionType => ({
    type: SeoActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

export const seoAdminUpsert = (data: SeoDataNew): SeoActionType => ({
    type: SeoActionTypes.ADMIN_UPSERT,
    payload: data,
});

export const seoUserViewDetails = (type: SeoEntityType, slug: string): SeoActionType => ({
    type: SeoActionTypes.USER_VIEW_DETAILS,
    payload: { type, slug },
});

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';

const api = new APICore();

function apiUserLoadProfile(username: string) {
    return api.get(`${apiBaseUrl}/profile/${username}`, null);
}

export { apiUserLoadProfile };

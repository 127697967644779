import { EvaluatorActionTypes } from './constants';
import { CompilerLanguage, compilerLanguages } from '../../../types/problem/compiler';
import { TabItem } from '../../../types/tabItem';

export type EvaluatorTestCase = {
    index: number;
    input: string;
};

const INIT_STATE = {
    language: compilerLanguages[0],
    outTabKey: 'compiler-cases',
    sourceCode: null,
    testCasesContent: ['', '', '', ''],
    testCasesTabs: [],
    testTabKey: 1,
};

type EvaluatorActionType = {
    type:
        | EvaluatorActionTypes.USER_ADD_TEST_CASE
        | EvaluatorActionTypes.USER_CHANGE_OUT_TAB
        | EvaluatorActionTypes.USER_CHANGE_TEST_CASE
        | EvaluatorActionTypes.USER_UPDATE_LANGUAGE
        | EvaluatorActionTypes.USER_UPDATE_SOURCE_CODE
        | EvaluatorActionTypes.USER_UPDATE_TEST_CASE
        | EvaluatorActionTypes.USER_REMOVE_TEST_CASE
        | EvaluatorActionTypes.USER_SET_FIRST_TEST_CASE;
    payload: {
        actionType?: string;
        data?: string | TabItem | {};
        dataIndex?: number;
        error?: string;
    };
};

type State = {
    language: CompilerLanguage | null;
    outTabKey: string;
    sourceCode: string | null;
    testCasesContent: string[];
    testCasesTabs: TabItem[];
    testTabKey: number;
};

const Evaluator = (state: State = INIT_STATE, action: EvaluatorActionType) => {
    switch (action.type) {
        case EvaluatorActionTypes.USER_ADD_TEST_CASE:
            return {
                ...state,
                testCasesTabs: [...state.testCasesTabs, action.payload.data],
            };

        case EvaluatorActionTypes.USER_REMOVE_TEST_CASE:
            const lastTab = state.testCasesTabs.length;
            state.testCasesContent[lastTab - 1] = '';
            return {
                ...state,
                testCasesTabs: state.testCasesTabs.slice(0, -1),
                testCasesContent: state.testCasesContent,
                testTabKey: lastTab === state.testTabKey ? lastTab - 1 : state.testTabKey,
            };

        case EvaluatorActionTypes.USER_UPDATE_TEST_CASE:
            return {
                ...state,
                testCasesContent: state.testCasesContent.map((item, index) => {
                    if (index === action.payload.dataIndex) {
                        return action.payload.data!;
                    }
                    return item;
                }),
            };

        case EvaluatorActionTypes.USER_SET_FIRST_TEST_CASE:
            return {
                ...state,
                testCasesTabs: [action.payload.data],
            };

        case EvaluatorActionTypes.USER_CHANGE_OUT_TAB:
            return {
                ...state,
                outTabKey: action.payload.data,
            };

        case EvaluatorActionTypes.USER_CHANGE_TEST_CASE:
            return {
                ...state,
                testTabKey: action.payload.data,
            };

        case EvaluatorActionTypes.USER_UPDATE_LANGUAGE:
            return {
                ...state,
                language: action.payload.data,
            };

        case EvaluatorActionTypes.USER_UPDATE_SOURCE_CODE:
            return {
                ...state,
                sourceCode: action.payload.data,
            };

        default:
            return { ...state };
    }
};

export default Evaluator;

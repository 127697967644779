import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { StaticSearchActionTypes } from './constants';
import { apiUserViewAll } from '../../../helpers/api/user/search';
import { staticSearchApiResponseError, staticSearchApiResponseSuccess } from './actions';

function* userViewAll(): SagaIterator {
    try {
        let response = yield call(apiUserViewAll);
        yield put(staticSearchApiResponseSuccess(StaticSearchActionTypes.USER_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(staticSearchApiResponseError(StaticSearchActionTypes.USER_VIEW_ALL, error));
    }
}

export function* watchUserViewAll() {
    yield takeEvery(StaticSearchActionTypes.USER_VIEW_ALL, userViewAll);
}

function* energyLogsSaga() {
    yield all([fork(watchUserViewAll)]);
}

export default energyLogsSaga;

export enum ProblemActionTypes {
    API_RESPONSE_SUCCESS = '@@problem/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@problem/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL = '@@problem/ADMIN_VIEW_ALL',
    ADMIN_VIEW_ALL_SHORT = '@@problem/ADMIN_VIEW_ALL_SHORT',
    ADMIN_VIEW_DETAILS = '@@problem/ADMIN_VIEW_DETAILS',
    ADMIN_DELETE = '@@problem/ADMIN_DELETE',
    ADMIN_UPSERT = '@@problem/ADMIN_UPSERT',

    USER_VIEW_SHORT = '@@problem/USER_VIEW_SHORT',
    USER_VIEW_ALL = '@@problem/USER_VIEW_ALL',
    USER_VIEW_DETAILS = '@@problem/USER_VIEW_DETAILS',
    USER_VIEW_FOR_LECTURE = '@@problem/USER_VIEW_FOR_LECTURE',

    USER_RUN = '@@problem/USER_RUN',
    USER_SUBMIT = '@@problem/USER_SUBMIT',

    RESET = '@@problem/RESET',
}

import { CompilerActionTypes } from './constants';
import { AdminLogsRequest, AdminTestProblemRequest, AdminTestSourceRequest, InternalCompilerLanguage } from '../../../types/problem/compiler';

export type CompilerActionType = {
    type:
        | CompilerActionTypes.ADMIN_TEST_PROBLEM
        | CompilerActionTypes.ADMIN_TEST_SOURCE
        | CompilerActionTypes.ADMIN_VIEW_LANGUAGES
        | CompilerActionTypes.ADMIN_VIEW_LOGS
        | CompilerActionTypes.API_RESPONSE_ERROR
        | CompilerActionTypes.API_RESPONSE_SUCCESS;
    payload: {} | string;
};

export const compilerApiResponseSuccess = (actionType: string, data: InternalCompilerLanguage[] | {}): CompilerActionType => ({
    type: CompilerActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const compilerApiResponseError = (actionType: string, error: string): CompilerActionType => ({
    type: CompilerActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const compilerAdminViewLanguages = (): CompilerActionType => ({
    type: CompilerActionTypes.ADMIN_VIEW_LANGUAGES,
    payload: {},
});

export const compilerAdminTestSource = (data: AdminTestSourceRequest): CompilerActionType => ({
    type: CompilerActionTypes.ADMIN_TEST_SOURCE,
    payload: data,
});

export const compilerAdminTestProblem = (data: AdminTestProblemRequest): CompilerActionType => ({
    type: CompilerActionTypes.ADMIN_TEST_PROBLEM,
    payload: data,
});

export const compilerAdminGetLogs = (data: AdminLogsRequest): CompilerActionType => ({
    type: CompilerActionTypes.ADMIN_VIEW_LOGS,
    payload: data,
});

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { AiConfigNew } from '../../../types/ai/aiConfig';

const api = new APICore();

function apiAdminViewAll() {
    return api.get(`${apiBaseUrl}/ai/configs/admin`, null);
}

function apiAdminUpdate(data: AiConfigNew) {
    return api.update(`${apiBaseUrl}/ai/configs/admin`, data);
}

export { apiAdminViewAll, apiAdminUpdate };

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiAdminViewAll, apiAdminViewDetails } from '../../../helpers/api/log/login';
import { loginLogApiResponseError, loginLogApiResponseSuccess } from './actions';
import { LoginLogActionTypes } from './constants';
import { LoginLogDetails, PaginatedLogSummary } from '../../../types/log/login';

type AdminLogAllPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

type AdminLogDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

function* adminViewAll({ payload: { page, size } }: AdminLogAllPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll, page, size);
        const responseItems: LoginLogDetails[] = response.data.items.map((log: LoginLogDetails) => {
            return Object.assign(log, {
                id_short: log.id.substring(0, 8),
            });
        });
        const responseData: PaginatedLogSummary = {
            items: responseItems,
            total: response.data.total,
        };

        yield put(loginLogApiResponseSuccess(LoginLogActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(loginLogApiResponseError(LoginLogActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewDetails({ payload: { id } }: AdminLogDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(loginLogApiResponseSuccess(LoginLogActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(loginLogApiResponseError(LoginLogActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(LoginLogActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewDetails() {
    yield takeEvery(LoginLogActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

function* LoginLogSaga() {
    yield all([fork(watchAdminViewAll), fork(watchAdminViewDetails)]);
}

export default LoginLogSaga;

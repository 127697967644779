import { LectureActionTypes } from './constants';
import { LectureDetails, LectureDetailsUser, LectureSelectableShort, LectureSummary, LectureUserRecap, PaginatedLectureSummary } from '../../../types/theory/lecture';

const INIT_STATE = {
    apiUpsertSuccess: false,
    adminAllLectures: null,
    adminLectureDetails: null,
    userLectureDetails: null,
    userLectureRecap: null,
    loading: false,
};

type LectureActionType = {
    type:
        | LectureActionTypes.API_RESPONSE_SUCCESS
        | LectureActionTypes.API_RESPONSE_ERROR
        | LectureActionTypes.ADMIN_VIEW_ALL
        | LectureActionTypes.ADMIN_VIEW_ALL_SHORT
        | LectureActionTypes.ADMIN_VIEW_DETAILS
        | LectureActionTypes.ADMIN_DELETE
        | LectureActionTypes.ADMIN_UPSERT
        | LectureActionTypes.USER_VIEW_DETAILS
        | LectureActionTypes.USER_VIEW_RECAP
        | LectureActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: LectureSummary | LectureDetails | LectureDetailsUser | LectureUserRecap | {};
        error?: string;
    };
};

type State = {
    apiUpsertSuccess: boolean;
    adminAllLectures?: PaginatedLectureSummary | null;
    adminAllLecturesShort?: LectureSelectableShort[] | null;
    adminLectureDetails?: LectureDetails | null;
    userLectureDetails?: LectureDetailsUser | null;
    userLectureRecap: LectureUserRecap | null;
    loading?: boolean;
};

const Lecture = (state: State = INIT_STATE, action: LectureActionType) => {
    switch (action.type) {
        case LectureActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case LectureActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllLectures: action.payload.data,
                        loading: false,
                    };
                }
                case LectureActionTypes.ADMIN_VIEW_ALL_SHORT: {
                    return {
                        ...state,
                        adminAllLecturesShort: action.payload.data,
                        loading: false,
                    };
                }
                case LectureActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminLectureDetails: action.payload.data,
                    };
                }
                case LectureActionTypes.ADMIN_UPSERT: {
                    return {
                        ...state,
                        apiUpsertSuccess: true,
                    };
                }
                case LectureActionTypes.USER_VIEW_DETAILS: {
                    return {
                        ...state,
                        userLectureDetails: action.payload.data,
                    };
                }
                case LectureActionTypes.USER_VIEW_RECAP: {
                    return {
                        ...state,
                        userLectureRecap: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case LectureActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case LectureActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminLectureDetails: null,
                        error: action.payload.error,
                    };
                }
                case LectureActionTypes.USER_VIEW_DETAILS: {
                    return {
                        ...state,
                        userLectureDetails: null,
                        error: action.payload.error,
                    };
                }
                case LectureActionTypes.USER_VIEW_RECAP: {
                    return {
                        ...state,
                        userLectureRecap: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case LectureActionTypes.ADMIN_VIEW_DETAILS:
            return {
                ...state,
                error: false,
                adminLectureDetails: null,
                apiUpsertSuccess: false,
            };

        case LectureActionTypes.ADMIN_UPSERT:
            return {
                ...state,
                apiUpsertSuccess: false,
            };

        case LectureActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                loading: true,
                error: false,
            };

        case LectureActionTypes.USER_VIEW_DETAILS:
            return {
                ...state,
                userLectureDetails: null,
                loading: true,
                error: false,
            };

        case LectureActionTypes.USER_VIEW_RECAP:
            return {
                ...state,
                userLectureRecap: null,
                loading: true,
                error: false,
            };

        case LectureActionTypes.RESET:
            return {
                ...state,
                loading: false,
                userLectureDetails: null,
                apiUpsertSuccess: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default Lecture;

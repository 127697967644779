import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';

const api = new APICore();

function apiAdminViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/logs/security/admin/${id}`, null);
}

function apiAdminViewAll(page: number, size: number) {
    return api.get(`${apiBaseUrl}/logs/security/admin/page/${page}/${size}`, null);
}

export { apiAdminViewAll, apiAdminViewDetails };

export enum SchoolActionTypes {
    API_RESPONSE_SUCCESS = '@@school/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@school/API_RESPONSE_ERROR',

    USER_VIEW_ALL = '@@school/USER_GET_ALL',

    ADMIN_VIEW_ALL = '@@school/ADMIN_VIEW_ALL',
    ADMIN_VIEW = '@@school/ADMIN_VIEW',
    ADMIN_CREATE = '@@school/ADMIN_CREATE',
    ADMIN_UPDATE = '@@school/ADMIN_UPDATE',
    ADMIN_DELETE = '@@school/ADMIN_DELETE',

    RESET = '@@school/RESET',
}

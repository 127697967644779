import { LoginLogActionTypes } from './constants';
import { LoginLogDetails } from '../../../types/log/login';

export type LoginLogActionType = {
    type: LoginLogActionTypes.API_RESPONSE_SUCCESS | LoginLogActionTypes.API_RESPONSE_ERROR | LoginLogActionTypes.ADMIN_VIEW_ALL | LoginLogActionTypes.ADMIN_VIEW_DETAILS;
    payload: {} | string;
};

export const loginLogApiResponseSuccess = (actionType: string, data: LoginLogDetails | {}): LoginLogActionType => ({
    type: LoginLogActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const loginLogApiResponseError = (actionType: string, error: string): LoginLogActionType => ({
    type: LoginLogActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const loginLogAdminViewAll = (page: number, size: number): LoginLogActionType => ({
    type: LoginLogActionTypes.ADMIN_VIEW_ALL,
    payload: { page, size },
});

export const loginLogAdminViewDetails = (id: string): LoginLogActionType => ({
    type: LoginLogActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

import { SecurityLogActionTypes } from './constants';
import { SecurityLogDetails, SecurityLogSummary } from '../../../types/log/security';

const INIT_STATE = {
    apiUpsertSuccess: false,
    adminAllSecurityLogs: null,
    adminSecurityLogDetails: null,
    loading: false,
};

type SecurityLogActionType = {
    type: SecurityLogActionTypes.API_RESPONSE_SUCCESS | SecurityLogActionTypes.API_RESPONSE_ERROR | SecurityLogActionTypes.ADMIN_VIEW_ALL | SecurityLogActionTypes.ADMIN_VIEW_DETAILS;
    payload: {
        actionType?: string;
        data?: SecurityLogSummary[] | SecurityLogDetails | {};
        error?: string;
    };
};

type State = {
    apiUpsertSuccess: boolean;
    adminAllSecurityLogs?: SecurityLogSummary[] | null;
    adminSecurityLogDetails?: SecurityLogDetails | null;
    loading?: boolean;
};

const SecurityLog = (state: State = INIT_STATE, action: SecurityLogActionType) => {
    switch (action.type) {
        case SecurityLogActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case SecurityLogActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllSecurityLogs: action.payload.data,
                        loading: false,
                    };
                }
                case SecurityLogActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminSecurityLogDetails: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case SecurityLogActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case SecurityLogActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminSecurityLogDetails: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case SecurityLogActionTypes.ADMIN_VIEW_DETAILS:
            return {
                ...state,
                error: false,
                adminSecurityLogDetails: null,
                apiUpsertSuccess: false,
            };

        case SecurityLogActionTypes.ADMIN_VIEW_ALL:
            return {
                ...state,
                adminAllSecurityLogs: null,
                loading: true,
                error: false,
            };

        default:
            return { ...state };
    }
};

export default SecurityLog;

import { FriendshipActionTypes } from './constants';

export type FriendshipActionType = {
    type:
        | FriendshipActionTypes.USER_VIEW_LAST
        | FriendshipActionTypes.USER_CHECK_FRIENDSHIP
        | FriendshipActionTypes.USER_VIEW_ALL
        | FriendshipActionTypes.USER_VIEW_ALL_INCOMING
        | FriendshipActionTypes.USER_DESTROY_FRIENDSHIP
        | FriendshipActionTypes.USER_SEND_REQUEST
        | FriendshipActionTypes.USER_ACCEPT_FRIENDSHIP
        | FriendshipActionTypes.USER_COUNT_FRIENDS
        | FriendshipActionTypes.API_RESPONSE_ERROR
        | FriendshipActionTypes.API_RESPONSE_SUCCESS
        | FriendshipActionTypes.RESET;
    payload: {} | string;
};
export const friendsUserViewAll = (username: string): FriendshipActionType => ({
    type: FriendshipActionTypes.USER_VIEW_ALL,
    payload: { username },
});

export const friendshipUserDestroy = (friendshipId: string): FriendshipActionType => ({
    type: FriendshipActionTypes.USER_DESTROY_FRIENDSHIP,
    payload: { friendshipId },
});

export const friendshipUserLoadIncoming = (): FriendshipActionType => ({
    type: FriendshipActionTypes.USER_VIEW_ALL_INCOMING,
    payload: {},
});

export const friendsUserViewLast = (username: string): FriendshipActionType => ({
    type: FriendshipActionTypes.USER_VIEW_LAST,
    payload: { username },
});

export const friendsUserCount = (username: string): FriendshipActionType => ({
    type: FriendshipActionTypes.USER_COUNT_FRIENDS,
    payload: { username },
});

export const friendshipUserSend = (username: string): FriendshipActionType => ({
    type: FriendshipActionTypes.USER_SEND_REQUEST,
    payload: { username },
});

export const friendshipUserCheck = (username: string): FriendshipActionType => ({
    type: FriendshipActionTypes.USER_CHECK_FRIENDSHIP,
    payload: { username },
});

export const friendshipUserAccept = (friendshipId: string): FriendshipActionType => ({
    type: FriendshipActionTypes.USER_ACCEPT_FRIENDSHIP,
    payload: { friendshipId },
});

export const friendshipApiResponseSuccess = (actionType: string, data: {}): FriendshipActionType => ({
    type: FriendshipActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const friendshipApiResponseError = (actionType: string, error: string): FriendshipActionType => ({
    type: FriendshipActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { ProblemHintNew } from '../../../types/problem/problemHint';
import { apiAdminView, apiAdminViewHints, apiAdminUpdate, apiAdminDelete, apiAdminCreate, apiUserView, apiUserViewForProblem } from '../../../helpers/api/problem/problemHint';
import { prbHintApiResponseError, prbHintApiResponseSuccess } from './actions';
import { ProblemHintActionTypes } from './constants';

type AdminProblemHintsPayloadType = {
    payload: { problemId: string };
    type: string;
};

type AdminProblemHintPayloadType = {
    payload: { hintId: string };
    type: string;
};

type AdminProblemHintNewPayloadType = {
    payload: ProblemHintNew;
    type: string;
};

function* adminViewAllHints({ payload: { problemId } }: AdminProblemHintsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewHints, problemId);
        yield put(prbHintApiResponseSuccess(ProblemHintActionTypes.ADMIN_VIEW_ALL_PROBLEM, response.data));
    } catch (error: any) {
        yield put(prbHintApiResponseError(ProblemHintActionTypes.ADMIN_VIEW_ALL_PROBLEM, error));
    }
}

function* adminViewHint({ payload: { hintId } }: AdminProblemHintPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminView, hintId);
        yield put(prbHintApiResponseSuccess(ProblemHintActionTypes.ADMIN_VIEW, response.data));
    } catch (error: any) {
        yield put(prbHintApiResponseError(ProblemHintActionTypes.ADMIN_VIEW, error));
    }
}

function* adminUpsert({ payload }: AdminProblemHintNewPayloadType): SagaIterator {
    try {
        const response = yield call(payload.id ? apiAdminUpdate : apiAdminCreate, payload);
        yield put(prbHintApiResponseSuccess(ProblemHintActionTypes.ADMIN_UPSERT, response.data));
    } catch (error: any) {
        yield put(prbHintApiResponseError(ProblemHintActionTypes.ADMIN_UPSERT, error));
    }
}

function* adminDelete({ payload: { hintId } }: AdminProblemHintPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminDelete, hintId);
        yield put(prbHintApiResponseSuccess(ProblemHintActionTypes.ADMIN_DELETE, response.data));
    } catch (error: any) {
        yield put(prbHintApiResponseError(ProblemHintActionTypes.ADMIN_DELETE, error));
    }
}

function* userViewAllHints({ payload: { problemId } }: AdminProblemHintsPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewForProblem, problemId);
        yield put(prbHintApiResponseSuccess(ProblemHintActionTypes.USER_VIEW_ALL_PROBLEM, response.data));
    } catch (error: any) {
        yield put(prbHintApiResponseError(ProblemHintActionTypes.USER_VIEW_ALL_PROBLEM, error));
    }
}

function* userViewHint({ payload: { hintId } }: AdminProblemHintPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserView, hintId);
        yield put(prbHintApiResponseSuccess(ProblemHintActionTypes.USER_VIEW, response.data));
    } catch (error: any) {
        yield put(prbHintApiResponseError(ProblemHintActionTypes.USER_VIEW, error));
    }
}

export function* watchAdminViewAllHints() {
    yield takeEvery(ProblemHintActionTypes.ADMIN_VIEW_ALL_PROBLEM, adminViewAllHints);
}

export function* watchAdminViewHint() {
    yield takeEvery(ProblemHintActionTypes.ADMIN_VIEW, adminViewHint);
}

export function* watchAdminUpsert() {
    yield takeEvery(ProblemHintActionTypes.ADMIN_UPSERT, adminUpsert);
}

export function* watchAdminDelete() {
    yield takeEvery(ProblemHintActionTypes.ADMIN_DELETE, adminDelete);
}

export function* watchUserViewAllHints() {
    yield takeEvery(ProblemHintActionTypes.USER_VIEW_ALL_PROBLEM, userViewAllHints);
}

export function* watchUserViewHint() {
    yield takeEvery(ProblemHintActionTypes.USER_VIEW, userViewHint);
}

function* ProblemHintSaga() {
    yield all([fork(watchAdminViewAllHints), fork(watchAdminViewHint), fork(watchAdminUpsert), fork(watchAdminDelete), fork(watchUserViewAllHints), fork(watchUserViewHint)]);
}

export default ProblemHintSaga;

import ReactDOM from 'react-dom';
import './i18n';
import App from './App';
import ReactGA from 'react-ga4';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from 'redux/store';
import * as Sentry from '@sentry/react';
import { googleTagManagerId, sentryDSN } from './constants';
import TagManager from 'react-gtm-module';

if (sentryDSN !== null) {
    Sentry.init({
        dsn: sentryDSN,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ['localhost:3000', /^https:\/\/api\.platforma-de.info\.ro/],
            }),
            new Sentry.Replay(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactGA.initialize('G-E1319JKQCZ');

TagManager.initialize({
    gtmId: googleTagManagerId,
});

ReactDOM.render(
    <Provider store={configureStore({})}>
        <App />
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

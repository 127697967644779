import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { ModuleNew } from '../../../types/theory/module';

const api = new APICore();

function apiAdminViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/modules/admin/${id}`, null);
}

function apiAdminViewAll() {
    return api.get(`${apiBaseUrl}/modules/admin`, null);
}

function apiAdminViewAllShort() {
    return api.get(`${apiBaseUrl}/modules/admin/short`, null);
}

function apiAdminCreate(data: ModuleNew) {
    return api.create(`${apiBaseUrl}/modules/admin`, data);
}

function apiAdminUpdate(data: ModuleNew) {
    return api.update(`${apiBaseUrl}/modules/admin`, data);
}

function apiUserViewAll() {
    return api.get(`${apiBaseUrl}/modules`, null);
}

function apiUserViewDetails(slug: string) {
    return api.get(`${apiBaseUrl}/modules/${slug}`, null);
}

function apiUserViewChapters(slug: string) {
    return api.get(`${apiBaseUrl}/modules/${slug}/chapters`, null);
}

function apiUserViewProgress(slug: string) {
    return api.get(`${apiBaseUrl}/modules/${slug}/progress`, null);
}

export { apiAdminCreate, apiAdminUpdate, apiUserViewProgress, apiAdminViewAll, apiAdminViewAllShort, apiAdminViewDetails, apiUserViewAll, apiUserViewDetails, apiUserViewChapters };

import { SolutionActionTypes } from './constants';
import { SolutionUserDetails, SolutionUserSummary } from '../../../types/problem/solution';

const INIT_STATE = {
    apiUpsertSuccess: false,
    userAllSolutions: null,
    userSolutionDetails: null,
    userFilteredSolutions: null,
    loading: false,
};

type SolutionActionType = {
    type:
        | SolutionActionTypes.API_RESPONSE_SUCCESS
        | SolutionActionTypes.API_RESPONSE_ERROR
        | SolutionActionTypes.USER_FILTER
        | SolutionActionTypes.USER_VIEW_ALL
        | SolutionActionTypes.USER_VIEW_DETAILS
        | SolutionActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: SolutionUserSummary | SolutionUserDetails | {};
        error?: string;
    };
};

type State = {
    apiUpsertSuccess: boolean;
    userAllSolutions?: SolutionUserSummary[] | null;
    userFilteredSolutions?: SolutionUserSummary[] | null;
    userSolutionDetails?: SolutionUserDetails | null;
    loading?: boolean;
};

const ProblemSolution = (state: State = INIT_STATE, action: SolutionActionType) => {
    switch (action.type) {
        case SolutionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case SolutionActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        userAllSolutions: action.payload.data,
                        loading: false,
                    };
                }
                case SolutionActionTypes.USER_VIEW_DETAILS: {
                    return {
                        ...state,
                        userSolutionDetails: action.payload.data,
                        loading: false,
                    };
                }
                case SolutionActionTypes.USER_FILTER: {
                    return {
                        ...state,
                        userFilteredSolutions: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case SolutionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case SolutionActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        userSolutionDetails: null,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case SolutionActionTypes.USER_VIEW_ALL:
            return {
                ...state,
                error: false,
                loading: true,
                userAllSolutions: null,
            };

        case SolutionActionTypes.USER_VIEW_DETAILS:
            return {
                ...state,
                error: false,
                loading: true,
                userSolutionDetails: null,
            };

        case SolutionActionTypes.USER_FILTER:
            return {
                ...state,
                error: false,
                loading: true,
                userFilteredSolutions: null,
            };

        case SolutionActionTypes.RESET:
            return {
                ...state,
                loading: false,
                apiUpsertSuccess: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default ProblemSolution;

import { TagActionTypes } from './constants';
import { TagDetails } from '../../../types/cms/tag';

const INIT_STATE = {
    loading: false,
    userAllTags: null,
    adminAllTags: null,
    updatedTag: null,
    adminTagDetails: null,
};

type TagActionType = {
    type:
        | TagActionTypes.API_RESPONSE_SUCCESS
        | TagActionTypes.ADMIN_UPDATE
        | TagActionTypes.ADMIN_CREATE
        | TagActionTypes.API_RESPONSE_ERROR
        | TagActionTypes.USER_VIEW_ALL
        | TagActionTypes.ADMIN_VIEW_ALL
        | TagActionTypes.ADMIN_VIEW
        | TagActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    userAllTags?: TagDetails[] | null;
    adminAllTags: TagDetails[] | null;
    adminTagDetails: TagDetails | null;
    updatedTag: TagDetails | null;
    loading?: boolean;
};

const Tag = (state: State = INIT_STATE, action: TagActionType) => {
    switch (action.type) {
        case TagActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TagActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        userAllTags: action.payload.data,
                        loading: false,
                    };
                }
                case TagActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllTags: action.payload.data,
                        loading: false,
                    };
                }
                case TagActionTypes.ADMIN_VIEW: {
                    return {
                        ...state,
                        adminTagDetails: action.payload.data,
                        loading: false,
                    };
                }
                case TagActionTypes.ADMIN_CREATE: {
                    return {
                        ...state,
                        updatedTag: action.payload.data,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                case TagActionTypes.ADMIN_UPDATE: {
                    return {
                        ...state,
                        updatedTag: action.payload.data,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case TagActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TagActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TagActionTypes.ADMIN_CREATE: {
                    return {
                        ...state,
                        updatedTag: null,
                        apiSuccess: false,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TagActionTypes.ADMIN_UPDATE: {
                    return {
                        ...state,
                        updatedTag: null,
                        apiSuccess: false,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TagActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TagActionTypes.ADMIN_VIEW: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
        case TagActionTypes.ADMIN_VIEW_ALL: {
            return {
                ...state,
                adminAllTags: null,
                error: false,
                loading: false,
            };
        }
        case TagActionTypes.ADMIN_CREATE: {
            return {
                ...state,
                updatedTag: null,
                apiSuccess: false,
                loading: false,
            };
        }
        case TagActionTypes.ADMIN_UPDATE: {
            return {
                ...state,
                updatedTag: null,
                apiSuccess: false,
                loading: false,
            };
        }
        case TagActionTypes.ADMIN_VIEW: {
            return {
                ...state,
                adminTagDetails: null,
                error: false,
                loading: false,
            };
        }
        case TagActionTypes.USER_VIEW_ALL:
        case TagActionTypes.RESET:
            return {
                ...state,
                loading: false,
                userAllTags: null,
                error: false,
            };

        default:
            return { ...state };
    }
};

export default Tag;

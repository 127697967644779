import { APICore } from './apiCore';
import { apiBaseUrl } from '../../constants';
import { UserForgotPassword, UserResetPassword } from '../../types/user/user';

const api = new APICore();

function userInfo() {
    const baseUrl = `${apiBaseUrl}/user/info`;
    return api.get(baseUrl, null);
}

function login(params: { user: string; password: string; userOTP: string }) {
    const baseUrl = `${apiBaseUrl}/auth/login`;
    return api.create(baseUrl, params);
}

function logout() {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: { email: string; password: string; captchaToken: string }) {
    return api.create(`${apiBaseUrl}/auth/register`, params);
}

function forgotPassword(data: UserForgotPassword) {
    return api.create(`${apiBaseUrl}/auth/forgot`, data);
}

function resetPasswordApi(data: UserResetPassword) {
    return api.create(`${apiBaseUrl}/auth/reset`, data);
}

function forgotPasswordConfirm(params: { email: string }) {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm, userInfo, resetPasswordApi };

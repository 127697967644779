import { NotificationActionTypes } from './constants';
import { NotificationDetails } from '../../../types/user/notification';

const INIT_STATE = {
    apiSuccess: false,
    loading: false,
    lastUserNotifications: null,
    selectedType: 0,
    userAllNotifications: null,
    setCanSend: true,
    readAllSuccess: false,
    readOneSuccess: false,
};

type NotificationActionType = {
    type:
        | NotificationActionTypes.API_RESPONSE_ERROR
        | NotificationActionTypes.API_RESPONSE_SUCCESS
        | NotificationActionTypes.USER_VIEW_LAST
        | NotificationActionTypes.USER_VIEW_ALL
        | NotificationActionTypes.USER_READ_ALL
        | NotificationActionTypes.USER_READ
        | NotificationActionTypes.RESET;

    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    lastUserNotifications?: NotificationDetails[] | null;
    apiSuccess?: boolean;
    loading?: boolean;
    error?: string | null;
    selectedType: number;
    userAllNotifications?: NotificationDetails[] | null;
    setCanSend: boolean;
    readAllSuccess: boolean;
    readOneSuccess: boolean;
};

const Notification = (state: State = INIT_STATE, action: NotificationActionType) => {
    switch (action.type) {
        case NotificationActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case NotificationActionTypes.USER_VIEW_LAST: {
                    return {
                        ...state,
                        lastUserNotifications: action.payload.data,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                case NotificationActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        userAllNotifications: action.payload.data,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                case NotificationActionTypes.USER_READ_ALL: {
                    return {
                        ...state,
                        loading: false,
                        apiSuccess: true,
                        readAllSuccess: true,
                        setCanSend: false,
                    };
                }
                case NotificationActionTypes.USER_READ: {
                    return {
                        ...state,
                        loading: false,
                        apiSuccess: true,
                        readOneSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }
        case NotificationActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case NotificationActionTypes.USER_VIEW_LAST:
                    return {
                        loading: true,
                        apiSuccess: false,
                    };
                case NotificationActionTypes.USER_VIEW_ALL:
                    return {
                        loading: true,
                        apiSuccess: false,
                    };
                case NotificationActionTypes.USER_READ_ALL: {
                    return {
                        ...state,
                        loading: false,
                        apiSuccess: true,
                        readAllSuccess: false,
                    };
                }
                case NotificationActionTypes.USER_READ: {
                    return {
                        ...state,
                        loading: false,
                        apiSuccess: true,
                        readOneSuccess: false,
                    };
                }
                default:
                    return { ...state };
            }

        case NotificationActionTypes.USER_READ: {
            return {
                ...state,
                loading: false,
                apiSuccess: false,
                readOneSuccess: false,
            };
        }
        case NotificationActionTypes.USER_READ_ALL: {
            return {
                ...state,
                loading: false,
                apiSuccess: false,
                readAllSuccess: false,
            };
        }
        default:
            return { ...state };
    }
};

export default Notification;

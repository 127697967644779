import { CompilerActionTypes } from './constants';
import { AdminTestSourceResponse, InternalCompilerLanguage } from '../../../types/problem/compiler';

const INIT_STATE = {
    adminAllLanguages: null,
    adminCompilerSuccess: null,
    adminErrorLogs: null,
    adminProblemResponse: null,
    adminTestResponse: null,
    apiCompilerSuccess: false,
    apiUpsertSuccess: false,
    error: false,
    loading: false,
};

type CompilerActionType = {
    type:
        | CompilerActionTypes.API_RESPONSE_SUCCESS
        | CompilerActionTypes.API_RESPONSE_ERROR
        | CompilerActionTypes.ADMIN_VIEW_LANGUAGES
        | CompilerActionTypes.ADMIN_TEST_SOURCE
        | CompilerActionTypes.ADMIN_TEST_PROBLEM;
    payload: {
        actionType?: string;
        data?: InternalCompilerLanguage[] | AdminTestSourceResponse | {};
        error?: string;
    };
};

type State = {
    adminAllLanguages?: InternalCompilerLanguage[] | null;
    adminErrorLogs?: string | null;
    adminProblemResponse?: AdminTestSourceResponse | null;
    adminTestResponse?: AdminTestSourceResponse | null;
    apiCompilerSuccess: boolean | null;
    error?: boolean;
    loading?: boolean;
};

const Compiler = (state: State = INIT_STATE, action: CompilerActionType) => {
    switch (action.type) {
        case CompilerActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CompilerActionTypes.ADMIN_VIEW_LANGUAGES: {
                    return {
                        ...state,
                        adminAllLanguages: action.payload.data,
                        adminCompilerSuccess: true,
                        loading: false,
                    };
                }
                case CompilerActionTypes.ADMIN_TEST_SOURCE: {
                    return {
                        ...state,
                        adminTestResponse: action.payload.data,
                        adminCompilerSuccess: true,
                        apiCompilerSuccess: true,
                        loading: false,
                    };
                }
                case CompilerActionTypes.ADMIN_TEST_PROBLEM: {
                    return {
                        ...state,
                        adminProblemResponse: action.payload.data,
                        adminCompilerSuccess: true,
                        loading: false,
                    };
                }
                case CompilerActionTypes.ADMIN_VIEW_LOGS: {
                    return {
                        ...state,
                        adminErrorLogs: action.payload.data,
                        adminCompilerSuccess: false,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case CompilerActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case CompilerActionTypes.ADMIN_VIEW_LANGUAGES: {
                    return {
                        ...state,
                        adminAllLanguages: null,
                        error: action.payload.error,
                    };
                }
                case CompilerActionTypes.ADMIN_TEST_SOURCE: {
                    return {
                        ...state,
                        adminTestResponse: null,
                        apiCompilerSuccess: false,
                        error: action.payload.error,
                    };
                }
                case CompilerActionTypes.ADMIN_TEST_PROBLEM: {
                    return {
                        ...state,
                        adminProblemResponse: null,
                        adminCompilerSuccess: false,
                        error: action.payload.error,
                    };
                }
                default:
                    return { ...state };
            }

        case CompilerActionTypes.ADMIN_TEST_SOURCE:
            return {
                ...state,
                error: false,
                adminTestResponse: null,
                adminCompilerSuccess: null,
                apiCompilerSuccess: false,
                loading: true,
            };

        case CompilerActionTypes.ADMIN_TEST_PROBLEM:
            return {
                ...state,
                error: false,
                adminCompilerSuccess: null,
                adminProblemResponse: null,
                adminErrorLogs: null,
                loading: true,
            };

        case CompilerActionTypes.ADMIN_VIEW_LANGUAGES:
            return {
                ...state,
                loading: true,
                adminCompilerSuccess: null,
                adminAllLanguages: null,
                error: false,
            };

        default:
            return { ...state };
    }
};

export default Compiler;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { EnergyActionTypes } from './constants';
import { apiAdminViewAll, apiUserViewAll } from '../../../../helpers/api/user/logs/energy';
import { EnergyDetailsAdmin } from '../../../../types/user/logs/energy';
import { energyApiResponseError, energyApiResponseSuccess } from './actions';

type UserAllEnergyPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

function* adminViewAll(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll);
        const responseData: EnergyDetailsAdmin[] = response.data.map((energyLog: EnergyDetailsAdmin) => {
            return Object.assign(energyLog, {
                id_short: energyLog.id.substring(0, 8),
                model_and_id: `${energyLog.model}(${energyLog.modelId.substring(0, 8)})`,
            });
        });
        yield put(energyApiResponseSuccess(EnergyActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(energyApiResponseError(EnergyActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* userViewAll({ payload: { page, size } }: UserAllEnergyPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewAll, page, size);
        yield put(energyApiResponseSuccess(EnergyActionTypes.USER_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(energyApiResponseError(EnergyActionTypes.USER_VIEW_ALL, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(EnergyActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchUserViewAll() {
    yield takeEvery(EnergyActionTypes.USER_VIEW_ALL, userViewAll);
}

function* energyLogsSaga() {
    yield all([fork(watchAdminViewAll), fork(watchUserViewAll)]);
}

export default energyLogsSaga;

import { AiConversationActionTypes } from './constants';
import { AiConversationDetails, AiConversationSummary } from '../../../types/ai/aiConversation';

export type AiConversationActionType = {
    type:
        | AiConversationActionTypes.API_RESPONSE_SUCCESS
        | AiConversationActionTypes.API_RESPONSE_ERROR
        | AiConversationActionTypes.ADMIN_VIEW_ALL
        | AiConversationActionTypes.ADMIN_VIEW_DETAILS
        | AiConversationActionTypes.RESET;
    payload: {} | string;
};

export const aiConversationApiResponseSuccess = (actionType: string, data: AiConversationSummary[] | AiConversationDetails | {}): AiConversationActionType => ({
    type: AiConversationActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const aiConversationApiResponseError = (actionType: string, error: string): AiConversationActionType => ({
    type: AiConversationActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const aiConversationAdminViewAll = (): AiConversationActionType => ({
    type: AiConversationActionTypes.ADMIN_VIEW_ALL,
    payload: {},
});

export const aiConversationAdminViewDetails = (id: string): AiConversationActionType => ({
    type: AiConversationActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

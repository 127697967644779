import { ProblemSearchPanelActionTypes } from './constants';

export type ProblemSearchPanelActionType = {
    type:
        | ProblemSearchPanelActionTypes.FORM_SET_SOLVED
        | ProblemSearchPanelActionTypes.FORM_SET_DIFFICULTY
        | ProblemSearchPanelActionTypes.FORM_SET_ORDER
        | ProblemSearchPanelActionTypes.FORM_SET_PAGE
        | ProblemSearchPanelActionTypes.FORM_SET_SORT
        | ProblemSearchPanelActionTypes.FORM_SET_TITLE
        | ProblemSearchPanelActionTypes.FORM_SET_TAGS;
    payload: {} | string;
};

export const problemSearchSetSolved = (solved: number): ProblemSearchPanelActionType => ({
    type: ProblemSearchPanelActionTypes.FORM_SET_SOLVED,
    payload: { data: solved },
});

export const problemSearchSetDifficulty = (difficulty: number | null): ProblemSearchPanelActionType => ({
    type: ProblemSearchPanelActionTypes.FORM_SET_DIFFICULTY,
    payload: { data: difficulty },
});

export const problemSearchSetOrder = (order: string): ProblemSearchPanelActionType => ({
    type: ProblemSearchPanelActionTypes.FORM_SET_ORDER,
    payload: { data: order },
});

export const problemSearchSetPage = (page: number): ProblemSearchPanelActionType => ({
    type: ProblemSearchPanelActionTypes.FORM_SET_PAGE,
    payload: { data: page },
});

export const problemSearchSetSort = (sort: string): ProblemSearchPanelActionType => ({
    type: ProblemSearchPanelActionTypes.FORM_SET_SORT,
    payload: { data: sort },
});

export const problemSearchSetTitle = (title: string): ProblemSearchPanelActionType => ({
    type: ProblemSearchPanelActionTypes.FORM_SET_TITLE,
    payload: { data: title },
});

export const problemSearchSetTags = (tags: string[]): ProblemSearchPanelActionType => ({
    type: ProblemSearchPanelActionTypes.FORM_SET_TAGS,
    payload: { data: tags },
});

import { all } from 'redux-saga/effects';
import adminPanelSaga from './admin/saga';
import aiConfigSaga from './ai/config/saga';
import aiConversationSaga from './ai/conversation/saga';
import aiMessageSaga from './ai/message/saga';
import answerSaga from './quiz/answer/saga';
import authSaga from './auth/saga';
import chapterSaga from './theory/chapter/saga';
import chatSaga from './user/chat/saga';
import commentSaga from './cms/comment/saga';
import compilerSaga from './problem/compiler/saga';
import contactSaga from './cms/contact/saga';
import countySaga from './constant/counties/saga';
import dashboardSaga from './panel/saga';
import dependencySaga from './cms/dependency/saga';
import energyLogSaga from './user/logs/energy/saga';
import friendshipSaga from './user/friendship/saga';
import gallerySaga from './gallery/saga';
import layoutSaga from './layout/saga';
import lectureSaga from './theory/lecture/saga';
import loginLog from './log/login/saga';
import moduleSaga from './theory/module/saga';
import notificationSaga from './user/notifications/saga';
import problemHintSaga from './problem/hint/saga';
import problemSaga from './problem/problem/saga';
import problemSolutionSaga from './problem/solution/saga';
import problemTestSaga from './problem/test/saga';
import profileSaga from './user/profile/saga';
import purchaseSaga from './user/purchase/saga';
import questionSaga from './quiz/question/saga';
import quizSaga from './theory/quiz/saga';
import rankingSaga from './user/ranking/saga';
import reportSaga from './cms/report/saga';
import securitySaga from './log/security/saga';
import schoolSaga from './cms/school/saga';
import seoDataSaga from './cms/seoData/saga';
import settingsSaga from './user/settings/saga';
import shopBundleSaga from './shop/bundle/saga';
import shopInvoiceSaga from './shop/invoice/saga';
import staticPageSaga from './cms/staticPage/saga';
import staticSearchSaga from './user/search/saga';
import tagSaga from './cms/tag/saga';
import ticketSaga from './cms/ticket/saga';
import timespentLogSaga from './user/logs/timespent/saga';
import userDashboardSaga from './user/dashboard/saga';

export default function* rootSaga() {
    yield all([
        adminPanelSaga(),
        aiConfigSaga(),
        aiConversationSaga(),
        aiMessageSaga(),
        answerSaga(),
        authSaga(),
        chapterSaga(),
        chatSaga(),
        commentSaga(),
        compilerSaga(),
        contactSaga(),
        countySaga(),
        dashboardSaga(),
        dependencySaga(),
        energyLogSaga(),
        friendshipSaga(),
        gallerySaga(),
        layoutSaga(),
        lectureSaga(),
        loginLog(),
        moduleSaga(),
        notificationSaga(),
        problemHintSaga(),
        problemSaga(),
        problemSolutionSaga(),
        problemTestSaga(),
        profileSaga(),
        purchaseSaga(),
        questionSaga(),
        quizSaga(),
        rankingSaga(),
        reportSaga(),
        schoolSaga(),
        seoDataSaga(),
        securitySaga(),
        settingsSaga(),
        shopBundleSaga(),
        shopInvoiceSaga(),
        staticPageSaga(),
        staticSearchSaga(),
        tagSaga(),
        ticketSaga(),
        timespentLogSaga(),
        userDashboardSaga(),
    ]);
}

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { tagApiResponseError, tagApiResponseSuccess } from './actions';
import { apiUserGetAll, apiAdminGetAll, apiAdminView, apiAdminUpdate, apiAdminCreate } from '../../../helpers/api/cms/tag';
import { TagActionTypes } from './constants';
import { TagDetails, TagNew } from '../../../types/cms/tag';

type TagUserViewAllPayloadType = {
    payload: {
        entity: string;
    };
    type: string;
};

type TagAdminViewPayloadType = {
    payload: {
        tagId: number;
    };
    type: string;
};

type TagAdminUpdatePayloadType = {
    payload: TagNew;
    type: string;
};

function* userViewTags({ payload: { entity } }: TagUserViewAllPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserGetAll, entity);
        yield put(tagApiResponseSuccess(TagActionTypes.USER_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(tagApiResponseError(TagActionTypes.USER_VIEW_ALL, error));
    }
}

function* adminView({ payload: { tagId } }: TagAdminViewPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminView, { tagId: tagId });
        yield put(tagApiResponseSuccess(TagActionTypes.ADMIN_VIEW, response.data));
    } catch (error: any) {
        yield put(tagApiResponseError(TagActionTypes.ADMIN_VIEW, error));
    }
}

function* adminViewAll(): SagaIterator {
    try {
        const response = yield call(apiAdminGetAll);
        const responseData: TagDetails[] = response.data.map((tag: TagDetails) => {
            return Object.assign(tag, {
                id_short: tag.id.substring(0, 8),
            });
        });
        yield put(tagApiResponseSuccess(TagActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(tagApiResponseError(TagActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminUpdate({ payload }: TagAdminUpdatePayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminUpdate, payload);
        yield put(tagApiResponseSuccess(TagActionTypes.ADMIN_UPDATE, response.data));
    } catch (error: any) {
        yield put(tagApiResponseError(TagActionTypes.ADMIN_UPDATE, error));
    }
}

function* adminCreate({ payload }: TagAdminUpdatePayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminCreate, payload);
        yield put(tagApiResponseSuccess(TagActionTypes.ADMIN_CREATE, response.data));
    } catch (error: any) {
        yield put(tagApiResponseError(TagActionTypes.ADMIN_CREATE, error));
    }
}

function* watchUserGet(): SagaIterator {
    yield takeEvery(TagActionTypes.USER_VIEW_ALL, userViewTags);
}

function* watchAdminView(): SagaIterator {
    yield takeEvery(TagActionTypes.ADMIN_VIEW, adminView);
}

function* watchAdminCreate(): SagaIterator {
    yield takeEvery(TagActionTypes.ADMIN_CREATE, adminCreate);
}

function* watchAdminUpdate(): SagaIterator {
    yield takeEvery(TagActionTypes.ADMIN_UPDATE, adminUpdate);
}

function* watchAdminViewAll(): SagaIterator {
    yield takeEvery(TagActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

function* TagSaga() {
    yield all([fork(watchUserGet), fork(watchAdminViewAll), fork(watchAdminView), fork(watchAdminUpdate), fork(watchAdminCreate)]);
}

export default TagSaga;

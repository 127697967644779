import { AdminPanelActionTypes } from './constants';

export type AdminPanelActionType = {
    type: AdminPanelActionTypes.API_RESPONSE_SUCCESS | AdminPanelActionTypes.API_RESPONSE_ERROR | AdminPanelActionTypes.ADMIN_CLEAR_CACHE;
    payload: {} | string;
};

export const adminPanelApiResponseSuccess = (actionType: string, data: AdminPanelActionTypes | {}): AdminPanelActionType => ({
    type: AdminPanelActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const adminPanelApiResponseError = (actionType: string, error: string): AdminPanelActionType => ({
    type: AdminPanelActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const adminPanelClearCache = (): AdminPanelActionType => ({
    type: AdminPanelActionTypes.ADMIN_CLEAR_CACHE,
    payload: {},
});

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';

const api = new APICore();

function apiUserViewLast(username: string) {
    return api.get(`${apiBaseUrl}/friendship/last-friends/${username}`, null);
}

function apiUserViewAll(username: string) {
    return api.get(`${apiBaseUrl}/friendship/all-friends/${username}`, null);
}

function apiUserCountFriends(username: string) {
    return api.get(`${apiBaseUrl}/friendship/count-friends/${username}`, null);
}

function apiUserDestroyFriendship(id: string) {
    return api.create(`${apiBaseUrl}/friendship/delete/${id}`, {});
}

function apiUserAcceptFriendship(id: string) {
    return api.create(`${apiBaseUrl}/friendship/accept/${id}`, {});
}

function apiUserViewAllIncoming() {
    return api.get(`${apiBaseUrl}/friendship/incoming`, null);
}

function apiUserAddFriend(username: string) {
    return api.create(`${apiBaseUrl}/friendship/send/${username}`, {});
}

function apiUserCheckFriendship(username: string) {
    return api.get(`${apiBaseUrl}/friendship/check/${username}`, {});
}

export { apiUserCheckFriendship, apiUserViewLast, apiUserViewAll, apiUserCountFriends, apiUserDestroyFriendship, apiUserAcceptFriendship, apiUserViewAllIncoming, apiUserAddFriend };

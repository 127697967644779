import { EnergyActionTypes } from './constants';
import { EnergyDetails, EnergyDetailsAdmin } from '../../../../types/user/logs/energy';

const INIT_STATE = {
    apiSuccess: false,
    loading: false,
    adminAllEnergyLogs: null,
    userAllEnergyLogs: null,
};

type EnergyActionType = {
    type: EnergyActionTypes.API_RESPONSE_ERROR | EnergyActionTypes.API_RESPONSE_SUCCESS | EnergyActionTypes.ADMIN_VIEW_ALL | EnergyActionTypes.USER_VIEW_ALL | EnergyActionTypes.RESET;

    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    adminAllEnergyLogs?: EnergyDetailsAdmin[] | null;
    userAllEnergyLogs?: EnergyDetails[] | null;
    apiSuccess?: boolean;
    loading?: boolean;
    error?: string | null;
};

const Energy = (state: State = INIT_STATE, action: EnergyActionType) => {
    switch (action.type) {
        case EnergyActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case EnergyActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllEnergyLogs: action.payload.data,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                case EnergyActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        userAllEnergyLogs: action.payload.data,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }

        case EnergyActionTypes.ADMIN_VIEW_ALL:
            return {
                loading: true,
                apiSuccess: false,
            };

        case EnergyActionTypes.USER_VIEW_ALL:
            return {
                loading: true,
                apiSuccess: false,
                userAllEnergyLogs: null,
            };

        default:
            return { ...state };
    }
};

export default Energy;

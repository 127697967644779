import { TicketActionTypes } from './constants';
import { TicketAdminSummary, TicketSummary } from '../../../types/support/supportTicket';

const INIT_STATE = {
    adminAllTickets: null,
    adminTicketDetails: null,
    apiSuccess: false,
    loading: false,
    userAllTickets: null,
    userTicketDetails: null,
};

type TicketActionType = {
    type:
        | TicketActionTypes.ADMIN_ARCHIVE
        | TicketActionTypes.ADMIN_CLOSE
        | TicketActionTypes.ADMIN_REPLY
        | TicketActionTypes.ADMIN_VIEW_ALL
        | TicketActionTypes.ADMIN_VIEW_ARCHIVED
        | TicketActionTypes.ADMIN_VIEW_DETAILS
        | TicketActionTypes.API_RESPONSE_ERROR
        | TicketActionTypes.API_RESPONSE_SUCCESS
        | TicketActionTypes.USER_VIEW_ALL
        | TicketActionTypes.USER_CREATE
        | TicketActionTypes.USER_VIEW_DETAILS
        | TicketActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    adminAllTickets?: TicketAdminSummary[] | null;
    adminTicketDetails?: TicketAdminSummary | null;
    apiSuccess?: boolean;
    userAllTickets?: TicketSummary[] | null;
    loading?: boolean;
};

const Ticket = (state: State = INIT_STATE, action: TicketActionType) => {
    switch (action.type) {
        case TicketActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TicketActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllTickets: action.payload.data,
                        loading: false,
                    };
                }
                case TicketActionTypes.ADMIN_VIEW_ARCHIVED: {
                    return {
                        ...state,
                        adminAllTickets: action.payload.data,
                        loading: false,
                    };
                }
                case TicketActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminTicketDetails: action.payload.data,
                        loading: false,
                    };
                }
                case TicketActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        userAllTickets: action.payload.data,
                        loading: false,
                    };
                }
                case TicketActionTypes.USER_VIEW_DETAILS: {
                    return {
                        ...state,
                        userTicketDetails: action.payload.data,
                        loading: false,
                    };
                }
                case TicketActionTypes.ADMIN_REPLY:
                case TicketActionTypes.ADMIN_CLOSE:
                case TicketActionTypes.ADMIN_ARCHIVE:
                case TicketActionTypes.USER_CREATE:
                case TicketActionTypes.USER_REPLY: {
                    return {
                        ...state,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }

        case TicketActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TicketActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case TicketActionTypes.USER_VIEW_ALL:
            return { ...state, loading: true };

        case TicketActionTypes.USER_CREATE:
            return { ...state, loading: true };

        case TicketActionTypes.RESET:
            return {
                ...state,
                userAllTickets: null,
                adminAllTickets: null,
                adminTicketDetails: null,
                loading: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default Ticket;

export enum CommentActionTypes {
    API_RESPONSE_SUCCESS = '@@comment/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@comment/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL = '@@comment/ADMIN_VIEW_ALL',
    ADMIN_VIEW_DETAILS = '@@comment/ADMIN_VIEW_DETAILS',
    ADMIN_UPDATE = '@@comment/ADMIN_UPDATE',
    ADMIN_DELETE = '@@comment/ADMIN_DELETE',

    USER_GET = '@@comment/USER_GET',
    USER_SEND = '@@comment/USER_SEND',

    RESET = '@@comment/RESET',
}

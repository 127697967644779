import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { staticPageApiResponseSuccess, staticPageApiResponseError } from './actions';
import { apiUserView as userView, apiAdminViewAll, apiAdminCreate, apiAdminUpdate } from '../../../helpers/api/cms/staticPage';
import { PageActionTypes } from './constants';
import { StaticPageData, StaticPageNew } from '../../../types/cms/staticPage';

type StaticPageSagaData = {
    payload: {
        slug: string;
    };
    type: string;
};

type StaticPageUpdateSaga = {
    payload: StaticPageNew;
    type: string;
};

/**
 * View the static page
 * @param {*} payload - the slug of the static page
 */
function* userViewDetails({ payload: { slug } }: StaticPageSagaData): SagaIterator {
    try {
        const response = yield call(userView, { slug: slug });
        yield put(staticPageApiResponseSuccess(PageActionTypes.USER_VIEW, response.data));
    } catch (error: any) {
        yield put(staticPageApiResponseError(PageActionTypes.USER_VIEW, error));
    }
}

function* adminViewAll(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll);
        const responseData: StaticPageData[] = response.data.map((page: StaticPageData) => {
            return Object.assign(page, {
                id_short: page.id.substring(0, 8),
            });
        });
        yield put(staticPageApiResponseSuccess(PageActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(staticPageApiResponseError(PageActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminUpdate({ payload }: StaticPageUpdateSaga): SagaIterator {
    try {
        const response = yield call(apiAdminUpdate, payload);
        yield put(staticPageApiResponseSuccess(PageActionTypes.ADMIN_UPDATE, response.data));
    } catch (error: any) {
        yield put(staticPageApiResponseError(PageActionTypes.ADMIN_UPDATE, error));
    }
}

function* adminCreate({ payload }: StaticPageUpdateSaga): SagaIterator {
    try {
        const response = yield call(apiAdminCreate, payload);
        yield put(staticPageApiResponseSuccess(PageActionTypes.ADMIN_CREATE, response.data));
    } catch (error: any) {
        yield put(staticPageApiResponseError(PageActionTypes.ADMIN_CREATE, error));
    }
}

export function* watchUserViewDetails() {
    yield takeEvery(PageActionTypes.USER_VIEW, userViewDetails);
}

export function* watchAdminCreate() {
    yield takeEvery(PageActionTypes.ADMIN_CREATE, adminCreate);
}

export function* watchAdminUpdate() {
    yield takeEvery(PageActionTypes.ADMIN_UPDATE, adminUpdate);
}

export function* watchAdminViewAll() {
    yield takeEvery(PageActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

function* staticPageSaga() {
    yield all([fork(watchUserViewDetails), fork(watchAdminViewAll), fork(watchAdminCreate), fork(watchAdminUpdate)]);
}

export default staticPageSaga;

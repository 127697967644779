import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { LectureNew } from '../../../types/theory/lecture';

const api = new APICore();

function apiAdminViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/lectures/admin/${id}`, null);
}

function apiAdminViewAll(page: number, size: number) {
    return api.get(`${apiBaseUrl}/lectures/admin/page/${page}/${size}`, null);
}

function apiAdminViewAllShort() {
    return api.get(`${apiBaseUrl}/lectures/admin/short`, null);
}

function apiAdminCreate(data: LectureNew) {
    return api.create(`${apiBaseUrl}/lectures/admin`, data);
}

function apiAdminUpdate(data: LectureNew) {
    return api.update(`${apiBaseUrl}/lectures/admin`, data);
}

function apiUserViewDetails(slug: string) {
    return api.get(`${apiBaseUrl}/lectures/${slug}`, null);
}

function apiUserViewRecap(slug: string) {
    return api.get(`${apiBaseUrl}/lectures/${slug}/recap`, null);
}

export { apiAdminCreate, apiAdminUpdate, apiAdminViewAll, apiAdminViewDetails, apiAdminViewAllShort, apiUserViewDetails, apiUserViewRecap };

import { ProfileActionTypes } from './constants';
import { ProfileDetails } from '../../../types/user/profile';

const INIT_STATE = {
    apiSuccess: false,
    error: null,
    loading: false,
    userProfileData: null,
};

type NotificationActionType = {
    type: ProfileActionTypes.API_RESPONSE_ERROR | ProfileActionTypes.API_RESPONSE_SUCCESS | ProfileActionTypes.USER_LOAD_PROFILE | ProfileActionTypes.RESET;

    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    apiSuccess?: boolean;
    loading?: boolean;
    error?: string | null;
    userProfileData: ProfileDetails | null;
};

const Profile = (state: State = INIT_STATE, action: NotificationActionType) => {
    switch (action.type) {
        case ProfileActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProfileActionTypes.USER_LOAD_PROFILE: {
                    return {
                        ...state,
                        loading: false,
                        userProfileData: action.payload.data,
                        apiSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }
        case ProfileActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ProfileActionTypes.USER_LOAD_PROFILE:
                    return {
                        error: action.payload.error,
                        loading: true,
                        apiSuccess: false,
                    };
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Profile;

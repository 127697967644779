import { ModuleActionTypes } from './constants';
import { ModuleDetails, ModuleNew, ModuleSummary } from '../../../types/theory/module';

export type ModuleActionType = {
    type:
        | ModuleActionTypes.API_RESPONSE_SUCCESS
        | ModuleActionTypes.API_RESPONSE_ERROR
        | ModuleActionTypes.USER_VIEW_ALL
        | ModuleActionTypes.USER_VIEW_DETAILS
        | ModuleActionTypes.USER_VIEW_CHAPTERS
        | ModuleActionTypes.USER_DEFAULT_TAB
        | ModuleActionTypes.USER_VIEW_PROGRESS
        | ModuleActionTypes.ADMIN_VIEW_ALL
        | ModuleActionTypes.ADMIN_VIEW_ALL_SHORT
        | ModuleActionTypes.ADMIN_VIEW_DETAILS
        | ModuleActionTypes.ADMIN_UPSERT
        | ModuleActionTypes.RESET;
    payload: {} | string;
};

export const moduleAllApiResponseSuccess = (actionType: string, data: ModuleSummary | ModuleDetails | {}): ModuleActionType => ({
    type: ModuleActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const moduleApiResponseError = (actionType: string, error: string): ModuleActionType => ({
    type: ModuleActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const moduleUserViewAll = (): ModuleActionType => ({
    type: ModuleActionTypes.USER_VIEW_ALL,
    payload: {},
});

export const moduleUserViewDetails = (slug: string): ModuleActionType => ({
    type: ModuleActionTypes.USER_VIEW_DETAILS,
    payload: { slug },
});

export const moduleUserViewProgress = (slug: string): ModuleActionType => ({
    type: ModuleActionTypes.USER_VIEW_PROGRESS,
    payload: { slug },
});

export const moduleUserViewChapters = (slug: string): ModuleActionType => ({
    type: ModuleActionTypes.USER_VIEW_CHAPTERS,
    payload: { slug },
});

export const moduleUserSetDefaultTab = (tab: string): ModuleActionType => ({
    type: ModuleActionTypes.USER_DEFAULT_TAB,
    payload: { data: tab },
});

export const moduleAdminViewAll = (): ModuleActionType => ({
    type: ModuleActionTypes.ADMIN_VIEW_ALL,
    payload: {},
});

export const moduleAdminViewAllShort = (): ModuleActionType => ({
    type: ModuleActionTypes.ADMIN_VIEW_ALL_SHORT,
    payload: {},
});

export const moduleAdminViewDetails = (id: string): ModuleActionType => ({
    type: ModuleActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

export const moduleAdminUpsert = (data: ModuleNew): ModuleActionType => ({
    type: ModuleActionTypes.ADMIN_UPSERT,
    payload: data,
});

export const resetModule = (): ModuleActionType => ({
    type: ModuleActionTypes.RESET,
    payload: {},
});

export enum PageActionTypes {
    API_RESPONSE_SUCCESS = '@@staticPage/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@staticPage/API_RESPONSE_ERROR',

    USER_VIEW = '@@staticPage/USER_VIEW',
    ADMIN_CREATE = '@@staticPage/ADMIN_CREATE',
    ADMIN_UPDATE = '@@staticPage/ADMIN_UPDATE',
    ADMIN_VIEW_ALL = '@@staticPage/ADMIN_VIEW_ALL',

    RESET = '@@staticPage/RESET',
}

export const decodeBase64 = (text: string) => {
    return decodeURIComponent(
        atob(text)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );
};

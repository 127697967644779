import { DashboardActionTypes } from './constants';
import { DashboardStats, UserHeatGraph } from '../../../types/user/dashboard';
import { CommentDetails } from '../../../types/cms/comment';

const INIT_STATE = {
    loading: false,
    error: null,
    userComments: null,
    userGraph7Days: null,
    userGraphHeatmap: null,
    userStats: null,
    userStartedModules: null,
};

type DashboardActionType = {
    type:
        | DashboardActionTypes.API_RESPONSE_ERROR
        | DashboardActionTypes.API_RESPONSE_SUCCESS
        | DashboardActionTypes.USER_COMMENTS
        | DashboardActionTypes.USER_LOAD_GRAPH_7_DAYS
        | DashboardActionTypes.USER_LOAD_GRAPH_HEATMAP
        | DashboardActionTypes.USER_LOAD_STATS
        | DashboardActionTypes.USER_STARTED_MODULES;
    payload: {
        actionType?: string;
        data?: {} | UserHeatGraph | number[];
        error?: string;
    };
};

type State = {
    loading?: boolean;
    error?: string | null;
    userComments?: CommentDetails[] | null;
    userGraph7Days?: number[] | null;
    userGraphHeatmap?: UserHeatGraph | null;
    userStats?: DashboardStats | null;
    userStartedModules?: {} | null;
};

const Dashboard = (state: State = INIT_STATE, action: DashboardActionType) => {
    switch (action.type) {
        case DashboardActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DashboardActionTypes.USER_COMMENTS: {
                    return {
                        ...state,
                        loading: false,
                        userComments: action.payload.data,
                    };
                }
                case DashboardActionTypes.USER_LOAD_GRAPH_7_DAYS: {
                    return {
                        ...state,
                        loading: false,
                        userGraph7Days: action.payload.data,
                    };
                }
                case DashboardActionTypes.USER_LOAD_GRAPH_HEATMAP: {
                    return {
                        ...state,
                        loading: false,
                        userGraphHeatmap: action.payload.data,
                    };
                }
                case DashboardActionTypes.USER_LOAD_STATS: {
                    return {
                        ...state,
                        loading: false,
                        userStats: action.payload.data,
                    };
                }
                case DashboardActionTypes.USER_STARTED_MODULES: {
                    return {
                        ...state,
                        loading: false,
                        userStartedModules: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case DashboardActionTypes.USER_COMMENTS: {
            return {
                ...state,
                loading: true,
                userComments: null,
            };
        }

        case DashboardActionTypes.USER_LOAD_GRAPH_7_DAYS: {
            return {
                ...state,
                loading: true,
                userGraph7Days: null,
            };
        }
        case DashboardActionTypes.USER_LOAD_GRAPH_HEATMAP: {
            return {
                ...state,
                loading: true,
                userGraphHeatmap: null,
            };
        }

        case DashboardActionTypes.USER_LOAD_STATS: {
            return {
                ...state,
                loading: true,
                userStats: null,
            };
        }

        case DashboardActionTypes.USER_STARTED_MODULES: {
            return {
                ...state,
                loading: true,
                userStartedModules: null,
            };
        }

        default:
            return { ...state };
    }
};

export default Dashboard;

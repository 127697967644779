import { NotificationActionTypes } from './constants';

export type NotificationActionType = {
    type:
        | NotificationActionTypes.USER_VIEW_LAST
        | NotificationActionTypes.API_RESPONSE_ERROR
        | NotificationActionTypes.API_RESPONSE_SUCCESS
        | NotificationActionTypes.USER_VIEW_ALL
        | NotificationActionTypes.USER_READ_ALL
        | NotificationActionTypes.USER_READ
        | NotificationActionTypes.RESET;
    payload: {} | string;
};

export const notificationUserViewLast = (): NotificationActionType => ({
    type: NotificationActionTypes.USER_VIEW_LAST,
    payload: {},
});

export const notificationUserReadAll = (): NotificationActionType => ({
    type: NotificationActionTypes.USER_READ_ALL,
    payload: {},
});

export const notificationUserRead = (id: String): NotificationActionType => ({
    type: NotificationActionTypes.USER_READ,
    payload: { id },
});

export const notificationUserViewAll = (): NotificationActionType => ({
    type: NotificationActionTypes.USER_VIEW_ALL,
    payload: {},
});

export const notificationApiResponseSuccess = (actionType: string, data: {}): NotificationActionType => ({
    type: NotificationActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const notificationApiResponseError = (actionType: string, error: string): NotificationActionType => ({
    type: NotificationActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { AnswerNew } from '../../../types/quiz/answer';

const api = new APICore();

function apiAdminViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/answers/admin/${id}`, null);
}

function apiAdminViewAll(page: number, size: number) {
    return api.get(`${apiBaseUrl}/answers/admin/page/${page}/${size}`, null);
}

function apiAdminViewAllShort() {
    return api.get(`${apiBaseUrl}/answers/admin/short`, null);
}

function apiAdminCreate(data: AnswerNew) {
    return api.create(`${apiBaseUrl}/answers/admin`, data);
}

function apiAdminUpdate(data: AnswerNew) {
    return api.update(`${apiBaseUrl}/answers/admin`, data);
}

export { apiAdminCreate, apiAdminUpdate, apiAdminViewAll, apiAdminViewAllShort, apiAdminViewDetails };

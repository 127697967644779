import { SettingsActionTypes } from './constants';
import { PersonalDataResponse, PreferenceResponse, ProfileDataResponse } from '../../../types/user/settings';

const INIT_STATE = {
    apiSuccess: false,
    apiAvatarSuccess: false,
    loading: false,
    userPersonalData: null,
    userProfileData: null,
    userPreferenceData: null,
};

type SettingActionType = {
    type:
        | SettingsActionTypes.API_RESPONSE_ERROR
        | SettingsActionTypes.API_RESPONSE_SUCCESS
        | SettingsActionTypes.USER_UPDATE_AVATAR
        | SettingsActionTypes.USER_UPDATE_PASSWORD
        | SettingsActionTypes.USER_UPDATE_PERSONAL_DATA
        | SettingsActionTypes.USER_UPDATE_PREFERENCE_DATA
        | SettingsActionTypes.USER_UPDATE_PROFILE_DATA
        | SettingsActionTypes.USER_VIEW_PERSONAL_DATA
        | SettingsActionTypes.USER_VIEW_PREFERENCE_DATA
        | SettingsActionTypes.USER_VIEW_PROFILE_DATA
        | SettingsActionTypes.USER_DELETE_ACCOUNT
        | SettingsActionTypes.RESET;

    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    apiSuccess?: boolean;
    apiAvatarSuccess?: boolean;
    loading?: boolean;
    error?: string | null;
    userPersonalData?: PersonalDataResponse | null;
    userProfileData?: ProfileDataResponse | null;
    userPreferenceData?: PreferenceResponse | null;
};

const Settings = (state: State = INIT_STATE, action: SettingActionType) => {
    switch (action.type) {
        case SettingsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case SettingsActionTypes.USER_VIEW_PERSONAL_DATA: {
                    return {
                        ...state,
                        loading: false,
                        userPersonalData: action.payload.data,
                    };
                }
                case SettingsActionTypes.USER_VIEW_PROFILE_DATA: {
                    return {
                        ...state,
                        loading: false,
                        userProfileData: action.payload.data,
                    };
                }
                case SettingsActionTypes.USER_VIEW_PREFERENCE_DATA: {
                    return {
                        ...state,
                        loading: false,
                        userPreferenceData: action.payload.data,
                    };
                }
                case SettingsActionTypes.USER_UPDATE_AVATAR:
                    return {
                        ...state,
                        apiAvatarSuccess: true,
                    };

                case SettingsActionTypes.USER_UPDATE_PASSWORD:
                case SettingsActionTypes.USER_UPDATE_PERSONAL_DATA:
                case SettingsActionTypes.USER_UPDATE_PROFILE_DATA:
                case SettingsActionTypes.USER_UPDATE_PREFERENCE_DATA:
                case SettingsActionTypes.USER_DELETE_ACCOUNT: {
                    return {
                        ...state,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }

        case SettingsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case SettingsActionTypes.USER_VIEW_PREFERENCE_DATA:
                case SettingsActionTypes.USER_VIEW_PROFILE_DATA:
                case SettingsActionTypes.USER_VIEW_PERSONAL_DATA:
                case SettingsActionTypes.USER_UPDATE_AVATAR:
                case SettingsActionTypes.USER_UPDATE_PASSWORD:
                case SettingsActionTypes.USER_UPDATE_PERSONAL_DATA: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case SettingsActionTypes.USER_UPDATE_AVATAR:
            return {
                ...state,
                apiAvatarSuccess: false,
            };

        case SettingsActionTypes.USER_VIEW_PERSONAL_DATA:
        case SettingsActionTypes.USER_VIEW_PROFILE_DATA:
        case SettingsActionTypes.USER_VIEW_PREFERENCE_DATA:
        case SettingsActionTypes.USER_UPDATE_PASSWORD:
        case SettingsActionTypes.USER_UPDATE_PERSONAL_DATA:
            return {
                ...state,
                apiSuccess: false,
                loading: true,
                error: null,
            };

        case SettingsActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default Settings;

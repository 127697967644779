import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiAdminCreate, apiAdminUpdate, apiAdminViewAll, apiAdminViewAllShort, apiAdminViewDetails } from '../../../helpers/api/quiz/answer';
import { answerApiResponseSuccess, answerApiResponseError } from './actions';
import { AnswerDetails, AnswerNew, PaginatedAnswerDetails } from '../../../types/quiz/answer';
import { AnswerActionTypes } from './constants';

type AdminAnswerAllPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

type AdminAnswerDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

type AdminAnswerUpsertPayloadType = {
    payload: AnswerNew;
    type: string;
};

function* adminViewAll({ payload: { page, size } }: AdminAnswerAllPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll, page, size);
        const responseItems: AnswerDetails[] = response.data.items.map((answer: AnswerDetails) => {
            return Object.assign(answer, {
                id_short: answer.id.substring(0, 8),
            });
        });
        const responseData: PaginatedAnswerDetails = {
            items: responseItems,
            total: response.data.total,
        };

        yield put(answerApiResponseSuccess(AnswerActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(answerApiResponseError(AnswerActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewAllShort(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAllShort);
        yield put(answerApiResponseSuccess(AnswerActionTypes.ADMIN_VIEW_ALL_SHORT, response.data));
    } catch (error: any) {
        yield put(answerApiResponseError(AnswerActionTypes.ADMIN_VIEW_ALL_SHORT, error));
    }
}

function* adminViewDetails({ payload: { id } }: AdminAnswerDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(answerApiResponseSuccess(AnswerActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(answerApiResponseError(AnswerActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

function* adminUpsert({ payload }: AdminAnswerUpsertPayloadType): SagaIterator {
    try {
        const response = yield call(payload.id ? apiAdminUpdate : apiAdminCreate, payload);
        yield put(answerApiResponseSuccess(AnswerActionTypes.ADMIN_UPSERT, response.data));
    } catch (error: any) {
        yield put(answerApiResponseError(AnswerActionTypes.ADMIN_UPSERT, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(AnswerActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewAllShort() {
    yield takeEvery(AnswerActionTypes.ADMIN_VIEW_ALL_SHORT, adminViewAllShort);
}

export function* watchAdminViewDetails() {
    yield takeEvery(AnswerActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

export function* watchAdminUpsert() {
    yield takeEvery(AnswerActionTypes.ADMIN_UPSERT, adminUpsert);
}

function* AnswerSaga() {
    yield all([fork(watchAdminViewAll), fork(watchAdminViewAllShort), fork(watchAdminViewDetails), fork(watchAdminUpsert)]);
}

export default AnswerSaga;

export enum DependencyActionTypes {
    API_RESPONSE_SUCCESS = '@@dependency/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@dependency/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL = '@@dependency/ADMIN_VIEW_ALL',
    ADMIN_VIEW = '@@dependency/ADMIN_VIEW',
    ADMIN_UPDATE = '@@dependency/ADMIN_UPDATE',
    ADMIN_DELETE = '@@dependency/ADMIN_DELETE',
    ADMIN_MAP_ALL_MODULES = '@@dependency/ADMIN_MAP_ALL_MODULES',
    ADMIN_MAP_ALL_LECTURES = '@@dependency/ADMIN_MAP_ALL_LECTURES',
    ADMIN_MAP_ALL_CHAPTERS = '@@dependency/ADMIN_MAP_ALL_CHAPTERS',

    RESET = '@@dependency/RESET',
}

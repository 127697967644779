import { AdminGalleryActionTypes } from './constants';

export type AdminGalleryActionType = {
    type:
        | AdminGalleryActionTypes.API_RESPONSE_SUCCESS
        | AdminGalleryActionTypes.API_RESPONSE_ERROR
        | AdminGalleryActionTypes.ADMIN_IMAGES_ALL
        | AdminGalleryActionTypes.ADMIN_IMAGES_ALL_SHORT
        | AdminGalleryActionTypes.ADMIN_IMAGES_DELETE
        | AdminGalleryActionTypes.ADMIN_IMAGES_UPLOAD
        | AdminGalleryActionTypes.ADMIN_AVATARS_ALL;
    payload: {} | string;
};

export const adminGalleryApiResponseSuccess = (actionType: string, data: AdminGalleryActionTypes | {}): AdminGalleryActionType => ({
    type: AdminGalleryActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const adminGalleryApiResponseError = (actionType: string, error: string): AdminGalleryActionType => ({
    type: AdminGalleryActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const adminGalleryImagesAll = (page: number, size: number): AdminGalleryActionType => ({
    type: AdminGalleryActionTypes.ADMIN_IMAGES_ALL,
    payload: { page, size },
});

export const adminGalleryImagesAllShort = (): AdminGalleryActionType => ({
    type: AdminGalleryActionTypes.ADMIN_IMAGES_ALL_SHORT,
    payload: {},
});

export const adminGalleryImagesDelete = (id: number): AdminGalleryActionType => ({
    type: AdminGalleryActionTypes.ADMIN_IMAGES_DELETE,
    payload: { id },
});

export const adminGalleryImagesUpload = (formData: FormData): AdminGalleryActionType => ({
    type: AdminGalleryActionTypes.ADMIN_IMAGES_UPLOAD,
    payload: formData,
});

export const adminGalleryAvatarsAll = (page: number, size: number): AdminGalleryActionType => ({
    type: AdminGalleryActionTypes.ADMIN_AVATARS_ALL,
    payload: { page, size },
});

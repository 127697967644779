import { AiConfigActionTypes } from './constants';
import { AiConfigDetails, AiConfigNew } from '../../../types/ai/aiConfig';

export type AiConfigActionType = {
    type:
        | AiConfigActionTypes.API_RESPONSE_SUCCESS
        | AiConfigActionTypes.API_RESPONSE_ERROR
        | AiConfigActionTypes.ADMIN_VIEW_ALL
        | AiConfigActionTypes.ADMIN_UPDATE
        | AiConfigActionTypes.RESET;
    payload: {} | string;
};

export const aiConfigApiResponseSuccess = (actionType: string, data: AiConfigDetails[] | {}): AiConfigActionType => ({
    type: AiConfigActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const aiConfigApiResponseError = (actionType: string, error: string): AiConfigActionType => ({
    type: AiConfigActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const aiConfigAdminViewAll = (): AiConfigActionType => ({
    type: AiConfigActionTypes.ADMIN_VIEW_ALL,
    payload: {},
});

export const aiConfigAdminUpdate = (data: AiConfigNew): AiConfigActionType => ({
    type: AiConfigActionTypes.ADMIN_UPDATE,
    payload: data,
});

import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import cookieEN from 'locales/en/cookie.json';
import cookieRO from 'locales/ro/cookie.json';
import dashboardEN from 'locales/en/dashboard.json';
import dashboardRO from 'locales/ro/dashboard.json';
import invoiceEN from 'locales/en/invoice.json';
import invoiceRO from 'locales/ro/invoice.json';
import landingEN from 'locales/en/landing.json';
import landingRO from 'locales/ro/landing.json';
import problemEn from 'locales/en/problem.json';
import problemRo from 'locales/ro/problem.json';
import translationEn from 'locales/en/translation.json';
import translationRo from 'locales/ro/translation.json';

// translations
const resources = {
    en: {
        cookie: cookieEN,
        dashboard: dashboardEN,
        invoice: invoiceEN,
        landing: landingEN,
        problem: problemEn,
        translation: translationEn,
    },
    ro: {
        cookie: cookieRO,
        dashboard: dashboardRO,
        invoice: invoiceRO,
        landing: landingRO,
        problem: problemRo,
        translation: translationRo,
    },
};

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'ro',
        fallbackLng: 'ro', // use en if detected lng is not available
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;

export enum LectureActionTypes {
    API_RESPONSE_SUCCESS = '@@lecture/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@lecture/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL = '@@lecture/ADMIN_VIEW_ALL',
    ADMIN_VIEW_ALL_SHORT = '@@lecture/ADMIN_VIEW_ALL_SHORT',
    ADMIN_VIEW_DETAILS = '@@lecture/ADMIN_VIEW_DETAILS',
    ADMIN_DELETE = '@@lecture/ADMIN_DELETE',
    ADMIN_UPSERT = '@@lecture/ADMIN_UPSERT',

    USER_VIEW_DETAILS = '@@lecture/USER_VIEW_DETAILS',
    USER_VIEW_RECAP = '@@lecture/USER_VIEW_RECAP',

    RESET = '@@lecture/RESET',
}

import { useRedux } from 'hooks/index';
import { aiMessageAdminViewAll, aiMessageAdminViewDetails, aiMessageUserCreate } from '../../redux/ai/message/actions';
import { AiMessageNew } from '../../types/ai/aiMessage';

export default function useAiMessage() {
    const { dispatch, appSelector } = useRedux();

    const { apiMessageSuccess, apiAssistantResponse, adminAllMessages, adminMessageDetails } = appSelector((state) => ({
        apiMessageSuccess: state.AiMessage.apiMessageSuccess,
        apiAssistantResponse: state.AiMessage.apiAssistantResponse,
        adminAllMessages: state.AiMessage.adminAllMessages,
        adminMessageDetails: state.AiMessage.adminMessageDetails,
    }));

    const adminLoadAllMessages = () => {
        dispatch(aiMessageAdminViewAll());
    };

    const adminLoadMessageDetails = (id: string) => {
        dispatch(aiMessageAdminViewDetails(id));
    };

    const userSendMessage = (data: AiMessageNew) => {
        dispatch(aiMessageUserCreate(data));
    };

    return {
        adminLoadAllMessages,
        adminLoadMessageDetails,
        userSendMessage,
        apiMessageSuccess,
        apiAssistantResponse,
        adminAllMessages,
        adminMessageDetails,
    };
}

export enum NotificationActionTypes {
    API_RESPONSE_ERROR = '@@notification/API_RESPONSE_ERROR',
    API_RESPONSE_SUCCESS = '@@notification/API_RESPONSE_SUCCESS',
    USER_VIEW_LAST = '@@notification/USER_VIEW_LAST',
    USER_VIEW_ALL = '@@notification/USER_VIEW_ALL',
    USER_READ_ALL = '@@notification/USER_READ_ALL',
    USER_READ = '@@notification/USER_READ',

    RESET = '@@notification/RESET',
}

import { TagDetails } from '../cms/tag';
import { LectureSelectableShort } from '../theory/lecture';
import { Column } from 'react-table';
import { UserDetails } from '../user/user';

export type ProblemSummary = {
    id: string;
    slug: string;
    title: string;
    status: number;
    difficulty: number;
    compilerType: number;
    lecture: LectureSelectableShort | null;
    updatedAt: number[];
};

export type ProblemDetails = {
    id: string;
    compilerType: number;
    lecture: LectureSelectableShort | null;
    title: string;
    slug: string;
    status: number;
    difficulty: number;
    description: string;
    validator: string;
    rewardEnergy: number;
    rewardXp: number;
    filename: string;
    origin: string;
    originAuthor: string;
    updatedAt: number[];
    createdAt: number[];
};

export type ProblemSelectableShort = {
    id: string;
    shortId: string;
    title: string;
    slug: string;
};

export type ProblemUserSummary = {
    id: string;
    title: string;
    slug: string;
    status: number;
    difficulty: number;
    compilerType: number;
    rewardXp: number;
    rewardEnergy: number;
    languageCode: number;
    successRate: number;
    totalCompleted: number;
    totalComments: number;
    tags: TagDetails[];
    solvedStatus: number;
};

export type ProblemDelete = {
    id: string;
};

export type ProblemNew = {
    id?: string;
    contestId?: string;
    userId?: string;
    compilerType: number;
    rewardEnergy: number;
    rewardXp: number;
    status: number;
    title: string;
    filename: string;
    lectureId?: string;
    difficulty: number;
    slug: string;
    origin: string;
    originAuthor: string;
    description: string;
    validator: string;
};

export type ProblemUserDetails = {
    id: string;
    user: UserDetails;
    compilerType: number;
    title: string;
    lecture: LectureSelectableShort | null;
    content: string;
    slug: string;
    status: number;
    difficulty: number;
    rewardEnergy: number;
    rewardXp: number;
    filename: string;
    origin: string;
    originAuthor: string;
    exampleInput: string;
    totalCompleted: number;
    totalComments: number;
    tags: TagDetails[];
    isSolved: boolean;
};

export type PaginatedProblemDetails = {
    items: ProblemSummary[];
    total: number;
};

export type ProblemSearchProps = {
    difficulty?: number;
    page: number;
    sortField: string;
    sortOrder: string;
    status?: number;
    tags?: string[];
    title?: string;
};

export const allProblemsColumnsAdmin: ReadonlyArray<Column> = [
    {
        Header: 'ID',
        accessor: 'id_short',
    },
    {
        Header: 'Status',
        accessor: 'status_badge',
    },
    {
        Header: 'Lecture',
        accessor: 'lecture_badge',
    },
    {
        Header: 'Title',
        accessor: 'title',
    },
    {
        Header: 'Difficulty',
        accessor: 'difficulty_badge',
    },
    {
        Header: 'Data',
        accessor: 'updated_at',
    },
    {
        Header: 'Editare',
        accessor: 'actions',
    },
];

export const allProblemsColumnsUser: ReadonlyArray<Column> = [
    {
        Header: 'Status',
        accessor: 'status_badge',
    },
    {
        Header: 'Titlu',
        accessor: 'title_badge',
    },
    {
        Header: 'Limbaj',
        accessor: 'language_badge',
    },
    {
        Header: 'Rata de succes',
        accessor: 'acceptance_badge',
    },
    {
        Header: 'Dificultate',
        accessor: 'difficulty_badge',
    },
    {
        Header: 'XP',
        accessor: 'reward_xp_badge',
    },
    {
        Header: 'Energie',
        accessor: 'reward_energy_badge',
    },
];

export enum ProblemSolvedStatus {
    UNSOLVED,
    ATTEMPTED,
    SOLVED,
}

export enum ProblemSortOptions {
    TITLE = 'TITLE',
    DIFFICULTY = 'DIFFICULTY',
    UPDATED_AT = 'UPDATED_AT',
    SUCCESS_RATE = 'SUCCESS_RATE',
    REWARD_XP = 'REWARD_XP',
    REWARD_ENERGY = 'REWARD_ENERGY',
}

export enum ProblemSortOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum ProblemStatus {
    HIDDEN,
    ACTIVE,
    SPECIAL,
}

export enum ProblemDifficulty {
    NONE,
    EASY,
    MEDIUM,
    HARD,
}

export enum ProblemCompilerType {
    CONSOLE,
    FILE,
    FUNCTION,
}

export enum ProblemLanguageCode {
    UNKNOWN,
    CPP,
    JAVA,
    PYTHON,
}

export interface UserEvaluatorRun {
    problemId: string;
    sourceCode: string;
    languageId: number;
    testCases: string[];
}

export interface UserEvaluatorSubmit {
    problemId: string;
    sourceCode: string;
    languageId: number;
}

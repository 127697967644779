import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { AdminLogsRequest, AdminTestProblemRequest, AdminTestSourceRequest } from '../../../types/problem/compiler';

const api = new APICore();

function apiAdminViewLanguages() {
    return api.get(`${apiBaseUrl}/compiler/languages`, null);
}

function apiAdminViewLogs(data: AdminLogsRequest) {
    return api.get(`${apiBaseUrl}/compiler/logs/${data.type}/${data.id}`, null);
}

function apiAdminTestSource(data: AdminTestSourceRequest) {
    return api.create(`${apiBaseUrl}/compiler/test_source`, data);
}

function apiAdminTestProblem(data: AdminTestProblemRequest) {
    return api.create(`${apiBaseUrl}/compiler/test_problem`, data);
}

export { apiAdminViewLanguages, apiAdminTestSource, apiAdminTestProblem, apiAdminViewLogs };

import { Navigate, useLocation } from 'react-router-dom';
import { APICore } from 'helpers/api/apiCore';
import { useUser } from 'hooks';
import { UserRoleType } from '../types/user/role';

type PrivateRouteProps = {
    component: React.ComponentType;
    roles?: UserRoleType[];
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const { loggedInUserRoles } = useUser();

    const api = new APICore();

    if (!api.isUserAuthenticated()) {
        return <Navigate to={'/account/login'} state={{ from: location }} replace />;
    }

    if (roles && roles.length > 0 && !roles.some((role) => loggedInUserRoles.includes(role))) {
        return <Navigate to={{ pathname: '/account/login' }} />;
    }

    return <RouteComponent />;
};

export default PrivateRoute;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiAdminCreate, apiAdminUpdate, apiAdminViewAll, apiAdminViewAllShort, apiAdminViewDetails, apiUserViewDetails, apiUserViewRecap } from '../../../helpers/api/theory/lecture';
import { LectureNew, LectureSummary, PaginatedLectureSummary } from '../../../types/theory/lecture';
import { lectureApiResponseSuccess, lectureApiResponseError } from './actions';
import { LectureActionTypes } from './constants';

type LectureDetailsPayloadType = {
    payload: {
        slug: string;
    };
    type: string;
};

type AdminLectureAllPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

type AdminLectureDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

type AdminLectureUpsertPayloadType = {
    payload: LectureNew;
    type: string;
};

function* adminViewAll({ payload: { page, size } }: AdminLectureAllPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll, page, size);
        const responseItems: LectureSummary[] = response.data.items.map((lecture: LectureSummary) => {
            return Object.assign(lecture, {
                id_short: lecture.id.substring(0, 8),
            });
        });
        const responseData: PaginatedLectureSummary = {
            items: responseItems,
            total: response.data.total,
        };
        yield put(lectureApiResponseSuccess(LectureActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(lectureApiResponseError(LectureActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewAllShort(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAllShort);
        yield put(lectureApiResponseSuccess(LectureActionTypes.ADMIN_VIEW_ALL_SHORT, response.data));
    } catch (error: any) {
        yield put(lectureApiResponseError(LectureActionTypes.ADMIN_VIEW_ALL_SHORT, error));
    }
}

function* adminViewDetails({ payload: { id } }: AdminLectureDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(lectureApiResponseSuccess(LectureActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(lectureApiResponseError(LectureActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

function* adminUpsert({ payload }: AdminLectureUpsertPayloadType): SagaIterator {
    try {
        const response = yield call(payload.id ? apiAdminUpdate : apiAdminCreate, payload);
        yield put(lectureApiResponseSuccess(LectureActionTypes.ADMIN_UPSERT, response.data));
    } catch (error: any) {
        yield put(lectureApiResponseError(LectureActionTypes.ADMIN_UPSERT, error));
    }
}

function* userViewDetails({ payload: { slug } }: LectureDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewDetails, slug);
        yield put(lectureApiResponseSuccess(LectureActionTypes.USER_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(lectureApiResponseError(LectureActionTypes.USER_VIEW_DETAILS, error));
    }
}

function* userViewRecap({ payload: { slug } }: LectureDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiUserViewRecap, slug);
        yield put(lectureApiResponseSuccess(LectureActionTypes.USER_VIEW_RECAP, response.data));
    } catch (error: any) {
        yield put(lectureApiResponseError(LectureActionTypes.USER_VIEW_RECAP, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(LectureActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewDetails() {
    yield takeEvery(LectureActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

export function* watchAdminUpsert() {
    yield takeEvery(LectureActionTypes.ADMIN_UPSERT, adminUpsert);
}

export function* watchUserViewDetails() {
    yield takeEvery(LectureActionTypes.USER_VIEW_DETAILS, userViewDetails);
}

export function* watchAdminViewAllShort() {
    yield takeEvery(LectureActionTypes.ADMIN_VIEW_ALL_SHORT, adminViewAllShort);
}

export function* watchUserViewRecap() {
    yield takeEvery(LectureActionTypes.USER_VIEW_RECAP, userViewRecap);
}

function* LectureSaga() {
    yield all([fork(watchUserViewDetails), fork(watchAdminViewAll), fork(watchAdminViewDetails), fork(watchAdminUpsert), fork(watchAdminViewAllShort), fork(watchUserViewRecap)]);
}

export default LectureSaga;

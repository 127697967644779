import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiAdminViewAll, apiAdminViewDetails } from '../../../helpers/api/log/security';
import { SecurityLogSummary } from '../../../types/log/security';
import { SecurityLogActionTypes } from './constants';
import { securityLogApiResponseError, securityLogApiResponseSuccess } from './actions';

type AdminLogAllPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

type AdminLogDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

function* adminViewAll({ payload: { page, size } }: AdminLogAllPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll, page, size);
        yield put(securityLogApiResponseSuccess(SecurityLogActionTypes.ADMIN_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(securityLogApiResponseError(SecurityLogActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewDetails({ payload: { id } }: AdminLogDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(securityLogApiResponseSuccess(SecurityLogActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(securityLogApiResponseError(SecurityLogActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(SecurityLogActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewDetails() {
    yield takeEvery(SecurityLogActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

function* SecurityLogSaga() {
    yield all([fork(watchAdminViewAll), fork(watchAdminViewDetails)]);
}

export default SecurityLogSaga;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiAdminViewAllAnswers, apiAdminCreate, apiAdminUpdate, apiAdminViewAll, apiAdminViewAllShort, apiAdminViewDetails, apiAdminViewByLecture } from '../../../helpers/api/quiz/question';
import { questionApiResponseSuccess, questionApiResponseError } from './actions';
import { PaginatedQuestionSummary, QuestionNew, QuestionSummary } from '../../../types/quiz/question';
import { QuestionActionTypes } from './constants';
import { AnswerDetails } from '../../../types/quiz/answer';

type AdminQuestionAllPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

type AdminQuestionDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

type AdminQuestionsByLecturePayloadType = {
    payload: {
        lectureId: string;
    };
    type: string;
};

type AdminQuestionUpsertPayloadType = {
    payload: QuestionNew;
    type: string;
};

function* adminViewAll({ payload: { page, size } }: AdminQuestionAllPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll, page, size);
        const responseItems: QuestionSummary[] = response.data.items.map((chapter: QuestionSummary) => {
            return Object.assign(chapter, {
                id_short: chapter.id.substring(0, 8),
                title_short: chapter.title.substring(0, 64),
            });
        });
        const responseData: PaginatedQuestionSummary = {
            items: responseItems,
            total: response.data.total,
        };

        yield put(questionApiResponseSuccess(QuestionActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(questionApiResponseError(QuestionActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewByLecture({ payload: { lectureId } }: AdminQuestionsByLecturePayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewByLecture, lectureId);
        yield put(questionApiResponseSuccess(QuestionActionTypes.ADMIN_VIEW_BY_LECTURE, response.data));
    } catch (error: any) {
        yield put(questionApiResponseError(QuestionActionTypes.ADMIN_VIEW_BY_LECTURE, error));
    }
}

function* adminViewAllAnswers({ payload: { id } }: AdminQuestionDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAllAnswers, id);
        const responseItems: AnswerDetails[] = response.data.map((answer: AnswerDetails) => {
            return Object.assign(answer, {
                id_short: answer.id.substring(0, 8),
            });
        });
        console.log(responseItems);
        yield put(questionApiResponseSuccess(QuestionActionTypes.ADMIN_VIEW_ALL_ANSWERS, responseItems));
    } catch (error: any) {
        yield put(questionApiResponseError(QuestionActionTypes.ADMIN_VIEW_ALL_ANSWERS, error));
    }
}

function* adminViewAllShort(): SagaIterator {
    try {
        const response = yield call(apiAdminViewAllShort);
        yield put(questionApiResponseSuccess(QuestionActionTypes.ADMIN_VIEW_ALL_SHORT, response.data));
    } catch (error: any) {
        yield put(questionApiResponseError(QuestionActionTypes.ADMIN_VIEW_ALL_SHORT, error));
    }
}

function* adminViewDetails({ payload: { id } }: AdminQuestionDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(questionApiResponseSuccess(QuestionActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(questionApiResponseError(QuestionActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

function* adminUpsert({ payload }: AdminQuestionUpsertPayloadType): SagaIterator {
    try {
        const response = yield call(payload.id ? apiAdminUpdate : apiAdminCreate, payload);
        yield put(questionApiResponseSuccess(QuestionActionTypes.ADMIN_UPSERT, response.data));
    } catch (error: any) {
        yield put(questionApiResponseError(QuestionActionTypes.ADMIN_UPSERT, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(QuestionActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewAllShort() {
    yield takeEvery(QuestionActionTypes.ADMIN_VIEW_ALL_SHORT, adminViewAllShort);
}

export function* watchAdminViewDetails() {
    yield takeEvery(QuestionActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

export function* watchAdminUpsert() {
    yield takeEvery(QuestionActionTypes.ADMIN_UPSERT, adminUpsert);
}

export function* watchAdminViewAllAnswers() {
    yield takeEvery(QuestionActionTypes.ADMIN_VIEW_ALL_ANSWERS, adminViewAllAnswers);
}

export function* watchAdminViewByLecture() {
    yield takeEvery(QuestionActionTypes.ADMIN_VIEW_BY_LECTURE, adminViewByLecture);
}

function* QuestionSaga() {
    yield all([fork(watchAdminViewByLecture), fork(watchAdminViewAll), fork(watchAdminViewAllShort), fork(watchAdminViewDetails), fork(watchAdminUpsert), fork(watchAdminViewAllAnswers)]);
}

export default QuestionSaga;

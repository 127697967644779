import { DependencyActionTypes } from './constants';
import { DependencyDetails, DependencySummary } from '../../../types/cms/dependency';
import { ModuleSelectableShort } from '../../../types/theory/module';
import { ChapterSelectableShort } from '../../../types/theory/chapter';
import { LectureSelectableShort } from '../../../types/theory/lecture';

const INIT_STATE = {
    loading: false,
    adminAllDependencies: null,
    adminDependencyDetails: null,
    updatedDependency: null,
    deletedDependency: null,
    mappedModules: null,
    mappedChapters: null,
    mappedLectures: null,
    apiSuccess: false,
};

type DependencyActionType = {
    type:
        | DependencyActionTypes.API_RESPONSE_SUCCESS
        | DependencyActionTypes.ADMIN_UPDATE
        | DependencyActionTypes.ADMIN_VIEW
        | DependencyActionTypes.ADMIN_DELETE
        | DependencyActionTypes.API_RESPONSE_ERROR
        | DependencyActionTypes.ADMIN_VIEW_ALL
        | DependencyActionTypes.ADMIN_MAP_ALL_LECTURES
        | DependencyActionTypes.ADMIN_MAP_ALL_MODULES
        | DependencyActionTypes.ADMIN_MAP_ALL_CHAPTERS
        | DependencyActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    adminAllDependencies: DependencyDetails[] | null;
    adminDependencyDetails: DependencySummary | null;
    updatedDependency: DependencySummary | null;
    deletedDependency: boolean | null;
    mappedModules: ModuleSelectableShort[] | null;
    mappedChapters: ChapterSelectableShort[] | null;
    mappedLectures: LectureSelectableShort[] | null;
    loading?: boolean;
    apiSuccess: boolean;
};

const Dependency = (state: State = INIT_STATE, action: DependencyActionType) => {
    switch (action.type) {
        case DependencyActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DependencyActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllDependencies: action.payload.data,
                        loading: false,
                    };
                }
                case DependencyActionTypes.ADMIN_VIEW: {
                    return {
                        ...state,
                        adminDependencyDetails: action.payload.data,
                        loading: false,
                    };
                }
                case DependencyActionTypes.ADMIN_UPDATE: {
                    return {
                        ...state,
                        updatedDependency: action.payload.data,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                case DependencyActionTypes.ADMIN_DELETE: {
                    return {
                        ...state,
                        deletedDependency: action.payload.data,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                case DependencyActionTypes.ADMIN_MAP_ALL_MODULES: {
                    return {
                        ...state,
                        mappedModules: action.payload.data,
                        loading: false,
                    };
                }
                case DependencyActionTypes.ADMIN_MAP_ALL_CHAPTERS: {
                    return {
                        ...state,
                        mappedChapters: action.payload.data,
                        loading: false,
                    };
                }
                case DependencyActionTypes.ADMIN_MAP_ALL_LECTURES: {
                    return {
                        ...state,
                        mappedLectures: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case DependencyActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DependencyActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        adminAllDependencies: null,
                        loading: false,
                    };
                }
                case DependencyActionTypes.ADMIN_VIEW: {
                    return {
                        ...state,
                        error: action.payload.error,
                        adminDependencyDetails: null,
                        loading: false,
                    };
                }
                case DependencyActionTypes.ADMIN_DELETE: {
                    return {
                        ...state,
                        deletedDependency: null,
                        apiSuccess: false,
                        loading: false,
                    };
                }
                case DependencyActionTypes.ADMIN_UPDATE: {
                    return {
                        ...state,
                        updatedDependency: null,
                        apiSuccess: false,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case DependencyActionTypes.ADMIN_MAP_ALL_MODULES: {
                    return {
                        ...state,
                        mappedModules: null,
                        loading: false,
                    };
                }
                case DependencyActionTypes.ADMIN_MAP_ALL_CHAPTERS: {
                    return {
                        ...state,
                        mappedChapters: null,
                        loading: false,
                    };
                }
                case DependencyActionTypes.ADMIN_MAP_ALL_LECTURES: {
                    return {
                        ...state,
                        mappedLectures: null,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
        case DependencyActionTypes.ADMIN_VIEW_ALL: {
            return {
                ...state,
                adminAllDependencies: null,
                error: false,
                loading: false,
            };
        }
        case DependencyActionTypes.ADMIN_VIEW: {
            return {
                ...state,
                adminDependencyDetails: null,
                error: false,
                loading: false,
            };
        }
        case DependencyActionTypes.ADMIN_UPDATE: {
            return {
                ...state,
                updatedDependency: null,
                apiSuccess: false,
                error: false,
                loading: false,
            };
        }
        case DependencyActionTypes.ADMIN_DELETE: {
            return {
                ...state,
                deletedDependency: null,
                apiSuccess: false,
                loading: false,
            };
        }
        case DependencyActionTypes.ADMIN_MAP_ALL_MODULES: {
            return {
                ...state,
                mappedModules: null,
                loading: false,
                error: false,
            };
        }
        case DependencyActionTypes.ADMIN_MAP_ALL_CHAPTERS: {
            return {
                ...state,
                mappedChapters: null,
                loading: false,
                error: false,
            };
        }
        case DependencyActionTypes.ADMIN_MAP_ALL_LECTURES: {
            return {
                ...state,
                mappedLectures: null,
                loading: false,
                error: false,
            };
        }
        default:
            return { ...state };
    }
};

export default Dependency;

import io from 'socket.io-client';

//const socket = io('http://127.0.0.1:3003', {
const socket = io('https://sockets.thecodingplatform.com', {
    path: '/io',
    reconnection: true,
    rejectUnauthorized: false,
    transports: ['websocket'],
    reconnectionDelay: 1000,
});

export { socket };

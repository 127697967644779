import { FriendshipActionTypes } from './constants';
import { FriendDetails, FriendshipStatus, FriendshipDetails } from '../../../types/user/friendship';

const INIT_STATE = {
    apiSuccess: false,
    error: null,
    friendshipRelation: null,
    loading: false,
    selectedType: 0,
    lastFriends: null,
    friendsCount: null,
    allFriends: null,
    allFriendRequests: null,
    friendshipDestroyedSuccess: null,
    friendshipStatusChanged: null,
};

type NotificationActionType = {
    type:
        | FriendshipActionTypes.API_RESPONSE_ERROR
        | FriendshipActionTypes.API_RESPONSE_SUCCESS
        | FriendshipActionTypes.USER_VIEW_LAST
        | FriendshipActionTypes.USER_VIEW_ALL
        | FriendshipActionTypes.USER_ACCEPT_FRIENDSHIP
        | FriendshipActionTypes.USER_CHECK_FRIENDSHIP
        | FriendshipActionTypes.USER_SEND_REQUEST
        | FriendshipActionTypes.USER_DESTROY_FRIENDSHIP
        | FriendshipActionTypes.USER_COUNT_FRIENDS
        | FriendshipActionTypes.RESET;

    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    apiSuccess?: boolean;
    friendshipRelation: FriendshipDetails | null;
    loading?: boolean;
    error?: string | null;
    lastFriends: FriendDetails[] | null;
    allFriends: FriendDetails[] | null;
    friendsCount: number | null;
    friendshipDestroyedSuccess: boolean | null;
    allFriendRequests: FriendDetails[] | null;
    friendshipStatusChanged: number | null;
};

const Friendship = (state: State = INIT_STATE, action: NotificationActionType) => {
    switch (action.type) {
        case FriendshipActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case FriendshipActionTypes.USER_VIEW_LAST: {
                    return {
                        ...state,
                        loading: false,
                        lastFriends: action.payload.data,
                        apiSuccess: true,
                    };
                }
                case FriendshipActionTypes.USER_CHECK_FRIENDSHIP: {
                    return {
                        ...state,
                        loading: false,
                        friendshipRelation: action.payload.data,
                        apiSuccess: true,
                    };
                }
                case FriendshipActionTypes.USER_COUNT_FRIENDS: {
                    return {
                        ...state,
                        loading: false,
                        friendsCount: action.payload.data,
                        apiSuccess: true,
                    };
                }
                case FriendshipActionTypes.USER_VIEW_ALL_INCOMING: {
                    return {
                        ...state,
                        loading: false,
                        allFriendRequests: action.payload.data,
                        apiSuccess: true,
                    };
                }
                case FriendshipActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        loading: false,
                        allFriends: action.payload.data,
                        apiSuccess: true,
                    };
                }
                case FriendshipActionTypes.USER_DESTROY_FRIENDSHIP: {
                    return {
                        ...state,
                        loading: false,
                        friendshipStatusChanged: FriendshipStatus.REJECTED,
                        apiSuccess: true,
                    };
                }
                case FriendshipActionTypes.USER_ACCEPT_FRIENDSHIP: {
                    return {
                        ...state,
                        loading: false,
                        friendshipStatusChanged: FriendshipStatus.ACCEPTED,
                        apiSuccess: true,
                    };
                }
                case FriendshipActionTypes.USER_SEND_REQUEST: {
                    return {
                        ...state,
                        loading: false,
                        friendshipStatusChanged: FriendshipStatus.PENDING,
                        apiSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }
        case FriendshipActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case FriendshipActionTypes.USER_VIEW_LAST:
                    return {
                        error: action.payload.error,
                        loading: true,
                        apiSuccess: false,
                    };
                case FriendshipActionTypes.USER_CHECK_FRIENDSHIP: {
                    return {
                        ...state,
                        loading: false,
                        friendshipRelation: null,
                        apiSuccess: true,
                    };
                }
                case FriendshipActionTypes.USER_DESTROY_FRIENDSHIP:
                    return {
                        error: action.payload.error,
                        loading: true,
                        apiSuccess: false,
                    };
                case FriendshipActionTypes.USER_VIEW_ALL:
                    return {
                        error: action.payload.error,
                        loading: true,
                        apiSuccess: false,
                    };
                case FriendshipActionTypes.USER_COUNT_FRIENDS:
                    return {
                        error: action.payload.error,
                        loading: true,
                        apiSuccess: false,
                    };
                default:
                    return { ...state };
            }
        case FriendshipActionTypes.USER_DESTROY_FRIENDSHIP:
            return {
                ...state,
                loading: false,
                apiSuccess: false,
                friendshipStatusChanged: null,
            };
        case FriendshipActionTypes.USER_CHECK_FRIENDSHIP: {
            return {
                ...state,
                loading: false,
                apiSuccess: true,
            };
        }
        case FriendshipActionTypes.USER_ACCEPT_FRIENDSHIP:
            return {
                ...state,
                loading: false,
                apiSuccess: false,
                friendshipStatusChanged: null,
            };
        case FriendshipActionTypes.USER_SEND_REQUEST: {
            return {
                ...state,
                loading: false,
                apiSuccess: false,
                friendshipStatusChanged: null,
            };
        }
        default:
            return { ...state };
    }
};

export default Friendship;

import { EvaluatorActionTypes } from './constants';
import { CompilerLanguage } from '../../../types/problem/compiler';
import { TabItem } from '../../../types/tabItem';
import { EvaluatorTestCase } from './reducers';

export type EvaluatorActionType = {
    type:
        | EvaluatorActionTypes.USER_ADD_TEST_CASE
        | EvaluatorActionTypes.USER_CHANGE_OUT_TAB
        | EvaluatorActionTypes.USER_CHANGE_TEST_CASE
        | EvaluatorActionTypes.USER_REMOVE_TEST_CASE
        | EvaluatorActionTypes.USER_UPDATE_LANGUAGE
        | EvaluatorActionTypes.USER_UPDATE_SOURCE_CODE
        | EvaluatorActionTypes.USER_UPDATE_TEST_CASE
        | EvaluatorActionTypes.USER_SET_FIRST_TEST_CASE;
    payload: {} | string;
};

export const evaluatorAddTestCase = (tab: TabItem): EvaluatorActionType => ({
    type: EvaluatorActionTypes.USER_ADD_TEST_CASE,
    payload: { data: tab },
});

export const evaluatorChangeOutTab = (tabKey: string): EvaluatorActionType => ({
    type: EvaluatorActionTypes.USER_CHANGE_OUT_TAB,
    payload: { data: tabKey },
});

export const evaluatorChangeTestCase = (tabKey: number): EvaluatorActionType => ({
    type: EvaluatorActionTypes.USER_CHANGE_TEST_CASE,
    payload: { data: tabKey },
});

export const evaluatorRemoveTestCase = (): EvaluatorActionType => ({
    type: EvaluatorActionTypes.USER_REMOVE_TEST_CASE,
    payload: {},
});

export const evaluatorUpdateLanguage = (language: CompilerLanguage): EvaluatorActionType => ({
    type: EvaluatorActionTypes.USER_UPDATE_LANGUAGE,
    payload: { data: language },
});

export const evaluatorUpdateSourceCode = (sourceCode: string): EvaluatorActionType => ({
    type: EvaluatorActionTypes.USER_UPDATE_SOURCE_CODE,
    payload: { data: sourceCode },
});

export const evaluatorUpdateTestCase = (data: EvaluatorTestCase): EvaluatorActionType => ({
    type: EvaluatorActionTypes.USER_UPDATE_TEST_CASE,
    payload: { data: data.input, dataIndex: data.index },
});

export const evaluatorSetFirstTestCase = (tab: TabItem): EvaluatorActionType => ({
    type: EvaluatorActionTypes.USER_SET_FIRST_TEST_CASE,
    payload: { data: tab },
});

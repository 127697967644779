export const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://127.0.0.1:3000';
export const breadcrumbs = 'PDI';
export const captchaSiteKey = '6LdB5uMmAAAAAH-u-6vw6kBbfIN80o5OSLl6ljtQ';
export const companyName = 'TPN LABS';
export const companyWebsite = 'TPN-LABS.COM';
export const defaultPageSize = 15;
export const googleTagManagerId = 'GTM-KJ8XMW3';
export const googleAdsenseId = 'ca-pub-6148865456800167';
export const platformName = 'Platforma-De.INFO';
export const sentryDSN = process.env.REACT_APP_SENTRY_DSN || null;
export const yearOfFoundation = 2019;
export const secInHour = 3600;
export const secInMinute = 60;

import { SettingsActionTypes } from './constants';
import { PersonalDataBody, PreferenceResponse, ProfileDataBody, UserDeleteAccount, UserNewPassword } from '../../../types/user/settings';

export type SettingsActionType = {
    type:
        | SettingsActionTypes.API_RESPONSE_ERROR
        | SettingsActionTypes.API_RESPONSE_SUCCESS
        | SettingsActionTypes.USER_UPDATE_AVATAR
        | SettingsActionTypes.USER_UPDATE_PASSWORD
        | SettingsActionTypes.USER_UPDATE_PERSONAL_DATA
        | SettingsActionTypes.USER_UPDATE_PREFERENCE_DATA
        | SettingsActionTypes.USER_UPDATE_PROFILE_DATA
        | SettingsActionTypes.USER_VIEW_PERSONAL_DATA
        | SettingsActionTypes.USER_VIEW_PREFERENCE_DATA
        | SettingsActionTypes.USER_VIEW_PROFILE_DATA
        | SettingsActionTypes.USER_DELETE_ACCOUNT
        | SettingsActionTypes.RESET;
    payload: {} | string;
};

export const settingsApiResponseSuccess = (actionType: string, data: {}): SettingsActionType => ({
    type: SettingsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const settingsApiResponseError = (actionType: string, error: string): SettingsActionType => ({
    type: SettingsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const settingsUserViewPersonalData = (): SettingsActionType => ({
    type: SettingsActionTypes.USER_VIEW_PERSONAL_DATA,
    payload: {},
});

export const settingsUserViewProfileData = (): SettingsActionType => ({
    type: SettingsActionTypes.USER_VIEW_PROFILE_DATA,
    payload: {},
});

export const settingsUserViewPreferenceData = (): SettingsActionType => ({
    type: SettingsActionTypes.USER_VIEW_PREFERENCE_DATA,
    payload: {},
});

export const settingsUserUpdatePersonalData = (data: PersonalDataBody): SettingsActionType => ({
    type: SettingsActionTypes.USER_UPDATE_PERSONAL_DATA,
    payload: data,
});

export const settingsUserUpdateProfileData = (data: ProfileDataBody): SettingsActionType => ({
    type: SettingsActionTypes.USER_UPDATE_PROFILE_DATA,
    payload: data,
});

export const settingsUserUpdateAvatar = (data: FormData): SettingsActionType => ({
    type: SettingsActionTypes.USER_UPDATE_AVATAR,
    payload: data,
});

export const settingsUserUpdatePassword = (data: UserNewPassword): SettingsActionType => ({
    type: SettingsActionTypes.USER_UPDATE_PASSWORD,
    payload: data,
});

export const settingsUserUpdatePreferenceData = (data: PreferenceResponse): SettingsActionType => ({
    type: SettingsActionTypes.USER_UPDATE_PREFERENCE_DATA,
    payload: data,
});

export const settingsUserDeleteAccount = (data: UserDeleteAccount): SettingsActionType => ({
    type: SettingsActionTypes.USER_DELETE_ACCOUNT,
    payload: data,
});

export const resetSettings = (): SettingsActionType => ({
    type: SettingsActionTypes.RESET,
    payload: {},
});

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiAdminUploadImage, apiAdminViewAvatars, apiAdminViewImages, apiAdminViewShort } from '../../helpers/api/cms/photo';
import { AdminGalleryActionTypes } from './constants';
import { adminGalleryApiResponseError, adminGalleryApiResponseSuccess } from './actions';

type AdminGalleryPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

function* adminViewAllImages({ payload: { page, size } }: AdminGalleryPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewImages, page, size);
        yield put(adminGalleryApiResponseSuccess(AdminGalleryActionTypes.ADMIN_IMAGES_ALL, response.data));
    } catch (error: any) {
        yield put(adminGalleryApiResponseError(AdminGalleryActionTypes.ADMIN_IMAGES_ALL, error));
    }
}

function* adminViewAllAvatars({ payload: { page, size } }: AdminGalleryPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAvatars, page, size);
        yield put(adminGalleryApiResponseSuccess(AdminGalleryActionTypes.ADMIN_AVATARS_ALL, response.data));
    } catch (error: any) {
        yield put(adminGalleryApiResponseError(AdminGalleryActionTypes.ADMIN_AVATARS_ALL, error));
    }
}

function* adminUploadImage({ payload }: any): SagaIterator {
    try {
        const response = yield call(apiAdminUploadImage, payload);
        yield put(adminGalleryApiResponseSuccess(AdminGalleryActionTypes.ADMIN_IMAGES_UPLOAD, response.data));
    } catch (error: any) {
        yield put(adminGalleryApiResponseError(AdminGalleryActionTypes.ADMIN_IMAGES_UPLOAD, error));
    }
}

function* adminViewAllImagesShort(): SagaIterator {
    try {
        const response = yield call(apiAdminViewShort);
        yield put(adminGalleryApiResponseSuccess(AdminGalleryActionTypes.ADMIN_IMAGES_ALL_SHORT, response.data));
    } catch (error: any) {
        yield put(adminGalleryApiResponseError(AdminGalleryActionTypes.ADMIN_IMAGES_ALL_SHORT, error));
    }
}

function* watchAdminViewAllImagesShort() {
    yield takeEvery(AdminGalleryActionTypes.ADMIN_IMAGES_ALL_SHORT, adminViewAllImagesShort);
}

function* watchAdminViewAllAvatars() {
    yield takeEvery(AdminGalleryActionTypes.ADMIN_AVATARS_ALL, adminViewAllAvatars);
}

function* watchAdminViewAllImages() {
    yield takeEvery(AdminGalleryActionTypes.ADMIN_IMAGES_ALL, adminViewAllImages);
}

function* watchAdminUploadImage() {
    yield takeEvery(AdminGalleryActionTypes.ADMIN_IMAGES_UPLOAD, adminUploadImage);
}

function* AdminPanelSaga() {
    yield all([fork(watchAdminViewAllImagesShort), fork(watchAdminViewAllImages), fork(watchAdminViewAllAvatars), fork(watchAdminUploadImage)]);
}

export default AdminPanelSaga;

export enum ModuleActionTypes {
    API_RESPONSE_SUCCESS = '@@Module/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@Module/API_RESPONSE_ERROR',

    USER_VIEW_CHAPTERS = '@@Module/USER_VIEW_CHAPTERS',
    USER_VIEW_DETAILS = '@@Module/USER_VIEW_DETAILS',
    USER_VIEW_PROGRESS = '@@Module/USER_VIEW_PROGRESS',
    USER_VIEW_ALL = '@@Module/USER_VIEW_ALL',
    USER_DEFAULT_TAB = '@@Module/USER_DEFAULT_TAB',

    ADMIN_VIEW_ALL = '@@Module/ADMIN_VIEW_ALL',
    ADMIN_VIEW_ALL_SHORT = '@@Module/ADMIN_VIEW_ALL_SHORT',
    ADMIN_VIEW_DETAILS = '@@Module/ADMIN_VIEW_DETAILS',
    ADMIN_UPSERT = '@@Module/ADMIN_UPSERT',

    RESET = '@@Module/RESET',
}

export enum SeoActionTypes {
    API_RESPONSE_SUCCESS = '@@seo/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@seo/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL = '@@seo/ADMIN_VIEW_ALL',
    ADMIN_VIEW_DETAILS = '@@seo/ADMIN_VIEW_DETAILS',
    ADMIN_DELETE = '@@seo/ADMIN_DELETE',
    ADMIN_UPSERT = '@@seo/ADMIN_UPSERT',
    USER_VIEW_DETAILS = '@@seo/USER_VIEW_DETAILS',

    RESET = '@@seo/RESET',
}

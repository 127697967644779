import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants/platform';
import { TicketNew, TicketReplyNew } from '../../../types/support/supportTicket';

const api = new APICore();

function apiUserCreate(ticket: TicketNew) {
    return api.create(`${apiBaseUrl}/tickets`, ticket);
}

function apiUserViewAll() {
    return api.get(`${apiBaseUrl}/tickets`, null);
}

function apiUserReply(reply: TicketReplyNew) {
    return api.create(`${apiBaseUrl}/tickets/reply`, reply);
}

function apiUserViewDetails(ticketId: String) {
    return api.get(`${apiBaseUrl}/tickets/${ticketId}`, null);
}

function apiAdminViewAll() {
    return api.get(`${apiBaseUrl}/tickets/admin`, null);
}

function apiAdminViewArchived() {
    return api.get(`${apiBaseUrl}/tickets/admin/archived`, null);
}

function apiAdminViewDetails(ticketId: String) {
    return api.get(`${apiBaseUrl}/tickets/admin/${ticketId}`, null);
}

function apiAdminReply(reply: TicketReplyNew) {
    return api.create(`${apiBaseUrl}/tickets/admin`, reply);
}

function apiAdminClose(ticketId: String) {
    return api.create(`${apiBaseUrl}/tickets/admin/${ticketId}/close`, null);
}

function apiAdminArchive(ticketId: String) {
    return api.create(`${apiBaseUrl}/tickets/admin/${ticketId}/archive`, null);
}

export {
    apiAdminArchive,
    apiAdminClose,
    apiAdminReply,
    apiAdminViewAll,
    apiAdminViewArchived,
    apiAdminViewDetails,
    apiUserCreate,
    apiUserReply,
    apiUserViewDetails,
    apiUserViewAll,
};

import { EnergyActionTypes } from './constants';

export type EnergyActionType = {
    type: EnergyActionTypes.ADMIN_VIEW_ALL | EnergyActionTypes.USER_VIEW_ALL | EnergyActionTypes.API_RESPONSE_ERROR | EnergyActionTypes.API_RESPONSE_SUCCESS | EnergyActionTypes.RESET;
    payload: {} | string;
};

export const energyAdminViewAll = (): EnergyActionType => ({
    type: EnergyActionTypes.ADMIN_VIEW_ALL,
    payload: {},
});

export const energyUserViewAll = (page: number, size: number): EnergyActionType => ({
    type: EnergyActionTypes.USER_VIEW_ALL,
    payload: { page, size },
});

export const energyApiResponseSuccess = (actionType: string, data: {}): EnergyActionType => ({
    type: EnergyActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const energyApiResponseError = (actionType: string, error: string): EnergyActionType => ({
    type: EnergyActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

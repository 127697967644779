import { QuizActionTypes } from './constants';
import { QuestionUserDetails } from '../../../types/theory/quiz';

export type QuizActionType = {
    type: QuizActionTypes.API_RESPONSE_SUCCESS | QuizActionTypes.API_RESPONSE_ERROR | QuizActionTypes.USER_VIEW_LECTURE_QUESTION | QuizActionTypes.USER_ANSWER_QUESTION | QuizActionTypes.RESET;
    payload: {} | string;
};

export const quizApiResponseSuccess = (actionType: string, data: QuestionUserDetails | {}): QuizActionType => ({
    type: QuizActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const quizApiResponseError = (actionType: string, error: string): QuizActionType => ({
    type: QuizActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const quizUserViewLectureQuestion = (lectureId: string): QuizActionType => ({
    type: QuizActionTypes.USER_VIEW_LECTURE_QUESTION,
    payload: { lectureId },
});

export const quizUserAnswerQuestion = (questionId: string, answerIds: string[]): QuizActionType => ({
    type: QuizActionTypes.USER_ANSWER_QUESTION,
    payload: { questionId, answerIds },
});

export const quizUserReset = (): QuizActionType => ({
    type: QuizActionTypes.RESET,
    payload: {},
});

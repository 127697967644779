import { AnswerActionTypes } from './constants';
import { AnswerDetails, AnswerNew } from '../../../types/quiz/answer';

export type AnswerActionType = {
    type:
        | AnswerActionTypes.API_RESPONSE_SUCCESS
        | AnswerActionTypes.API_RESPONSE_ERROR
        | AnswerActionTypes.ADMIN_VIEW_ALL
        | AnswerActionTypes.ADMIN_VIEW_ALL_SHORT
        | AnswerActionTypes.ADMIN_VIEW_DETAILS
        | AnswerActionTypes.ADMIN_DELETE
        | AnswerActionTypes.ADMIN_UPSERT
        | AnswerActionTypes.RESET;
    payload: {} | string;
};

export const answerApiResponseSuccess = (actionType: string, data: AnswerDetails | {}): AnswerActionType => ({
    type: AnswerActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const answerApiResponseError = (actionType: string, error: string): AnswerActionType => ({
    type: AnswerActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const answerAdminViewAll = (page: number, size: number): AnswerActionType => ({
    type: AnswerActionTypes.ADMIN_VIEW_ALL,
    payload: { page, size },
});

export const answerAdminViewAllShort = (): AnswerActionType => ({
    type: AnswerActionTypes.ADMIN_VIEW_ALL_SHORT,
    payload: {},
});

export const answerAdminViewDetails = (id: string): AnswerActionType => ({
    type: AnswerActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

export const answerAdminUpsert = (data: AnswerNew): AnswerActionType => ({
    type: AnswerActionTypes.ADMIN_UPSERT,
    payload: data,
});

export enum ProblemHintActionTypes {
    API_RESPONSE_SUCCESS = '@@problemHint/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@problemHint/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL_PROBLEM = '@@problemHint/ADMIN_VIEW_ALL_PROBLEM',
    ADMIN_VIEW = '@@problemHint/ADMIN_VIEW',
    ADMIN_UPSERT = '@@problemHint/ADMIN_UPSERT',
    ADMIN_DELETE = '@@problemHint/ADMIN_DELETE',

    USER_VIEW_ALL_PROBLEM = '@@problemHint/USER_VIEW_ALL_PROBLEM',
    USER_VIEW = '@@problemHint/USER_VIEW',
}

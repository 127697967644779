import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { MessageNew } from '../../../types/user/chat';

const api = new APICore();

function apiUserViewAllContacts() {
    return api.get(`${apiBaseUrl}/conversations`, null);
}

function apiAutoReadConversation(id: string) {
    return api.get(`${apiBaseUrl}/conversations/auto-read/${id}`, null);
}

function apiUserChangeConversationStatus(id: string, status: number) {
    return api.create(`${apiBaseUrl}/conversations`, { id, status });
}

function apiUserViewConversation(id: string) {
    return api.get(`${apiBaseUrl}/conversations/load/${id}`, null);
}

function apiUserSendMessage(data: MessageNew) {
    return api.create(`${apiBaseUrl}/conversations/send-message`, data);
}

function apiUserLoadFriends() {
    return api.get(`${apiBaseUrl}/conversations/load-friends`, null);
}

export { apiUserLoadFriends, apiUserSendMessage, apiUserViewAllContacts, apiAutoReadConversation, apiUserViewConversation, apiUserChangeConversationStatus };

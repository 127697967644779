import { Moment } from 'moment';

export interface NotificationDetails {
    id: string;
    time: Moment;
    title: string;
    type: number;
    message: string;
    isRead: boolean;
    variant: string;
    readStatus: number;
    icon: string;
    createdAt: number[];
}

export interface UserNotificationMenuType {
    key: string;
    color: string;
    icon: string;
}

export const userNotificationMenuItems: UserNotificationMenuType[] = [
    {
        key: 'all_notifications',
        color: 'dark',
        icon: 'mdi-inbox',
    },
    {
        key: 'friend_requests',
        color: 'primary',
        icon: 'mdi-account-multiple-plus',
    },
    {
        key: 'medals',
        color: 'primary',
        icon: 'mdi-medal-outline',
    },
    {
        key: 'competitions',
        color: 'info',
        icon: 'mdi-trophy-outline',
    },
    {
        key: 'comment_reply',
        color: 'info',
        icon: 'mdi-comment-arrow-right-outline',
    },
    {
        key: 'received_assignments',
        color: 'primary',
        icon: 'mdi-notebook-outline',
    },
    {
        key: 'completed_lectures',
        color: 'info',
        icon: 'mdi-sticker-check-outline',
    },
    {
        key: 'private_messages',
        color: 'warning',
        icon: 'mdi-chat-processing-outline',
    },
    {
        key: 'payment_confirmed',
        color: 'warning',
        icon: 'mdi-cart',
    },
    {
        key: 'level_up',
        color: 'primary',
        icon: 'mdi-arrow-up-bold',
    },
    {
        key: 'ticket_reply',
        color: 'blue',
        icon: 'mdi-lifebuoy',
    },
];

import { ReportActionTypes } from './constants';
import { ReportNew } from '../../../types/support/supportReport';

export type ReportActionType = {
    type:
        | ReportActionTypes.USER_VIEW_ALL
        | ReportActionTypes.USER_CREATE
        | ReportActionTypes.RESET
        | ReportActionTypes.API_RESPONSE_SUCCESS
        | ReportActionTypes.API_RESPONSE_ERROR
        | ReportActionTypes.ADMIN_VIEW_ALL
        | ReportActionTypes.ADMIN_VIEW_DETAILS
        | ReportActionTypes.ADMIN_CLOSE;
    payload: {} | string;
};

export const reportUserViewAll = (): ReportActionType => ({
    type: ReportActionTypes.USER_VIEW_ALL,
    payload: {},
});

export const reportAdminViewAll = (): ReportActionType => ({
    type: ReportActionTypes.ADMIN_VIEW_ALL,
    payload: {},
});

export const reportAdminViewDetails = (id: string, type: string): ReportActionType => ({
    type: ReportActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id, type },
});

export const reportAdminClose = (id: string, type: string, status: number): ReportActionType => ({
    type: ReportActionTypes.ADMIN_CLOSE,
    payload: { id, type, status },
});

export const reportUserCreate = (reportData: ReportNew): ReportActionType => ({
    type: ReportActionTypes.USER_CREATE,
    payload: reportData,
});

export const reportApiResponseSuccess = (actionType: string, data: {}): ReportActionType => ({
    type: ReportActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const reportApiResponseError = (actionType: string, error: string): ReportActionType => ({
    type: ReportActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

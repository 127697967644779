import { TimespentActionTypes } from './constants';
import { TimespentDetails, TimespentResponse } from '../../../../types/user/logs/timespent';

const INIT_STATE = {
    apiSuccess: false,
    loading: false,
    adminAllTimespentLogs: null,
    userCreateResponse: null,
};

type TimespentActionType = {
    type: TimespentActionTypes.API_RESPONSE_ERROR | TimespentActionTypes.API_RESPONSE_SUCCESS | TimespentActionTypes.ADMIN_VIEW_ALL | TimespentActionTypes.USER_CREATE;

    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    adminAllTimespentLogs?: TimespentDetails[] | null;
    userCreateResponse?: TimespentResponse | null;
    apiSuccess?: boolean;
    loading?: boolean;
    error?: string | null;
};

const Timespent = (state: State = INIT_STATE, action: TimespentActionType) => {
    switch (action.type) {
        case TimespentActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TimespentActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllTimespentLogs: action.payload.data,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                case TimespentActionTypes.USER_CREATE: {
                    return {
                        ...state,
                        userCreateResponse: action.payload.data,
                        loading: false,
                        apiSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }

        case TimespentActionTypes.ADMIN_VIEW_ALL:
            return {
                loading: true,
                apiSuccess: false,
            };

        case TimespentActionTypes.USER_CREATE:
            return {
                loading: true,
                apiSuccess: false,
                userCreateResponse: null,
            };

        default:
            return { ...state };
    }
};

export default Timespent;

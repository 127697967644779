import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';

const api = new APICore();

function apiUserViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/problems_solutions/${id}`, null);
}

function apiUserViewAll(page: number, size: number) {
    return api.get(`${apiBaseUrl}/problems_solutions/page/${page}/${size}`, null);
}

function apiUserFilter(id: string, page: number, size: number) {
    return api.get(`${apiBaseUrl}/problems_solutions/filter/${id}/${page}/${size}`, null);
}

export { apiUserViewDetails, apiUserViewAll, apiUserFilter };

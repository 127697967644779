import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiUserComments, apiUserLoadGraph7days, apiUserLoadGraphHeatmap, apiUserLoadStats, apiUserStartedModules } from '../../../helpers/api/user/dashboard';
import { dashboardApiResponseError, dashboardApiResponseSuccess } from './actions';
import { DashboardActionTypes } from './constants';
import { HeatSeries } from '../../../types/user/dashboard';
import { convertToDate } from '../../../utils';

function* userViewComments(): SagaIterator {
    try {
        let response = yield call(apiUserComments);
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.USER_COMMENTS, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(DashboardActionTypes.USER_COMMENTS, error));
    }
}

function* userViewGraph7days(): SagaIterator {
    try {
        let response = yield call(apiUserLoadGraph7days);
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.USER_LOAD_GRAPH_7_DAYS, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(DashboardActionTypes.USER_LOAD_GRAPH_7_DAYS, error));
    }
}

function* userViewGraphHeatmap(): SagaIterator {
    try {
        let response = yield call(apiUserLoadGraphHeatmap);
        const responseData = response.data.series;
        responseData.map((series: HeatSeries) => {
            series.data.map((dataPoint) => {
                dataPoint.x = convertToDate(dataPoint.x as number[]);
            });
        });
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.USER_LOAD_GRAPH_HEATMAP, responseData));
    } catch (error: any) {
        yield put(dashboardApiResponseError(DashboardActionTypes.USER_LOAD_GRAPH_HEATMAP, error));
    }
}

function* userViewStats(): SagaIterator {
    try {
        let response = yield call(apiUserLoadStats);
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.USER_LOAD_STATS, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(DashboardActionTypes.USER_LOAD_STATS, error));
    }
}

function* userViewStartedModules(): SagaIterator {
    try {
        let response = yield call(apiUserStartedModules);
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.USER_STARTED_MODULES, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(DashboardActionTypes.USER_STARTED_MODULES, error));
    }
}

export function* watchUserViewGraph7days() {
    yield takeEvery(DashboardActionTypes.USER_LOAD_GRAPH_7_DAYS, userViewGraph7days);
}

export function* watchUserViewGraphHeatmap() {
    yield takeEvery(DashboardActionTypes.USER_LOAD_GRAPH_HEATMAP, userViewGraphHeatmap);
}

export function* watchUserViewStats() {
    yield takeEvery(DashboardActionTypes.USER_LOAD_STATS, userViewStats);
}

export function* watchUserViewStartedModules() {
    yield takeEvery(DashboardActionTypes.USER_STARTED_MODULES, userViewStartedModules);
}

export function* watchUserViewComments() {
    yield takeEvery(DashboardActionTypes.USER_COMMENTS, userViewComments);
}

function* dashboardSaga() {
    yield all([fork(watchUserViewGraph7days), fork(watchUserViewGraphHeatmap), fork(watchUserViewStats), fork(watchUserViewStartedModules), fork(watchUserViewComments)]);
}

export default dashboardSaga;

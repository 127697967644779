export enum SettingsActionTypes {
    API_RESPONSE_ERROR = '@@settings/API_RESPONSE_ERROR',
    API_RESPONSE_SUCCESS = '@@settings/API_RESPONSE_SUCCESS',

    USER_VIEW_PERSONAL_DATA = '@@settings/USER_VIEW_PERSONAL_DATA',
    USER_VIEW_PROFILE_DATA = '@@settings/USER_VIEW_PROFILE_DATA',
    USER_VIEW_PREFERENCE_DATA = '@@settings/USER_VIEW_PREFERENCE_DATA',

    USER_UPDATE_PERSONAL_DATA = '@@settings/USER_UPDATE_PERSONAL_DATA',
    USER_UPDATE_PROFILE_DATA = '@@settings/USER_UPDATE_PROFILE_DATA',
    USER_UPDATE_PREFERENCE_DATA = '@@settings/USER_UPDATE_PREFERENCE_DATA',
    USER_UPDATE_PASSWORD = '@@settings/USER_UPDATE_PASSWORD',
    USER_UPDATE_AVATAR = '@@settings/USER_UPDATE_AVATAR',

    USER_DELETE_ACCOUNT = '@@settings/USER_DELETE_ACCOUNT',

    RESET = '@@settings/RESET',
}

import Cookies from 'js-cookie';
import { SAME_SITE_OPTIONS } from '../types/cookie';

export const defaultCookiePrefix = 'pdi_cookie_';
export const defaultCookieName = `${defaultCookiePrefix}consent`;

export const generateUUIDv4 = (): string => {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
};

export const getLegacyCookieName = (name: string) => {
    return `${name}-legacy`;
};

export const getCookieValue = (name: string = defaultCookieName): string | null => {
    const cookieValue = Cookies.get(name);

    if (cookieValue === undefined) {
        return Cookies.get(getLegacyCookieName(name)) || null;
    }
    return cookieValue;
};

export const getDefaultCookieId = () => {
    const cookieName = `${defaultCookiePrefix}id`;
    return getCookieValue(cookieName);
};

export const getDefaultCookieCreation = () => {
    const cookieCreation = `${defaultCookiePrefix}created`;
    return getCookieValue(cookieCreation);
};

export const createDefaultCookie = () => {
    if (!getDefaultCookieId()) {
        const randomId = generateUUIDv4();
        const cookieName = `${defaultCookiePrefix}id`;
        const cookieCreation = `${defaultCookiePrefix}created`;
        const createdDateAsString = new Date().toISOString();
        setCookie(cookieName, randomId);
        setCookie(cookieCreation, createdDateAsString);
    }
};

export const setCookie = (name: string, value: string) => {
    const expires = 365;
    const sameSite = SAME_SITE_OPTIONS.LAX;
    const cookieSecurity = window.location ? window.location.protocol === 'https:' : true;
    const cookieOptions = { expires, sameSite, secure: cookieSecurity };
    Cookies.set(name, value, cookieOptions);
};

export const acceptCookies = () => {
    setCookie(defaultCookieName, 'true');
};

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { StaticPageNew } from '../../../types/cms/staticPage';

const api = new APICore();

function apiUserView(params: { slug: string }) {
    return api.get(`${apiBaseUrl}/static_pages/${params.slug}`, null);
}

function apiAdminViewAll() {
    return api.get(`${apiBaseUrl}/static_pages`, null);
}

function apiAdminCreate(data: StaticPageNew) {
    return api.create(`${apiBaseUrl}/static_pages/admin`, data);
}

function apiAdminUpdate(data: StaticPageNew) {
    console.log(data);
    return api.update(`${apiBaseUrl}/static_pages/admin`, data);
}

export { apiUserView, apiAdminViewAll, apiAdminUpdate, apiAdminCreate };

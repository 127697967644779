import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { MessageNew } from '../../../types/user/chat';

const api = new APICore();

function apiUserLoadGraph7days() {
    return api.get(`${apiBaseUrl}/dashboard/graphs/7days`, null);
}

function apiUserLoadGraphHeatmap() {
    return api.get(`${apiBaseUrl}/dashboard/graphs/heatmap`, null);
}

function apiUserLoadStats() {
    return api.get(`${apiBaseUrl}/dashboard/stats`, null);
}

function apiUserStartedModules() {
    return api.get(`${apiBaseUrl}/dashboard/started_modules`, null);
}

function apiUserComments() {
    return api.get(`${apiBaseUrl}/dashboard/comments`, null);
}

export { apiUserLoadGraph7days, apiUserLoadGraphHeatmap, apiUserLoadStats, apiUserStartedModules, apiUserComments };

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { schoolApiResponseError, schoolApiResponseSuccess } from './actions';
import { apiAdminGetAll, apiAdminView, apiAdminUpdate, apiAdminCreate, apiAdminDelete } from '../../../helpers/api/cms/school';
import { SchoolActionTypes } from './constants';
import { SchoolDetails, SchoolNew } from '../../../types/cms/school';

type SchoolAdminViewPayloadType = {
    payload: {
        schoolId: number;
    };
    type: string;
};

type SchoolAdminUpdatePayloadType = {
    payload: SchoolNew;
    type: string;
};

function* adminView({ payload: { schoolId } }: SchoolAdminViewPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminView, { schoolId: schoolId });
        yield put(schoolApiResponseSuccess(SchoolActionTypes.ADMIN_VIEW, response.data));
    } catch (error: any) {
        yield put(schoolApiResponseError(SchoolActionTypes.ADMIN_VIEW, error));
    }
}

function* adminDelete({ payload: { schoolId } }: SchoolAdminViewPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminDelete, { schoolId: schoolId });
        yield put(schoolApiResponseSuccess(SchoolActionTypes.ADMIN_DELETE, response.data));
    } catch (error: any) {
        yield put(schoolApiResponseError(SchoolActionTypes.ADMIN_DELETE, error));
    }
}

function* adminViewAll(): SagaIterator {
    try {
        const response = yield call(apiAdminGetAll);
        const responseData: SchoolDetails[] = response.data.map((school: SchoolDetails) => {
            return Object.assign(school, {
                id_short: school.id.substring(0, 8),
            });
        });
        yield put(schoolApiResponseSuccess(SchoolActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(schoolApiResponseError(SchoolActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminUpdate({ payload }: SchoolAdminUpdatePayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminUpdate, payload);
        yield put(schoolApiResponseSuccess(SchoolActionTypes.ADMIN_UPDATE, response.data));
    } catch (error: any) {
        yield put(schoolApiResponseError(SchoolActionTypes.ADMIN_UPDATE, error));
    }
}

function* adminCreate({ payload }: SchoolAdminUpdatePayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminCreate, payload);
        yield put(schoolApiResponseSuccess(SchoolActionTypes.ADMIN_CREATE, response.data));
    } catch (error: any) {
        yield put(schoolApiResponseError(SchoolActionTypes.ADMIN_CREATE, error));
    }
}

function* watchAdminView(): SagaIterator {
    yield takeEvery(SchoolActionTypes.ADMIN_VIEW, adminView);
}

function* watchAdminCreate(): SagaIterator {
    yield takeEvery(SchoolActionTypes.ADMIN_CREATE, adminCreate);
}

function* watchAdminUpdate(): SagaIterator {
    yield takeEvery(SchoolActionTypes.ADMIN_UPDATE, adminUpdate);
}

function* watchAdminViewAll(): SagaIterator {
    yield takeEvery(SchoolActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

function* watchAdminDelete(): SagaIterator {
    yield takeEvery(SchoolActionTypes.ADMIN_DELETE, adminDelete);
}

function* SchoolSaga() {
    yield all([fork(watchAdminDelete), fork(watchAdminViewAll), fork(watchAdminView), fork(watchAdminUpdate), fork(watchAdminCreate)]);
}

export default SchoolSaga;

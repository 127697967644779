import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { TabItem } from '../../../../types/tabItem';

type TabNavProps = {
    content: TabItem[];
    variant?: string;
    justify?: boolean;
    hideIcons?: boolean;
    highlight?: boolean;
    onClick?: (eventKey: string) => void;
};

const HorizontalTabNav = ({ content, variant, justify, onClick, hideIcons, highlight }: TabNavProps) => {
    return (
        <Nav variant={variant ?? 'tabs'} className='nav-bordered' as='ul' justify={justify ?? false}>
            {content.map((tab, index) => {
                return (
                    <Nav.Item key={index.toString()} onClick={() => onClick?.(tab.key)}>
                        <Nav.Link as={Link} to='#' eventKey={tab.key}>
                            {!hideIcons && <i className={classnames(tab.icon, 'mdi-16px me-1')}></i>}
                            <span className={highlight ? 'text-uppercase fw-bold' : 'text-capitalize'}>{tab.title}</span>
                        </Nav.Link>
                    </Nav.Item>
                );
            })}
        </Nav>
    );
};

export default HorizontalTabNav;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { purchaseMessageAllApiResponseSuccess, purchaseMessageApiResponseError } from './actions';
import { UserRankingActionTypes } from './constants';
import { apiUserLoadCountyRanking } from '../../../helpers/api/user/ranking';

type UserCreatePayloadType = {
    payload: {
        county: string;
    };
    type: string;
};

function* userView({ payload }: UserCreatePayloadType): SagaIterator {
    try {
        const response = yield call(apiUserLoadCountyRanking, payload.county);
        yield put(purchaseMessageAllApiResponseSuccess(UserRankingActionTypes.USER_VIEW, response.data));
    } catch (error: any) {
        yield put(purchaseMessageApiResponseError(UserRankingActionTypes.USER_VIEW, error));
    }
}

export function* watchUserCreate() {
    yield takeEvery(UserRankingActionTypes.USER_VIEW, userView);
}

function* PurchaseSaga() {
    yield all([fork(watchUserCreate)]);
}

export default PurchaseSaga;

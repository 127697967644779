import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { dependencyApiResponseError, dependencyApiResponseSuccess } from './actions';
import { apiAdminMapChapters, apiAdminMapLectures, apiAdminMapModules, apiAdminGetAll, apiAdminView, apiAdminUpdate, apiAdminDelete } from '../../../helpers/api/cms/dependency';
import { DependencyActionTypes } from './constants';
import { DependencyDetails, DependencyNew } from '../../../types/cms/dependency';

type DependencyAdminViewPayloadType = {
    payload: {
        id: string;
        type: string;
    };
    type: string;
};

type DependencyAdminUpsertPayloadType = {
    payload: DependencyNew;
    type: string;
};

type DependencyMapType = {
    payload: {
        parentId: string;
    };
    type: string;
};

function* adminViewAll(): SagaIterator {
    try {
        const response = yield call(apiAdminGetAll);
        const responseData: DependencyDetails[] = [];
        response.data.lectures.map((dependency: DependencyDetails) => {
            responseData.push(
                Object.assign(dependency, {
                    type: 'Lecture',
                    id_short: dependency.id.substring(0, 8),
                }),
            );
        });
        response.data.chapters.map((dependency: DependencyDetails) => {
            responseData.push(
                Object.assign(dependency, {
                    type: 'Chapter',
                    id_short: dependency.id.substring(0, 8),
                }),
            );
        });
        yield put(dependencyApiResponseSuccess(DependencyActionTypes.ADMIN_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(dependencyApiResponseError(DependencyActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminView({ payload: { id, type } }: DependencyAdminViewPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminView, { model: type, modelId: id });
        yield put(dependencyApiResponseSuccess(DependencyActionTypes.ADMIN_VIEW, response.data));
    } catch (error: any) {
        yield put(dependencyApiResponseError(DependencyActionTypes.ADMIN_VIEW, error));
    }
}

function* adminMapModules(): SagaIterator {
    try {
        const response = yield call(apiAdminMapModules);
        yield put(dependencyApiResponseSuccess(DependencyActionTypes.ADMIN_MAP_ALL_MODULES, response.data));
    } catch (error: any) {
        yield put(dependencyApiResponseError(DependencyActionTypes.ADMIN_MAP_ALL_MODULES, error));
    }
}

function* adminMapChapters({ payload: { parentId } }: DependencyMapType): SagaIterator {
    try {
        const response = yield call(apiAdminMapChapters, parentId);
        yield put(dependencyApiResponseSuccess(DependencyActionTypes.ADMIN_MAP_ALL_CHAPTERS, response.data));
    } catch (error: any) {
        yield put(dependencyApiResponseError(DependencyActionTypes.ADMIN_MAP_ALL_CHAPTERS, error));
    }
}

function* adminMapLectures({ payload: { parentId } }: DependencyMapType): SagaIterator {
    try {
        const response = yield call(apiAdminMapLectures, parentId);
        yield put(dependencyApiResponseSuccess(DependencyActionTypes.ADMIN_MAP_ALL_LECTURES, response.data));
    } catch (error: any) {
        yield put(dependencyApiResponseError(DependencyActionTypes.ADMIN_MAP_ALL_LECTURES, error));
    }
}

function* adminUpdate({ payload }: DependencyAdminUpsertPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminUpdate, payload);
        yield put(dependencyApiResponseSuccess(DependencyActionTypes.ADMIN_UPDATE, response.data));
    } catch (error: any) {
        yield put(dependencyApiResponseError(DependencyActionTypes.ADMIN_UPDATE, error));
    }
}

function* adminDelete({ payload: { id, type } }: DependencyAdminViewPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminDelete, { model: type, modelId: id });
        yield put(dependencyApiResponseSuccess(DependencyActionTypes.ADMIN_DELETE, response.data));
    } catch (error: any) {
        yield put(dependencyApiResponseError(DependencyActionTypes.ADMIN_DELETE, error));
    }
}

function* watchAdminViewAll(): SagaIterator {
    yield takeEvery(DependencyActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

function* watchAdminMapLectures(): SagaIterator {
    yield takeEvery(DependencyActionTypes.ADMIN_MAP_ALL_LECTURES, adminMapLectures);
}

function* watchAdminMapChapters(): SagaIterator {
    yield takeEvery(DependencyActionTypes.ADMIN_MAP_ALL_CHAPTERS, adminMapChapters);
}

function* watchAdminMapModules(): SagaIterator {
    yield takeEvery(DependencyActionTypes.ADMIN_MAP_ALL_MODULES, adminMapModules);
}

function* watchAdminDelete(): SagaIterator {
    yield takeEvery(DependencyActionTypes.ADMIN_DELETE, adminDelete);
}

function* watchAdminUpdate(): SagaIterator {
    yield takeEvery(DependencyActionTypes.ADMIN_UPDATE, adminUpdate);
}

function* watchAdminView(): SagaIterator {
    yield takeEvery(DependencyActionTypes.ADMIN_VIEW, adminView);
}

function* DependencySaga() {
    yield all([fork(watchAdminMapLectures), fork(watchAdminMapChapters), fork(watchAdminMapModules), fork(watchAdminViewAll), fork(watchAdminView), fork(watchAdminUpdate), fork(watchAdminDelete)]);
}

export default DependencySaga;

import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore, setAuthorization } from 'helpers/api/apiCore';
import { login as loginApi, logout as logoutApi, userInfo as userInfoApi, signup as signupApi, forgotPassword as forgotPasswordApi } from 'helpers';
import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActionTypes } from './constants';
import ReactGA from 'react-ga4';
import jwtDecode from 'jwt-decode';
import { UserForgotPassword, UserInfo, UserJWTDetails, UserResetPassword } from '../../types/user/user';
import { resetPasswordApi } from '../../helpers/api/auth';

type UserData = {
    payload: {
        user: string;
        password: string;
        email: string;
        captchaToken: string;
    };
    type: string;
};

type UserDataForgotPasswordPayload = {
    payload: UserForgotPassword;
    type: string;
};

type UserResetPasswordPayload = {
    payload: UserResetPassword;
    type: string;
};

const api = new APICore();

/**
 * Login the User
 * @param {*} payload - username and password
 */
function* login({ payload: { user, password }, type }: UserData): SagaIterator {
    try {
        const response = yield call(loginApi, { user, password, userOTP: '' });
        const authKey = response.data.authKey;

        const { id, username } = jwtDecode(authKey) as UserJWTDetails;
        ReactGA.set({ userId: id, user: username });

        api.setLoggedInUser(authKey);
        setAuthorization(authKey);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* getUserInfo(): SagaIterator {
    try {
        const response = yield call(userInfoApi);
        const userInfo: UserInfo = response.data;
        ReactGA.set({ user_id: userInfo.id, user_name: userInfo.username });

        yield put(authApiResponseSuccess(AuthActionTypes.GET_USER_INFO, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.GET_USER_INFO, error));
    }
}

/**
 * Logout the User
 */
function* logout(): SagaIterator {
    try {
        // yield call(logoutApi); TODO: implement User logout on API
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* signup({ payload: { email, password, captchaToken } }: UserData): SagaIterator {
    try {
        const response = yield call(signupApi, { email, password, captchaToken });
        const user = response.data;
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload }: UserDataForgotPasswordPayload): SagaIterator {
    try {
        const response = yield call(forgotPasswordApi, payload);
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}

function* resetPassword({ payload }: UserResetPasswordPayload): SagaIterator {
    try {
        const response = yield call(resetPasswordApi, payload);
        yield put(authApiResponseSuccess(AuthActionTypes.RESET_PASSWORD, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.RESET_PASSWORD, error));
    }
}

export function* watchGetUserInfo() {
    yield takeEvery(AuthActionTypes.GET_USER_INFO, getUserInfo);
}

export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup() {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword() {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
    yield takeEvery(AuthActionTypes.RESET_PASSWORD, resetPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogout), fork(watchSignup), fork(watchForgotPassword), fork(watchGetUserInfo), fork(watchResetPassword)]);
}

export default authSaga;

import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { ProblemNew, ProblemSearchProps, UserEvaluatorRun, UserEvaluatorSubmit } from '../../../types/problem/problem';

const api = new APICore();

function apiAdminViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/problems/admin/${id}`, null);
}

function apiAdminViewAll(page: number, size: number) {
    return api.get(`${apiBaseUrl}/problems/admin/page/${page}/${size}`, null);
}

function apiAdminViewAllShort() {
    return api.get(`${apiBaseUrl}/problems/admin/short`, null);
}

function apiAdminCreate(data: ProblemNew) {
    return api.create(`${apiBaseUrl}/problems/admin`, data);
}

function apiAdminUpdate(data: ProblemNew) {
    return api.update(`${apiBaseUrl}/problems/admin`, data);
}

function apiUserViewAll(data: ProblemSearchProps) {
    return api.create(`${apiBaseUrl}/problems/all`, data);
}

function apiUserViewDetails(slug: string) {
    return api.get(`${apiBaseUrl}/problems/${slug}`, null);
}

function apiUserEvaluatorRun(data: UserEvaluatorRun) {
    return api.create(`${apiBaseUrl}/problems/compiler_run`, data);
}

function apiUserEvaluatorSubmit(data: UserEvaluatorSubmit) {
    return api.create(`${apiBaseUrl}/problems/compiler_submit`, data);
}

function apiUserViewAllShort() {
    return api.get(`${apiBaseUrl}/problems/all-short`, null);
}

export {
    apiAdminCreate,
    apiAdminUpdate,
    apiAdminViewAll,
    apiAdminViewAllShort,
    apiAdminViewDetails,
    apiUserViewAll,
    apiUserViewAllShort,
    apiUserViewDetails,
    apiUserEvaluatorRun,
    apiUserEvaluatorSubmit,
};

import { DashboardActionTypes } from './constants';

export type DashboardActionType = {
    type:
        | DashboardActionTypes.API_RESPONSE_ERROR
        | DashboardActionTypes.API_RESPONSE_SUCCESS
        | DashboardActionTypes.USER_COMMENTS
        | DashboardActionTypes.USER_LOAD_GRAPH_7_DAYS
        | DashboardActionTypes.USER_LOAD_GRAPH_HEATMAP
        | DashboardActionTypes.USER_LOAD_STATS
        | DashboardActionTypes.USER_STARTED_MODULES;
    payload: {} | string;
};

export const dashboardUserComments = (): DashboardActionType => ({
    type: DashboardActionTypes.USER_COMMENTS,
    payload: {},
});

export const dashboardLoadGraph7Days = (): DashboardActionType => ({
    type: DashboardActionTypes.USER_LOAD_GRAPH_7_DAYS,
    payload: {},
});

export const dashboardLoadGraphHeatmap = (): DashboardActionType => ({
    type: DashboardActionTypes.USER_LOAD_GRAPH_HEATMAP,
    payload: {},
});

export const dashboardLoadStats = (): DashboardActionType => ({
    type: DashboardActionTypes.USER_LOAD_STATS,
    payload: {},
});

export const dashboardStartedModules = (): DashboardActionType => ({
    type: DashboardActionTypes.USER_STARTED_MODULES,
    payload: {},
});

export const dashboardApiResponseSuccess = (actionType: string, data: {}): DashboardActionType => ({
    type: DashboardActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const dashboardApiResponseError = (actionType: string, error: string): DashboardActionType => ({
    type: DashboardActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});
